import { ElectiveFeedbackDialog } from "components/ElectiveFeedbackDialog";
import { PromptedFeedbackDialog } from "components/PromptedFeedbackDialog";
import { PromptedFeedbackToast } from "components/PromptedFeedbackToast";

import { PortalContext } from "contexts/PortalContext";

import { FeedbackPrompt } from "model/dto/feedback-prompt";

import { constructFeedbackSubmission } from "utils/feedbackUtils";

import { getFeedbackPrompt, submitFeedback } from "api/portal";

import { Field, FieldRegistry, FieldSaver } from "model/types/Fields";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { compressError } from "utils/compressError";
import { useIntl } from "i18n/Internationalization";

const FEEDBACK_PROMPT_DELAY_MS = 2000;

export type Feedback = {
	[key: string | number | symbol]: Field<any, any>["value"];
};

export type FeedbackValue = FieldRegistry<Field<any, any>["value"]> | boolean;

export type FeedbackComplete = () => Promise<void>;

type FeedbackMethod = {
	showDialog: React.Dispatch<boolean>;
	hideDialog: React.Dispatch<boolean>;
	showToast?: React.Dispatch<boolean>;
	hideToast?: React.Dispatch<boolean>;
	saveValue: FieldSaver<FeedbackValue>;
	feedback: Feedback;
};

type FeedbackState = {
	hideAll: () => void;
	prompted: FeedbackMethod;
	elective: FeedbackMethod;
};

const getValueSaver = (setFeedback: React.Dispatch<Feedback>, feedback: Feedback): FieldSaver<FeedbackValue> => {
	return (key: string, value: FeedbackValue) => {
		setFeedback({
			...feedback,
			...{ [key]: value },
		});
	};
};

export const FeedbackContext = React.createContext<FeedbackState>({
	hideAll: () => {},
	prompted: {
		showDialog: () => {},
		hideDialog: () => {},
		showToast: () => {},
		hideToast: () => {},
		saveValue: async () => {},
		feedback: {},
	},
	elective: {
		showDialog: () => {},
		hideDialog: () => {},
		saveValue: async () => {},
		feedback: {},
	},
});

export const FeedbackProvider = ({ children }) => {
	const { error, publicId, portal } = useContext(PortalContext);
	const [searchParams] = useSearchParams(undefined);
	const forcePrompt = searchParams.get("forcePrompt");

	const intl = useIntl();

	const getDefaultPromptedFeedbackState = (): Feedback => {
		return {
			contractor_overall: {},
			contractor_details: {},
			accuserve_overall: {},
			accuserve_details: {},
			additional_feedback: {},
		};
	};
	const getDefaultElectiveFeedbackState = (): Feedback => {
		return {
			details_experience: {},
			overall_experience: {},
			feedback_for: {},
			feedback_for_contractor: {},
		};
	};

	// prompted feedback state stuffs
	const [feedbackPrompt, setFeedbackPrompt] = useState<FeedbackPrompt>({
		prompt: false,
		questions: [],
	});

	const [promptedToastVisibility, setPromptedToastVisibility] = useState<boolean>(false);
	const [promptedVisibility, setPromptedVisibility] = useState<boolean>(false);
	const [promptedFeedback, setPromptedFeedback] = useState<Feedback>(getDefaultPromptedFeedbackState());
	const promptedFeedbackSaver = getValueSaver(setPromptedFeedback, promptedFeedback);

	// elective feedback stuffs
	const [electiveVisibility, setElectiveVisibility] = useState<boolean>(false);
	const [electiveFeedback, setElectiveFeedback] = useState<Feedback>(getDefaultElectiveFeedbackState());
	const electiveFeedbackSaver = getValueSaver(setElectiveFeedback, electiveFeedback);

	const [promptedFeedbackReady, setPromptedFeedbackReady] = useState<boolean>(false);
	const [electiveFeedbackReady, setElectiveFeedbackReady] = useState<boolean>(false);

	const loadFeedback = useCallback(
		async (publicId: string) => {
			try {
				const feedbackPromptReponse = await getFeedbackPrompt(publicId);
				setFeedbackPrompt(feedbackPromptReponse);
				if ((!portal?.isStaff && feedbackPromptReponse.prompt) || forcePrompt) {
					setTimeout(() => setPromptedToastVisibility(true), FEEDBACK_PROMPT_DELAY_MS);
				}
			} catch (err: any) {
				if (err.status !== 403) {
					const compressed = compressError(err);
					Sentry.captureException(compressed);
				}
			}
		},
		[portal, forcePrompt]
	);
	useEffect(() => {
		if (publicId) {
			loadFeedback(publicId);
		}
	}, [publicId, loadFeedback]);

	const feedbackState: FeedbackState = {
		hideAll: () => {
			setPromptedVisibility(false);
			setPromptedToastVisibility(false);
			setElectiveVisibility(false);
		},
		prompted: {
			showDialog: () => setPromptedVisibility(true),
			hideDialog: () => setPromptedVisibility(false),
			showToast: () => setPromptedToastVisibility(true),
			hideToast: () => setPromptedToastVisibility(false),
			saveValue: promptedFeedbackSaver,
			feedback: promptedFeedback,
		},
		elective: {
			showDialog: () => setElectiveVisibility(true),
			hideDialog: () => setElectiveVisibility(false),
			saveValue: electiveFeedbackSaver,
			feedback: electiveFeedback,
		},
	};

	const sentToServer = useCallback(async () => {
		if (!publicId) {
			return;
		}
		if (promptedFeedbackReady) {
			const feedbackSubmission = constructFeedbackSubmission(intl, promptedFeedback, "prompted", feedbackPrompt);
			try {
				await submitFeedback(publicId, feedbackSubmission);
			} catch (error) {
				console.error(error);
			}
			setPromptedFeedbackReady(false);
			setPromptedFeedback(getDefaultPromptedFeedbackState());
		}
		if (electiveFeedbackReady) {
			const feedbackSubmission = constructFeedbackSubmission(intl, electiveFeedback, "elective");
			try {
				await submitFeedback(publicId, feedbackSubmission);
			} catch (error) {
				console.error(error);
			}
			setElectiveFeedbackReady(false);
			setElectiveFeedback(getDefaultElectiveFeedbackState());
		}
	}, [
		promptedFeedbackReady,
		promptedFeedback,
		publicId,
		intl,
		feedbackPrompt,
		electiveFeedbackReady,
		electiveFeedback,
	]);

	useEffect(() => {
		sentToServer();
	}, [sentToServer]);

	return (
		<FeedbackContext.Provider value={feedbackState}>
			{children}
			{error ? (
				<></>
			) : (
				<>
					<PromptedFeedbackToast
						publicId={publicId || ""}
						visible={promptedToastVisibility}
						openDialog={() => {
							setPromptedToastVisibility(false);
							setPromptedVisibility(true);
						}}
						close={() => {
							setPromptedToastVisibility(false);
						}}
					/>
					<PromptedFeedbackDialog
						visible={promptedVisibility}
						feedback={promptedFeedback}
						saveValue={promptedFeedbackSaver}
						submitFeedback={() => {
							setPromptedFeedbackReady(true);
						}}
						close={() => {
							setPromptedVisibility(false);
							setPromptedFeedback(getDefaultPromptedFeedbackState());
						}}
					/>
					<ElectiveFeedbackDialog
						visible={electiveVisibility}
						feedback={electiveFeedback}
						saveValue={electiveFeedbackSaver}
						submitFeedback={() => {
							setElectiveFeedbackReady(true);
						}}
						close={() => {
							setElectiveVisibility(false);
							setElectiveFeedback(getDefaultElectiveFeedbackState());
						}}
					/>
				</>
			)}
		</FeedbackContext.Provider>
	);
};
const FeedbackExports = { FeedbackProvider, FeedbackContext };
export default FeedbackExports;
