export const FIRE_MITIGATION = [
	{
		type: "Fire Mitigation",
		state: "N/A",
		prose: ["", "", ""],
	},
	{
		type: "Fire Mitigation",
		state: "Accuserve Assigned",
		prose: [
			"Your claim has been received.",
			"Accuserve has received your [service] claim and is reaching out to schedule an inspection of your property.",
			"A contractor will be assigned shortly.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Assigning Contractor",
		prose: [
			"A contractor has been assigned to your claim.",
			"We are locating a [service] contractor who will inspect your property.",
			"The contractor will inspect your property.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Awaiting Inspection",
		prose: [
			"The contractor has inspected your property.",
			"A [service] contractor has been assigned and the next step is for them to inspect your property.",
			"The contractor will inspect your property at the scheduled appointment time.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Estimate In Progress",
		prose: [
			"An estimate has been completed.",
			"We are working with the [service] contractor to create an estimate for any repairs needed.",
			"Once the estimate is approved by your insurance provider, you will be asked to sign a work authorization in order to begin.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Awaiting Work Authorization",
		prose: [
			"Thank you for signing the work authorization!",
			"Good news! The estimate for [service] has been approved by your insurance provider.",
			"Once you sign a work authorization, the work will be scheduled.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Mitigation Planning",
		prose: [
			"Your service has been planned.",
			"We are working with the [service] contractor to plan and schedule your repairs.",
			"We will reach out to you with the plan shortly.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Mitigation Scheduled",
		prose: [
			"The service has been planned and scheduled.",
			"We have a target start date for your [service] service.",
			"Once we determine a plan and schedule, we will provide a target start date.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Mitigation In Progress",
		prose: [
			"Accuserve has been working with the contractor to complete the [service] service at your property.",
			"Accuserve is working with the [service] contractor to complete the service at your property.",
			"Accuserve will work with the contractor until the service at your property is complete.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Awaiting COS",
		prose: [
			"Thank you for signing that you are satisfied with the completed service!",
			"Now that the [service] is complete, we are reaching out to confirm that you are satisfied with the work.",
			"Once the service is complete, we will reach out to confirm that you are satisfied with the work.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Service Complete",
		prose: [
			"",
			"It has been a pleasure working to complete the [service] at your property. If other services are required within this claim, we will continue that work.",
			"The service will be complete after you confirm you are satisfied.",
		],
	},
	{
		type: "Fire Mitigation",
		state: "Accuserve Not Handling Service",
		terminal: true,
		prose: [
			"The service is no longer stopped.",
			"Accuserve is not currently managing the [service] service at your property. If anything changes and you would like our help, please contact us.",
			"",
		],
	},
];
