import { ButtonGroup } from "react-bootstrap";
import { Locale, useLocale } from "i18n/LocaleContext";
import { useIntl } from "i18n/Internationalization";
import { explicitLanguageChange } from "api/portal";

type LanguageSelectorProps = {
	publicId?: String | undefined;
};

const LanguageSelector = ({ publicId }: LanguageSelectorProps) => {
	const { currentLocale, setCurrentLocale } = useLocale();

	const intl = useIntl();

	const updateLocale = async (locale: Locale) => {
		setCurrentLocale(locale);
		if (publicId) {
			try {
				await explicitLanguageChange(publicId as string, locale);
			} catch (error) {
				console.error(error);
			}
		}
	};
	const i18nstrings = {
		"header-language": intl.formatMessage({
			id: "header-language",
			defaultMessage: "Language",
		}),
	};

	return (
		<div className="primary-header__language">
			<span className="primary-header__language--label">{i18nstrings["header-language"]}:</span>
			<ButtonGroup className="language-selector">
				<input
					type="radio"
					key="lang-en"
					id="lang-en"
					value={Locale.ENGLISH}
					checked={currentLocale === Locale.ENGLISH}
					onChange={(e) => updateLocale(e.currentTarget.value as Locale)}
				/>
				<label htmlFor="lang-en">EN</label>
				<input
					type="radio"
					key="lang-es"
					id="lang-es"
					value={Locale.SPANISH}
					checked={currentLocale === Locale.SPANISH}
					onChange={(e) => updateLocale(e.currentTarget.value as Locale)}
				/>
				<label htmlFor="lang-es">ES</label>
			</ButtonGroup>
		</div>
	);
};

export default LanguageSelector;
