import * as t from "io-ts";

export const PortalInfoCodec = t.type({
	publicId: t.string,
	description: t.string,
	lossDate: t.union([t.string, t.null]),
	baseClaimNumber: t.union([t.string, t.null]),
});

export type PortalInfo = t.TypeOf<typeof PortalInfoCodec>;
