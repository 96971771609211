import * as t from "io-ts";

export const ServiceTypeCodec = t.union([
	t.literal("Reconstruction"),
	t.literal("Fire Mitigation"),
	t.literal("Contents Cleaning"),
	t.literal("Contents Packout"),
	t.literal("Board Up"),
	t.literal("Tree Removal"),
	t.literal("Water Mitigation"),
	t.literal("Roofing"),
	t.literal("Window"),
]);

export type ServiceType = t.TypeOf<typeof ServiceTypeCodec>;
