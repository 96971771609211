import * as t from "io-ts";

const SimpleContactRequired = t.type({
	methodType: t.union([t.literal("Phone"), t.literal("Email")]),
});

const SimpleContactOptional = t.partial({
	phoneNumber: t.string,
	emailAddress: t.string,
});

const SimpleContactCodec = t.intersection([SimpleContactRequired, SimpleContactOptional]);

export const AssignedStaffRepCodec = t.type({
	fullName: t.string,
	contactMethods: t.array(SimpleContactCodec),
});

export type SimpleContact = t.TypeOf<typeof SimpleContactCodec>;
export type AssignedStaffRep = t.TypeOf<typeof AssignedStaffRepCodec>;
