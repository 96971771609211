import React from "react";

type SidebarWidgetProps = {
	content: JSX.Element | React.ReactFragment | String;
	classes?: String;
};

const SidebarWidget = ({ content, classes = "" }: SidebarWidgetProps) => {
	if (!content) return null;

	let widgetClasses = ["claim-meta__widget", classes].join(" ").trim();

	return (
		<React.Fragment>
			<div className={widgetClasses}>{content}</div>
		</React.Fragment>
	);
};

export default SidebarWidget;
