import { SimpleContact } from "model/dto/assigned-staff-rep";
import { ContactMethod } from "model/dto/contact-method";
import { ReactComponent as EmailIcon } from "assets/img/icon-email.svg";
import { ReactComponent as PhoneIcon } from "assets/img/icon-phone.svg";
import { useIntl } from "i18n/Internationalization";

type ContactMethodsProps = {
	contactMethods: Array<ContactMethod | SimpleContact> | undefined | null;
	includeBreaks: boolean;
	sortEmailsTop: boolean;
};

const ContactMethods = ({ contactMethods, includeBreaks, sortEmailsTop }: ContactMethodsProps) => {
	let links;

	const intl = useIntl();

	const i18nstrings = {
		"contact-method-email": intl.formatMessage({
			id: "contact-method-email",
			defaultMessage: "Email",
		}),
	};

	if (typeof contactMethods !== "undefined" && contactMethods !== null) {
		links = contactMethods
			.filter((method: ContactMethod) => {
				if (method.methodType === "Email") {
					return (method.emailAddress || "").length > 0;
				} else if (method.methodType === "Phone") {
					return (method.phoneNumber || "").length > 0;
				}

				return false;
			})
			.sort((a, b) => {
				if (sortEmailsTop && (a.methodType === "Email" || b.methodType === "Email")) {
					if (a.emailAddress && b.emailAddress) {
						return a.emailAddress > b.emailAddress ? -1 : 1;
					} else if (a.emailAddress) {
						return -1;
					} else if (b.emailAddress) {
						return 1;
					}
				} else {
					if (a.phoneNumber && b.phoneNumber) {
						return a.phoneNumber > b.phoneNumber ? -1 : 1;
					} else if (a.phoneNumber) {
						return -1;
					} else if (b.phoneNumber) {
						return 1;
					}
				}

				return 0;
			});

		links = links.map((method, idx, arr) => {
			let link;

			if (method.methodType === "Email") {
				link = (
					<a className="contact-link" href={`mailto:${method.emailAddress}`} title={method.emailAddress}>
						<EmailIcon /> {i18nstrings["contact-method-email"]}
					</a>
				);
			} else {
				link = (
					<a className="contact-link" href={`tel:${method.phoneNumber}`}>
						<PhoneIcon />
						{method.phoneNumber}
					</a>
				);
			}

			return (
				<p className="service-card__contractor--description" key={idx}>
					{link}
					{includeBreaks && idx < arr.length ? <br /> : ""}
				</p>
			);
		});
	}

	return <>{links}</>;
};

export default ContactMethods;
