import { CoreApiPath } from "api/path";
import mockConfig from "config/mock";
import { coreMock } from "mock/axios-mock-adapter";

if (mockConfig !== null) {
	/*
	mockConfig.surveys.forEach((survey) => {
		coreMock
			.onGet(CoreApiPath.GET_POLICY_HOLDER_SURVEY, { params: { surveyAuthKey: survey.surveyAuthKey } })
			.reply(200, survey.policyHolderSurvey);
	});
	*/

	coreMock.onPost(CoreApiPath.AUTH.ACTIVATE).reply(200, mockConfig.activationResult);

	mockConfig.portals.forEach((portal, idx) => {
		coreMock.onGet(`${CoreApiPath.PORTAL}/${idx}`).reply(200, portal);
		coreMock.onGet(`${CoreApiPath.PORTAL}/history/${idx}`).reply(200, portal);
	});
}
