import moment from "moment";
import { Claim } from "model/dto/claim";
import { getHistoryEntries } from "utils/claim";
import SidebarWidget from "components/SidebarWidget";
import { Link } from "react-router-dom";
import SidebarWidgetCollapsible from "components/SidebarWidgetCollapsible";
import OtherClaims from "components/OtherClaims";
import { PortalInfo } from "model/dto/portal-info";
import { ReactNode } from "react";
import { useIntl } from "i18n/Internationalization";

type SidebarClaimMeta = {
	publicId: String | undefined;
	claim: Claim;
	otherPortalsList: Array<PortalInfo> | null;
	hideHistory: boolean;
	classes: string;
};

const SidbarClaimMeta = ({ publicId, claim, classes, hideHistory, otherPortalsList }: SidebarClaimMeta) => {
	const intl = useIntl();

	const i18nstrings = {
		"sidebar-claim-carrier": intl.formatMessage({
			id: "sidebar-claim-carrier",
			defaultMessage: "Insurance Carrier",
		}),
		"sidebar-claim-date-of-loss": intl.formatMessage({
			id: "sidebar-claim-date-of-loss",
			defaultMessage: "Date of loss",
		}),
		"sidebar-claim-carrier-phone": intl.formatMessage({
			id: "sidebar-claim-date-of-loss",
			defaultMessage: "Carrier Phone",
		}),
		"sidebar-claim-address": intl.formatMessage({
			id: "sidebar-claim-address",
			defaultMessage: "Address of coverage",
		}),
		"note-content-sevicename-claim": intl.formatMessage({
			id: "note-content-sevicename-claim",
			defaultMessage: "Claim",
		}),
		"sidebar-claim-view-history": intl.formatMessage({
			id: "sidebar-claim-view-history",
			defaultMessage: "View claim history",
		}),
		"sidebar-looking-for-another": intl.formatMessage({
			id: "sidebar-looking-for-another",
			defaultMessage: "Looking for another claim?",
		}),
	};

	const history = getHistoryEntries(claim);

	const lossDateToUse = claim.lossDateTime || claim.lossDate;

	const lossDateMoment = moment(lossDateToUse);

	const lossDate = lossDateToUse ? (
		<SidebarWidget
			content={
				<div className="carrier-info">
					<div className="carrier-info__title">{i18nstrings["sidebar-claim-date-of-loss"]}</div>
					{lossDateMoment.format("MMM D, YYYY")}
				</div>
			}
		/>
	) : null;

	let claimInfoWidget = <></>;

	if (history.length && !hideHistory) {
		claimInfoWidget = (
			<SidebarWidget
				content={
					<Link className="button secondary" to={`/${publicId}/history`} aria-label="View Claim History">
						{i18nstrings["sidebar-claim-view-history"]}
					</Link>
				}
			/>
		);
	}

	let carrierInfoWidget = <></>;
	let carrierLogo: ReactNode;
	let carrierName: ReactNode;
	let carrierPhone: ReactNode;

	if (claim.carrierName || claim.carrierPhone || claim.carrierLogoUrl) {
		carrierLogo =
			claim.carrierName && claim.carrierLogoUrl ? (
				<div key={`${claim.carrierName}-logo`} className="carrier-info">
					<img className="carrier-info__logo" src={claim.carrierLogoUrl} alt={claim.carrierName} />
				</div>
			) : null;

		// If name and no logo
		carrierName =
			claim.carrierName && !claim.carrierLogoUrl ? (
				<div key={`${claim.carrierName}-name`} className="carrier-info">
					<div className="carrier-info__title">{i18nstrings["sidebar-claim-carrier"]}:</div>
					{claim.carrierName}
				</div>
			) : null;

		carrierPhone = claim.carrierPhone ? (
			<div key={`${claim.carrierName}-phone`} className="carrier-info">
				<div className="carrier-info__title">{i18nstrings["sidebar-claim-carrier-phone"]}:</div>
				{claim.carrierPhone}
			</div>
		) : null;

		carrierInfoWidget = <SidebarWidget content={[carrierLogo, carrierName, carrierPhone]} />;
	}

	let claimList = <></>;

	if (otherPortalsList && otherPortalsList.length > 0) {
		claimList = (
			<SidebarWidgetCollapsible
				toggleLabel={i18nstrings["sidebar-looking-for-another"]}
				content={<OtherClaims otherPortalsList={otherPortalsList} />}
			/>
		);
	}

	return (
		<>
			<div className={classes}>
				<div className="carrier-info">
					<h2 className="carrier-info__title">{i18nstrings["note-content-sevicename-claim"]}</h2>
					<div className="claim-meta__claim-highlight">{claim.baseClaimNumber}</div>
				</div>
			</div>

			{carrierInfoWidget}

			{lossDate}

			<SidebarWidget
				content={
					<address className="claim-meta__address">
						<div className="carrier-info">
							<div className="carrier-info__title">{i18nstrings["sidebar-claim-address"]}:</div>
							{claim.lossAddressCity}, {claim.lossAddressState} {claim.lossAddressZip}
						</div>
					</address>
				}
			/>

			{claimInfoWidget}

			{claimList}
		</>
	);
};

export default SidbarClaimMeta;
