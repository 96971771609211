import { assertPromptShown } from "api/portal";
import { Toast, ToastAction } from "components/Toast";
import { useEffect } from "react";
import { useIntl } from "i18n/Internationalization";

export type PromptedFeedbackToastConfig = {
	publicId: string;
	visible: boolean;
	openDialog: () => void;
	close: () => void;
};

export const PromptedFeedbackToast = ({ publicId, visible, openDialog, close }: PromptedFeedbackToastConfig) => {
	const intl = useIntl();
	useEffect(() => {
		if (visible) assertPromptShown(publicId);
	}, [visible, publicId]);
	const primaryAction: ToastAction = {
		label: intl.formatMessage({
			id: "feedback-modal-provide-feedback",
			defaultMessage: "Provide Feedback",
		}),
		action: openDialog,
	};

	const secondaryActon: ToastAction = {
		label: intl.formatMessage({
			id: "feedback-modal-maybe-later",
			defaultMessage: "Maybe Later",
		}),
		action: close,
	};

	return (
		<Toast
			title={intl.formatMessage({
				id: "feedback-modal-prompted-have-a-few-minutes",
				defaultMessage: "Have a few minutes to answer some questions?",
			})}
			show={visible}
			primaryAction={primaryAction}
			secondaryAction={secondaryActon}
		/>
	);
};
