import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { ServiceCodec } from "model/dto/service";
import { JournalEntryCodec } from "model/dto/journal-entry";
import { ZonedDateTimeFromString } from "model/types/TimeZonedDate";

export const ClaimCodec = t.partial({
	claimId: t.union([t.null, UUID]),
	lossDate: t.union([t.null, t.string]),
	lossDateTime: t.union([t.null, ZonedDateTimeFromString]),
	baseClaimNumber: t.union([t.null, t.string]),
	lossAddress: t.union([t.null, t.string]),
	lossAddressCity: t.union([t.null, t.string]),
	lossAddressState: t.union([t.null, t.string]),
	lossAddressZip: t.union([t.null, t.string]),
	lossAddressTimeZone: t.union([t.null, t.string]),
	services: t.union([t.null, t.array(ServiceCodec)]),
	policyholderEmailObscured: t.union([t.null, t.string]),
	policyholderPhoneNumberObscured: t.union([t.null, t.string]),
	carrierName: t.union([t.null, t.string]),
	carrierPhone: t.union([t.null, t.string]),
	carrierLogoUrl: t.union([t.null, t.string]),
	policyholderFirstName: t.union([t.null, t.string]),
	policyholderLastName: t.union([t.null, t.string]),
	policyholderFullName: t.union([t.null, t.string]),
	claimHistoryEvents: t.union([t.null, t.array(JournalEntryCodec)]),
	explicitLanguagePreference: t.union([t.null, t.literal("EN"), t.literal("ES")]),
});

export type Claim = t.TypeOf<typeof ClaimCodec>;
