import * as portalApi from "api/portal";
import Loader from "components/Loader";
import Centered from "layouts/Centered";
import ErrorLayout from "layouts/ErrorLayout";
import { AuthState } from "model/dto/auth-state";
import { CheckValidationCodeResult } from "model/dto/check-validation-code-result";
import { ContactMethod } from "model/dto/contact-method";
import { SendValidationCodeResult } from "model/dto/send-validation-code-result";
import ConfirmCode from "pages/activate/ConfirmCode";
import SelectContactMethod from "pages/activate/SelectContactMethod";
import FatalError from "pages/error/FatalError";
import Page404 from "pages/error/Page404";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { UUID } from "io-ts-types/lib/UUID";
import PrimaryHeaderSimple from "components/PrimaryHeaderSimple";
import PrimaryFooterSimple from "components/PrimaryFooterSimple";
import PageIntroSimple from "components/PageIntroSimple";
import moment from "moment";
import { useIntl } from "i18n/Internationalization";

interface PageState {
	step:
		| "LOAD"
		| "REDIRECT_TO_CORE"
		| "REDIRECT_TO_PORTAL"
		| "CHOOSE_CONTACT"
		| "CONFIRM_CODE"
		| "NOT_FOUND"
		| "ERROR";
	contactMethods?: ContactMethod[];
	selectedContactMethod?: ContactMethod;
	sendValidationCodeResult?: SendValidationCodeResult;
	checkValidationCodeResult?: CheckValidationCodeResult;
	authState?: AuthState;
	coreAuthUrl?: string;
	carrierName?: string | null;
	lossDate?: string | null;
	firstName?: string | null;
	fullName?: string | null;
}

const POLL_AUTH_STATE_INTERVAL_MS = 2000;
const POLL_AUTH_STATE_MAX_DURATION_MINUTES = 30;
const POLL_AUTH_STATE_MAX_TIMES = (POLL_AUTH_STATE_MAX_DURATION_MINUTES * 60) / (POLL_AUTH_STATE_INTERVAL_MS / 1000);

const ActivatePage = () => {
	const { publicId } = useParams();
	const [searchParams] = useSearchParams();
	const linkCode = searchParams.get("c");

	const [pageState, setPageState] = useState<PageState>({
		step: "LOAD",
	});

	const setError = useCallback(() => setPageState((pageState) => ({ ...pageState, step: "ERROR" })), []);
	const setNotFound = useCallback(() => setPageState((pageState) => ({ ...pageState, step: "NOT_FOUND" })), []);

	const intl = useIntl();

	useEffect(() => {
		const activatePortal = async (publicId: string, linkCode: string | null) => {
			try {
				await portalApi.ping(); // make sure we have a csrf cookie prior to POST below
				const result = await portalApi.activate(publicId, linkCode);
				const coreAuthUrl = result.coreAuthUrl;
				if (result.validated) {
					setPageState((pageState) => ({
						...pageState,
						step: "REDIRECT_TO_PORTAL",
					}));
				} else if (coreAuthUrl) {
					setPageState((pageState) => ({
						...pageState,
						step: "REDIRECT_TO_CORE",
						coreAuthUrl: coreAuthUrl,
					}));
				} else {
					setPageState((pageState) => ({
						...pageState,
						step: "CHOOSE_CONTACT",
						contactMethods: result.contactMethods,
						selectedContactMethod: result.contactMethods.length > 0 ? result.contactMethods[0] : undefined,
						carrierName: result.carrierName || "",
						lossDate: (result.lossDate && moment(result.lossDate).format("MM/DD/YYYY")) || "",
						firstName: result.firstName || null,
						fullName: result.fullName || null,
					}));
				}
			} catch (err: any) {
				if (err.status === 400) {
					setNotFound();
				} else {
					setError();
				}
			}
		};

		if (publicId && pageState.step === "LOAD") {
			activatePortal(publicId, linkCode);
		}
	}, [pageState.step, setError, setNotFound, publicId, linkCode]);

	const sendCode = async (publicId: string, selectedContactMethod: ContactMethod) => {
		try {
			const result = await portalApi.sendValidationCode(publicId, selectedContactMethod.contactMethodId as UUID);
			setPageState((pageState) => ({
				...pageState,
				step: "CONFIRM_CODE",
				sendValidationCodeResult: result,
			}));
		} catch (err) {
			setError();
		}
	};

	const checkCode = async (publicId: string, confirmCode: string) => {
		try {
			setPageState((pageState) => ({
				...pageState,
			}));
			const result = await portalApi.checkValidationCode(publicId, confirmCode);
			if (result.validated) {
				setPageState((pageState) => ({
					...pageState,
					checkValidationCodeResult: result,
					step: "REDIRECT_TO_PORTAL",
				}));
			} else {
				setPageState((pageState) => ({
					...pageState,
					checkValidationCodeResult: result,
				}));
			}
		} catch (err) {
			setError();
		}
	};

	useEffect(() => {
		let pollAttempts = 0;
		let pollTimer: NodeJS.Timeout | null = null;
		let destroy = false;

		const pollAuthState = (publicId: string, since: Date) => {
			pollTimer = setTimeout(() => {
				pollAttempts++;
				portalApi
					.getAuthState(publicId, since)
					.then((authState) => {
						if (authState.portalInSession) {
							setPageState((pageState) => ({
								...pageState,
								step: "REDIRECT_TO_PORTAL",
								authState,
							}));
						} else {
							setPageState((pageState) => ({ ...pageState, authState }));
							if (!destroy && pollAttempts <= POLL_AUTH_STATE_MAX_TIMES) {
								pollAuthState(publicId, since);
							}
						}
					})
					.catch((reason) => {
						// noop - will stop polling on error
					});
			}, POLL_AUTH_STATE_INTERVAL_MS);
		};

		if (pageState.step === "CONFIRM_CODE" && publicId && pageState.sendValidationCodeResult) {
			pollAttempts = 0;
			pollAuthState(publicId, pageState.sendValidationCodeResult.issuedDatetime);
		}

		return () => {
			destroy = true;
			if (pollTimer !== null) {
				clearTimeout(pollTimer);
			}
		};
	}, [pageState.step, pageState.sendValidationCodeResult, publicId]);

	if (!publicId) {
		return (
			<ErrorLayout>
				<div className="errorWrap">
					<Page404 />
				</div>
			</ErrorLayout>
		);
	}

	if (pageState.step === "NOT_FOUND") {
		return (
			<ErrorLayout>
				<div className="errorWrap">
					<Page404 />
				</div>
			</ErrorLayout>
		);
	}

	if (pageState.step === "ERROR") {
		return (
			<ErrorLayout>
				<div className="errorWrap">
					<FatalError />
				</div>
			</ErrorLayout>
		);
	}

	if (pageState.step === "LOAD") {
		return <Loader></Loader>;
	}

	// core user detected - send to core to authenticate
	if (pageState.step === "REDIRECT_TO_CORE") {
		if (!pageState.coreAuthUrl) {
			return (
				<ErrorLayout>
					<div className="errorWrap">
						<FatalError />
					</div>
				</ErrorLayout>
			);
		}
		window.location.assign(pageState.coreAuthUrl);
		return <Loader></Loader>;
	}

	// portal is active, redirect to portal page
	if (pageState.step === "REDIRECT_TO_PORTAL") {
		return <Navigate to={"/" + publicId}></Navigate>;
	}

	// no contact methods available to validate portal - show error
	if (!pageState.contactMethods || pageState.contactMethods.length === 0) {
		return (
			<ErrorLayout>
				<Centered vertical={true}>
					<FatalError />
				</Centered>
			</ErrorLayout>
		);
	}

	let nameToUse = "";
	if (pageState.firstName) nameToUse = " " + pageState.firstName;
	else if (pageState.fullName) nameToUse = " " + pageState.fullName;

	// const lossText = pageState.lossDate ? (
	// 	<>
	// 		{", stemming from an event on "}
	// 		<strong>{pageState.lossDate}</strong>
	// 	</>
	// ) : (
	// 	""
	// );

	// let carrierName = pageState.carrierName ? (
	// 	<>
	// 		{", "}
	// 		<strong>{pageState.carrierName}</strong>
	// 	</>
	// ) : (
	// 	""
	// );
	const lossText = pageState.lossDate ? ", stemming from an event on " + pageState.lossDate : "";

	const carrierName = pageState.carrierName ? pageState.carrierName : "";

	const i18nstrings = {
		"activate-page-introduction": intl.formatMessage(
			{
				id: "activate-page-introduction",
				defaultMessage:
					"You recently reported property damage to your insurance company {carrierName} {lossText}. Accuserve is partnering with them to restore your property while minimizing the disruption this may cause. To follow the progress of your claim in the Accuserve Claim Tracker, select a method to receive your activation code below.",
			},
			{
				carrierName: carrierName,
				lossText: lossText,
			}
		),
		"activate-page-activate-portal": intl.formatMessage({
			id: "activate-page-activate-portal",
			defaultMessage: "Activate Portal",
		}),
		"activate-page-greeting-hello": intl.formatMessage({
			id: "activate-page-greeting-hello",
			defaultMessage: "Hello",
		}),
	};

	let introTitle = `${i18nstrings["activate-page-greeting-hello"]}${nameToUse},`;
	let introCopy = <>{i18nstrings["activate-page-introduction"]}</>;

	return (
		<>
			<Helmet />
			<PrimaryHeaderSimple />
			<Centered>
				<PageIntroSimple title={introTitle} copy={introCopy} />

				<Card className="activate-card mt-3">
					<Card.Header>
						<Card.Title className="mb-0">
							<h3 className="activate-card__title">{i18nstrings["activate-page-activate-portal"]}</h3>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						{pageState.step === "CHOOSE_CONTACT" &&
							pageState.contactMethods &&
							pageState.selectedContactMethod && (
								<SelectContactMethod
									contactMethods={pageState.contactMethods}
									selectedContactMethod={pageState.selectedContactMethod}
									onSelectContactMethod={(contactMethod) => {
										setPageState({ ...pageState, selectedContactMethod: contactMethod });
									}}
									onConfirm={(contactMethod: ContactMethod) => {
										sendCode(publicId, contactMethod);
									}}
								/>
							)}

						{pageState.step === "CONFIRM_CODE" && (
							<ConfirmCode
								validCode={
									pageState.checkValidationCodeResult
										? pageState.checkValidationCodeResult.validated
										: false
								}
								invalidCode={
									pageState.checkValidationCodeResult
										? !pageState.checkValidationCodeResult.validated
										: false
								}
								codeUsed={!!pageState.authState?.codeUsed}
								onConfirm={(confirmCode) => {
									checkCode(publicId, confirmCode);
								}}
							/>
						)}
					</Card.Body>
				</Card>
			</Centered>
			<PrimaryFooterSimple />
		</>
	);
};

export default ActivatePage;
