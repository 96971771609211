export const PLURALS = {
	"Accuserve has received your [service] claim and is reaching out to schedule an inspection of your property.":
		"Accuserve has received your [service] claims and is reaching out to schedule an inspection of your property.",
	"We are locating a [service] contractor who will inspect your property.":
		"We are locating [service] contractors who will inspect your property.",
	"The [service] contractor is scheduled to inspect your property.":
		"The [service] contractors are scheduled to inspect your property.",
	"A [service] contractor has been assigned and the next step is for them to inspect your property.":
		"[service] contractors have been assigned and the next step is for them to inspect your property.",
	"We are working with the [service] contractor to create an estimate for any repairs needed.":
		"We are working with the [service] contractors to create an estimate for any repairs needed.",
	"Good news! The [service] estimate is approved by your insurance provider. Your signature is now needed to authorize the work.":
		"Good news! The [service] estimates are approved by your insurance provider. Your signature is now needed to authorize the work.",
	"We are working with the [service] contractor to plan and schedule your repairs.":
		"We are working with the contractors to plan and schedule your [service].",
	"We have a target start date for your [service] service.":
		"We have a target start date for the [service] services.",
	"Good news! The estimate for [service] has been approved by your insurance provider.":
		"Good news! The estimates for [service] have been approved by your insurance provider.",
	"Accuserve is working with the [service] contractor to complete the service at your property.":
		"Accuserve is working with the contractors to complete [service] at your property.",
	"We are currently working closely with the [service] to complete repairs to your property.":
		"We are currently working closely with the [service] contractors to complete repairs to your property.",
	"Now that the [service] is complete, we are reaching out to confirm that you are satisfied with the work.":
		"Now that [service] are complete, we are reaching out to confirm that you are satisfied with the work.",
	"It has been a pleasure working to complete the [service] at your property. If other services are required within this claim, we will continue that work.":
		"It has been a pleasure working to complete the [service] at your property. If other services are required within this claim, we will continue that work.",
	"Accuserve is not currently managing the [service] service at your property. If anything changes and you would like our help, please contact us.":
		"Accuserve is not currently managing the [service] service at your property. If anything changes and you would like our help, please contact us.",
};
