import Loader from "components/Loader";
import PrimaryFooter from "components/PrimaryFooter";
import PrimaryHeader from "components/PrimaryHeader";
import PrimarySidebar from "components/PrimarySidebar";
import ErrorLayout from "layouts/ErrorLayout";
import PrimaryContent from "layouts/PrimaryContent";
import FatalError from "pages/error/FatalError";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PortalContext } from "contexts/PortalContext";
import { prepareOtherPortals } from "utils/portal";

const PortalTermsES = () => {
	const { portal, portalList, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	if (error) {
		return (
			<ErrorLayout>
				<FatalError />
			</ErrorLayout>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || !portalList || isLoading) {
		return <Loader></Loader>;
	}

	const otherPortalsList = prepareOtherPortals(portalList, publicId);

	return (
		<>
			<Helmet />
			<PrimaryHeader
				publicId={publicId}
				claim={portal.claim}
				otherPortalsList={otherPortalsList}
				hideHistory={false}
			/>
			<PrimaryContent>
				<div className="g-section">
					<div className="g-layout-sidebar">
						<Link
							className="back-btn"
							to={`/${publicId}`}
							title="Back to dashboard"
							aria-label="Back to dashboard"
						>
							Volver al panel
						</Link>
					</div>
				</div>

				<div className="g-section">
					<div className="g-layout-sidebar">
						<div className="copy-content">
							<div className="copy-content--title">
								<h1>Términos del servicio</h1>
							</div>
							<div className="copy-content--card">
								<p className="copy-content--updated">
									Última actualización: 3 de febrero de 2022 12:05 p. m.
								</p>

								<p>
									<em>
										Lea estos Términos de servicio (“Términos,” “Términos de servicio”) atentamente
										antes de utilizar el Sitio web www.accuserve.com (el “Sitio web”) que gestiona
										Accuserve Solutions, Inc., una empresa constituida en Delaware, Estados Unidos
										(“nosotros”, “nuestro/a”), ya que estos Términos de servicio contienen
										información importante con relación a las limitaciones de nuestra
										responsabilidad. El acceso y uso de este Sitio web está condicionado a la
										aceptación y cumplimiento de estos Términos. Estos Términos aplican a todas las
										personas, incluidos, pero no limitado a, usuarios y otros que deseen acceder y
										utilizar el Sitio web.
									</em>
								</p>
								<p>
									<em>
										Al usar o acceder al Sitio web, usted acepta estos Términos. Si no está de
										acuerdo con alguna parte de estos Términos, entonces no cuenta con nuestra
										autorización para utilizar o acceder al Sitio web.
									</em>
								</p>

								<strong>
									<em>Nuestras ofertas y precios</em>
								</strong>
								<p>
									<em>
										WEn este Sitio web ofrecemos servicios. El precio de estos servicios se
										determina según sus necesidades, lo que significa que le brindaremos un
										presupuesto después de determinar sus necesidades. Este precio incluye todos los
										impuestos aplicables y demás cargos.
									</em>
								</p>

								<strong>
									<em>Política de cancelación</em>
								</strong>
								<p>
									<em>
										No le brindamos la posibilidad de cancelar ninguna de las compras que haya hecho
										de los servicios ofrecidos en nuestro Sitio web.
									</em>
								</p>
								<p>
									<em>
										Nos reservamos el derecho de cancelar sus compras por cualquier motivo y a
										nuestro exclusivo criterio, incluidos, entre otros, el fraude, las imprecisiones
										y la falta de disponibilidad de los artículos o servicios comprados. Si
										planeamos cancelar su compra, se lo informaremos de inmediato.
									</em>
								</p>
								<p>
									<em>
										Si cancelamos su compra, realizaremos un reembolso parcial del precio de compra
										que pagó.
									</em>
								</p>

								<strong>
									<em>Política de reembolso</em>
								</strong>
								<p>
									<em>No ofrecemos reembolsos por compras realizadas en este Sitio web.</em>
								</p>

								<h2>Garantía de compras</h2>
								<p>LOS SERVICIOS SE REALIZARÁN CON UNA PRECAUCIÓN Y PERICIA RAZONABLES;</p>

								<ul>
									<li>LOS SERVICIOS SE REALIZARÁN CON UNA PRECAUCIÓN Y PERICIA RAZONABLES;</li>
									<li>LOS SERVICIOS SE REALIZARÁN DENTRO DE UN PLAZO RAZONABLE.</li>
								</ul>

								<p>
									A EXCEPCIÓN DE LO ESTABLECIDO ANTERIORMENTE, NINGUNA OTRA GARANTÍA EXPLÍCITA O
									IMPLÍCITA (INCLUIDA CUALQUIER GARANTÍA IMPLÍCITA DE COMERCIABILIDAD, DE CALIDAD
									SATISFACTORIA O IDONEIDAD PARA UN PROPÓSITO O USO EN PARTICULAR) SE APLICARÁ A LOS
									SERVICIOS EXHIBIDOS O A LA VENTA EN ESTE SITIO WEB, YA SEA QUE SURJA DE LA LEY, DEL
									CURSO DE LA NEGOCIACIÓN, DEL CURSO DE EJECUCIÓN, DEL USO DEL COMERCIO U OTRO. LOS
									PLAZOS DE LA GARANTÍA VARÍAN SEGÚN EL TIPO DE SERVICIO PRESTADO. PARA OBTENER MÁS
									INFORMACIÓN, CONSULTE NUESTRO ACUERDO.
								</p>

								<strong>Recursos</strong>
								<p>
									Usted acepta que los recursos por incumplimiento de estos Términos de servicio con
									relación a su compra serán:
								</p>
								<ul>
									<li>reparación;</li>
									<li>reemplazo.</li>
								</ul>
								<p>
									También acepta que el recurso por incumplimiento de estos Términos de servicio con
									relación a su compra será buscar la resolución de disputas según lo dispuesto en la
									sección “ley aplicable, divisibilidad, resolución de disputas, lugar y renuncia a
									demandas colectivas” más adelante. Se pretende que estos recursos constituyan los
									únicos y exclusivos recursos con los que cuenta usted ante cualquier incumplimiento
									de estos Términos de servicio con relación a una compra.
								</p>

								<strong>Usos prohibidos</strong>
								<p>
									Usted acepta que utilizará este Sitio web de acuerdo con todas las leyes, normas y
									regulaciones aplicables y con estos Términos en todo momento. La siguiente lista
									incluye los usos prohibidos de este Sitio web, aunque no de manera exhaustiva. Usted
									acepta que no llevará a cabo ninguno de los siguientes usos prohibidos:
								</p>

								<ol>
									<li>
										suplantar o intentar suplantar la identidad de Accuserve Solutions, Inc. o
										alguno de sus empleados, representantes, filiales o reparticiones;
									</li>
									<li>falsificar su identidad o afiliación con cualquier persona o entidad;</li>
									<li>
										enviar o intentar enviar cualquier material publicitario o promocional,
										incluidos, entre otros, el spam, el correo basura, el correo en cadena o
										cualquier material similar;
									</li>
									<li>
										participar en conductas que restrinjan o inhiban el uso o goce del sitio web por
										parte de otras personas, o que, según se determine a exclusivo criterio de la
										Empresa, puedan perjudicar a la Empresa o a los usuarios del Sitio web o exponer
										a responsabilidades a la Empresa o a los usuarios;
									</li>
									<li>
										utilizar el Sitio web de formas que puedan deshabilitarlo, sobrecargarlo,
										dañarlo o perjudicarlo o interferir con el uso del Sitio web por parte de
										terceros;
									</li>
									<li>
										utilizar robots, arañas u otra tecnología, proceso o medio automático similar
										para acceder o utilizar el Sitio web para cualquier propósito, incluida la
										vigilancia o copia del material del Sitio web;
									</li>
									<li>
										utilizar cualquier proceso o medio manual para vigilar o copiar el material de
										este Sitio web o para cualquier otro fin no autorizado;
									</li>
									<li>
										utilizar cualquier dispositivo, software, medio o rutina que interfiera con el
										correcto funcionamiento del Sitio web, incluidos, entre otros, los virus, los
										troyanos, los gusanos, las bombas lógicas u otros materiales similares;
									</li>
									<li>
										intentar obtener acceso no autorizado, interferir, dañar o interrumpir cualquier
										parte del Sitio web, los servidores en los que se almacena el Sitio web o
										cualquier servidor, computadora o base de datos conectados al Sitio web;
									</li>
									<li>
										intentar atacar o atacar el Sitio web a través de un ataque de denegación de
										servicio o un ataque de denegación de servicio distribuido;
									</li>
									<li>
										intentar interferir con el correcto funcionamiento del Sitio web de cualquier
										modo;
									</li>
									<li>
										usar el Sitio web en cualquier forma que infrinja las leyes, normas o
										regulaciones federales, estatales o locales.
									</li>
								</ol>

								<h3>Sitio web sin garantías</h3>
								<p>
									EL SITIO WEB SE BRINDA “TAL CUAL”, NINGUNA GARANTÍA EXPLÍCITA O IMPLÍCITA (INCLUIDA
									CUALQUIER GARANTÍA IMPLÍCITA DE COMERCIABILIDAD, DE CALIDAD SATISFACTORIA O
									IDONEIDAD PARA UN PROPÓSITO O USO EN PARTICULAR) SE APLICARÁ A ESTE SITIO WEB, YA
									SEA QUE SURJA DE LA LEY, DEL CURSO DE LA NEGOCIACIÓN, DEL CURSO DE EJECUCIÓN, DEL
									USO DEL COMERCIO U OTRO.
								</p>

								<strong>Disponibilidad, errores e imprecisiones</strong>
								<p>
									No asumimos ninguna responsabilidad por la disponibilidad, los errores o las
									imprecisiones de la información, los productos o los servicios ofrecidos en este
									Sitio web. Es posible que experimentemos demoras para actualizar la información del
									Sitio web y de nuestros anuncios en otros sitios web. La información, los productos
									y los servicios que se encuentran en el Sitio web pueden contener errores o
									imprecisiones o pueden estar incompletos o desactualizados. Los productos y los
									servicios pueden tener un precio incorrecto o no estar disponibles. Nos reservamos
									expresamente el derecho de corregir cualquier error de precios en nuestro Sitio web.
									La inclusión u oferta de cualquier producto o servicio en el Sitio web no constituye
									la aprobación o recomendación de dicho producto o servicio por parte de la Empresa.
								</p>

								<h3>Daños y limitación de responsabilidad</h3>
								<p>
									EN NINGÚN CASO ACCUSERVE SOLUTIONS, INC. SERÁ RESPONSABLE ANTE CUALQUIER DAÑO
									DIRECTO, INDIRECTO, PUNITIVO, INCIDENTAL, ESPECIAL O CONSECUENTE QUE SURJA DE O ESTÉ
									RELACIONADO O CONECTADO DE CUALQUIER MANERA CON SU ACCESO A LA VISUALIZACIÓN O USO
									DE ESTE SITIO WEB O CON EL RETRASO O INCAPACIDAD PARA ACCEDER, VISUALIZAR O UTILIZAR
									ESTE SITIO WEB, INCLUIDO, ENTRE OTROS, SU CONFIANZA EN LAS OPINIONES O EN LA
									INFORMACIÓN QUE APARECE EN ESTE SITIO WEB; CUALQUIER VIRUS INFORMÁTICO, INFORMACIÓN,
									SOFTWARE, SITIOS WEB VINCULADOS OPERADOS POR TERCEROS, PRODUCTOS O SERVICIOS
									OBTENIDOS A TRAVÉS DE ESTE SITIO WEB, YA SEA BASADO EN UNA TEORÍA DE NEGLIGENCIA,
									CONTRATO, AGRAVIO, RESPONSABILIDAD ESTRICTA, ESTATUTOS DE PROTECCIÓN AL CONSUMIDOR U
									OTROS INSTRUMENTOS, INCLUSO SI ACCUSERVE SOLUTIONS, INC. FUE ADVERTIDO DE LA
									POSIBILIDAD DE TALES DAÑOS.
								</p>
								<p>
									ESTA LIMITACIÓN DE RESPONSABILIDAD REFLEJA LA ASIGNACIÓN DE RIESGO ENTRE USTED Y
									NOSOTROS. LAS LIMITACIONES ESPECIFICADAS EN ESTA SECCIÓN SUBSISTIRÁN Y SE APLICARÁN
									INCLUSO SI ALGÚN RECURSO LIMITADO ESPECIFICADO EN ESTOS TÉRMINOS DE USO NO CUMPLIÓ
									CON SU PROPÓSITO ESENCIAL. LAS LIMITACIONES DE RESPONSABILIDAD ESTABLECIDAS EN ESTOS
									TÉRMINOS DE USO REDUNDARÁN EN BENEFICIO DE ACCUSERVE SOLUTIONS, INC. LA
									RESPONSABILIDAD TOTAL DE ACCUSERVE SOLUTIONS, INC. DERIVADA DE, O RELACIONADA CON,
									ESTE SITIO WEB, YA SEA DERIVADA DE, O RELACIONADA CON, EL INCUMPLIMIENTO DEL
									CONTRATO, AGRAVIOS (INCLUIDA LA NEGLIGENCIA) U OTRAS FORMAS, SE LIMITARÁ A LA
									CANTIDAD DE HONORARIOS QUE ACCUSERVE SOLUTIONS, INC. PERCIBA EFECTIVAMENTE POR PARTE
									DE USTED.
								</p>

								<strong>Enlaces a sitios web de terceros</strong>
								<p>
									Este Sitio web puede contener hipervínculos a sitios web gestionados por terceros y
									no por nosotros. Solo proporcionamos dichos hipervínculos a modo de referencia. No
									controlamos tales sitios web y no somos responsables del contenido, privacidad y
									otras prácticas de tales sitios web. Asimismo, es su responsabilidad tomar
									precauciones para asegurarse de que cualquier enlace en el que haga clic o de que
									cualquier software que descargue, ya sea desde este sitio web u otros sitios web o
									aplicaciones, estén libres de elementos como virus, gusanos, troyanos, defectos y
									otros elementos de carácter destructivo. La inclusión de hipervínculos en tales
									sitios web por nuestra parte no implica ninguna clase de aprobación del material en
									tales sitios web ni ningún tipo de asociación con quienes los gestionan.
								</p>

								<strong>
									Notificaciones de propiedad intelectual y DMCA y procedimiento para reclamos de
									infracción de propiedad intelectual
								</strong>
								<p>
									Todos los contenidos de este Sitio web pertenecen a ©2021-2022 Accuserve Solutions,
									Inc. o a terceros. Todos los derechos reservados. A menos que se especifique lo
									contrario, este Sitio web y todo el contenido y otros materiales en este Sitio web,
									incluidos, entre otros, los logotipos, los diseños, el texto, los gráficos, las
									imágenes, la información, los datos, el software, los archivos de sonido y la
									disposición de estos (en conjunto denominados “Contenido”) son propiedad de
									Accuserve Solutions, Inc. y son marcas comerciales registradas o marcas comerciales
									o son propiedad intelectual protegida de Accuserve Solutions, Inc. o de terceros en
									los Estados Unidos u otros países.
								</p>
								<p>
									Si tiene conocimiento de una posible infracción de nuestra propiedad intelectual,
									comuníquese con <a href="mailto:marketing@accuserve.com">marketing@accuserve.com</a>
									.
								</p>
								<p>
									Nosotros respetamos la propiedad intelectual de los demás. Es nuestra política
									responder a cualquier reclamo que afirme que el Contenido publicado en el Sitio web
									infringe los derechos de autor, la marca comercial o cualquier otro derecho de
									propiedad intelectual de otras personas o entidades.
								</p>
								<p>
									Si cree de buena fe que el Contenido infringe sus derechos de propiedad intelectual,
									usted o su representante pueden enviarnos una notificación escrita de tal infracción
									bajo el título “Infracción de los derechos de propiedad intelectual: DMCA”. La
									notificación debe incluir la siguiente información:
								</p>

								<ol>
									<li>
										una firma física o electrónica de la persona autorizada para actuar en nombre
										del interés del titular del derecho de la propiedad intelectual;
									</li>
									<li>
										una descripción del trabajo sobre el cual, usted afirma, se cometió la
										infracción, incluido el URL (por ejemplo, la dirección de la página web) de la
										ubicación donde se encuentra el trabajo o una copia del trabajo;
									</li>
									<li>su nombre, correo electrónico, domicilio y número de teléfono; y</li>
									<li>
										una declaración de que usted cree de buena fe que el uso en disputa no está
										autorizado por el titular del trabajo, por su representante o por la ley.
									</li>
								</ol>

								<p>
									Tenga en cuenta que no procesaremos el reclamo si no está debidamente cumplimentado
									o está incompleto. Usted puede ser responsable por los daños, incluidos, entre
									otros, los costos y los honorarios de los abogados como consecuencia de cualquier
									declaración falsa o reclamo de mala fe relacionados con la infracción de los
									derechos de propiedad intelectual por parte del Contenido de este Sitio web.
								</p>
								<p>Puede enviarnos su reclamo a:</p>

								<p>
									Accuserve Solutions, Inc
									<br />
									<a href="mailto:marketing@accuserve.com">marketing@accuserve.com</a>
									<br />
									<a href="tel:937-328-4300">937-328-4300</a>
								</p>

								<p>ESTADOS UNIDOS</p>

								<p>
									<strong>Ley aplicable, divisibilidad, resolución de disputas y lugar</strong>
								</p>
								<p>
									Estos Términos se regirán e interpretarán de acuerdo con las leyes del estado de
									Delaware, Estados Unidos, independientemente de su conflicto con las disposiciones
									legales. ESTOS TÉRMINOS NO SE REGIRÁN POR LA CONVENCIÓN DE LAS NACIONES UNIDAS SOBRE
									CONTRATOS PARA LA VENTA DE BIENES INTERNACIONALES, EL CÓDIGO UNIFORME DE COMERCIO NI
									LOS INCOTERMS.
								</p>
								<p>
									Nuestra incapacidad para hacer cumplir cualquier derecho o disposición de estos
									Términos no se considerará una renuncia a tal derecho o disposición. Si un tribunal
									considera inválida o inexigible alguna disposición de estos Términos, las
									disposiciones restantes de estos Términos permanecerán vigentes. Estos Términos
									constituyen el acuerdo completo entre usted y nosotros con respecto a nuestro Sitio
									web y revocan y reemplazan cualquier acuerdo anterior que hayamos tenido con usted
									con respecto al Sitio web.
								</p>
								<p>
									Cualquier controversia o reclamo que surja de, o esté relacionado con, estos
									Términos, incluidos, entre otros, la interpretación o el incumplimiento de estos, se
									resolverá en un tribunal de jurisdicción competente en el condado de Delaware.
								</p>
								<p>
									USTED Y ACCUSERVE SOLUTIONS, INC. ACEPTAN QUE CADA UNO PUEDE PRESENTAR RECLAMOS
									CONTRA EL OTRO ÚNICAMENTE DE MANERA INDIVIDUAL Y NO COMO DEMANDANTE O MIEMBRO DE
									CLASE EN CUALQUIER ACCIÓN COLECTIVA O REPRESENTATIVA.
								</p>
								<p>
									Nos reservamos el derecho de realizar modificaciones a estos Términos de servicio en
									cualquier momento. No le enviaremos ningún aviso cuando realicemos modificaciones de
									estos Términos de servicio.
								</p>

								<p>
									<strong>Consultas</strong>
								</p>
								<p>
									Si tiene consultas sobre nuestros Términos de servicio, comuníquese con nosotros
									mediante <a href="mailto:info@accuserve.com">info@accuserve.com</a>.
								</p>
							</div>
						</div>

						<PrimarySidebar
							publicId={publicId}
							claim={portal.claim}
							isAbsolute={true}
							otherPortalsList={null}
							hideHistory={false}
						/>
					</div>
				</div>
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalTermsES;
