import { FieldItem, ItemAdder, FieldRegistry, Field } from "model/types/Fields";
import { ReactNode } from "react";

type IconListValue = FieldRegistry<boolean>;

export type IconItem = FieldItem<string | number> & {
	selectedClass?: string;
};

export type SelectType = "icon" | "checkbox";

export type SelectSize = "small" | "medium";

interface IconSelectState {
	item: IconItem;
	addItem: ItemAdder<IconItem>;
	value: IconListValue;
	selectType: SelectType;
}

export type IconSelectField = Field<IconListValue, IconItem> & {
	label?: string | ReactNode;
};

const getItemAdder = ({ name, value, isMulti, saveValue }: IconSelectField): ItemAdder<IconItem> => {
	return (item) => {
		let update: IconSelectField["value"] = {};

		if (value[item.value]) {
			update = {
				...value,
				...{ [item.value]: false },
			};
		} else if (isMulti) {
			update = {
				...value,
				...{ [item.value]: true },
			};
		} else {
			update = { [item.value]: true };
		}
		saveValue(name, update);
	};
};

const selectListClasses = {
	icon: {
		selectListArea: "iconSelectListArea",
		selectListLabel: "iconSelectListLabel",
		selectList: "iconSelectList",
		selectItem: "iconSelectList__selectItem",
	},
	checkbox: {
		selectListArea: "checkboxSelectListArea",
		selectListLabel: "checkboxSelectListLabel",
		selectList: "checkboxSelectList",
		selectItem: "checkboxSelectList__selectItem",
	},
};

const IconSelectItem = ({ item, addItem, value, selectType }: IconSelectState) => {
	const classes = [
		selectListClasses[selectType].selectItem,
		value[item.value] ? "selected" : "",
		item.selectedClass ? item.selectedClass : "",
	]
		.filter((c) => !!c)
		.join(" ");
	return (
		<div
			className={classes}
			onClick={(_e) => {
				addItem(item);
			}}
		>
			{item.element}
		</div>
	);
};

export const IconSelectList = ({
	field,
	selectType,
	size = "medium",
}: {
	field: IconSelectField;
	selectType: SelectType;
	size?: SelectSize;
}) => {
	const addItem: ItemAdder<IconItem> = getItemAdder(field);

	const labelElement = field.label ? <label>{field.label}</label> : <></>;

	return (
		<div className={selectListClasses[selectType].selectListArea}>
			<div className={selectListClasses[selectType].selectListLabel}>{labelElement}</div>
			<div
				className={
					selectListClasses[selectType].selectList +
					" " +
					selectListClasses[selectType].selectList +
					"--" +
					size
				}
			>
				{field.items.map((item) => (
					<IconSelectItem
						key={item.value}
						item={item}
						addItem={addItem}
						value={field.value}
						selectType={selectType}
					/>
				))}
			</div>
		</div>
	);
};
