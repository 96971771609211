import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/Date/DateFromISOString";
import { UUID } from "io-ts-types/lib/UUID";

const NoteRequired = t.type({
	noteId: UUID,
	serviceId: UUID,
});

// these may or may not be assigned a value, so this
// portion of the service definition is considered
// optional
const NoteOptional = t.partial({
	noteText: t.union([t.null, t.string]),
	noteDateTime: t.union([t.null, DateFromISOString]),
});

export const NoteCodec = t.intersection([NoteRequired, NoteOptional]);

export type Note = t.TypeOf<typeof NoteCodec>;
