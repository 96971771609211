import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { ServiceTypeCodec } from "model/dto/service-type";
import { SubstatusTypeCodec } from "model/dto/substatus-type";
import { ZonedDateTimeFromString } from "model/types/TimeZonedDate";

const Argument = t.type({
	name: t.string,
	value: t.string,
});

const JournalEventToken = t.type({
	arguments: t.array(Argument),
	token: t.string,
});

const JournalEntryRequired = t.type({
	claimId: UUID,
	eventTimestamp: ZonedDateTimeFromString,
	eventTitle: t.string,
	eventType: t.string,
	eventTextToken: t.union([t.null, t.undefined, JournalEventToken]),
	eventTitleToken: t.union([t.null, t.undefined, JournalEventToken]),
});

// optional event entry parameters
const JournalEntryOptional = t.partial({
	serviceId: UUID,
	eventText: t.string,
	serviceType: ServiceTypeCodec,
	portalSubStatus: SubstatusTypeCodec,
});

export const JournalEntryCodec = t.intersection([JournalEntryRequired, JournalEntryOptional]);

export type JournalEntry = t.TypeOf<typeof JournalEntryCodec>;
