import React, { ReactElement, useState } from "react";
import { useIntl } from "i18n/Internationalization";

export type SequencerComplete = () => void;
export type SequencerStepComplete = () => void;
export type SequencerStepBack = () => void;
export type SequencerSetStep = (direction: number) => void;
export type SequencerAllowProceed = () => boolean;

export type SequencerGroup = {
	label: string;
	active?: boolean;
	show: boolean;
};
export type SequencerStep = {
	show: boolean;
	terminal: boolean;
	element: ReactElement;
	allowProceed?: SequencerAllowProceed;
	onComplete?: SequencerStepComplete;
	onBack?: SequencerStepBack;
	buttonNext?: string;
	buttonBack?: string;
	butonClose?: string;
};

export type SequencerStepGrouped<Group extends SequencerGroup> = SequencerStep & {
	group: Group["label"];
};

export type SequencerConfig<SequencerStep> = {
	steps: SequencerStep[] | SequencerStepGrouped<SequencerGroup>[];
	groups?: SequencerGroup[];
	onComplete: SequencerComplete;
};

type SequencerActionState<SequencerStep> = {
	setStep: SequencerSetStep;
	onComplete: SequencerComplete;
	currentStep: SequencerStep;
	currentIdx: number;
	total: number;
};

const SequencerGroups = ({ groups }: { groups: SequencerGroup[] }) => {
	const groupsToShow = groups
		.filter(({ show }) => show)
		.map((group) => {
			return group;
		});
	const hasActiveGroups = groupsToShow.filter(({ active }) => active).length > 0;
	let groupContent;
	if (hasActiveGroups) {
		groupContent = groupsToShow.map((group) => {
			const classes = ["sequencer_groups--items__item", group.active ? "active" : ""].join(" ");
			return (
				<div key={group.label} className={classes}>
					{group.label}
				</div>
			);
		});
	}
	return hasActiveGroups ? (
		<div className="sequencer_groups">
			<div className="sequencer_groups--items">{groupContent}</div>
		</div>
	) : (
		<></>
	);
};

const SequencerActions = ({ setStep, currentStep, currentIdx, total, onComplete }: SequencerActionState<any>) => {
	const intl = useIntl();

	const actionVisibility = {
		back: "",
		next: "",
		step: "",
		close: "",
	};

	if (currentStep.terminal) {
		actionVisibility.back = "none";
		actionVisibility.next = "none";
		actionVisibility.step = "none";
		actionVisibility.close = "";
	} else {
		actionVisibility.back = currentIdx > 0 ? "" : "hidden";
		actionVisibility.next = currentIdx < total - 1 ? "" : "hidden";
		actionVisibility.step = "";
		actionVisibility.close = "none";
	}
	const okToProceed = currentStep.allowProceed ? currentStep.allowProceed() : true;

	const nextStep = () => {
		if (currentStep.onComplete) {
			currentStep.onComplete();
		}
		setStep(1);
	};

	const prevStep = () => {
		if (currentStep.onBack) {
			currentStep.onBack();
		}
		setStep(-1);
	};

	return (
		<div className="sequencer_actions">
			<button
				className={`sequencer_actions--button button ${actionVisibility.back}`}
				onClick={() => prevStep()}
				aria-label="Back"
			>
				{currentStep.buttonBack
					? currentStep.buttonBack
					: intl.formatMessage({
							id: "feedback-modal-back",
							defaultMessage: "Back",
					  })}
			</button>
			<div className={`sequencer_actions--progress ${actionVisibility.step}`}>
				<span>{currentIdx + 1}</span> of {total}
			</div>
			{/* if allowProceed is undefined, we assume it's allowed */}
			<button
				className={`sequencer_actions--button button primary ${actionVisibility.next} ${
					okToProceed ? "" : "disabled"
				}`}
				onClick={() => okToProceed && nextStep()}
				aria-label="Next"
			>
				{currentStep.buttonNext
					? currentStep.buttonNext
					: intl.formatMessage({
							id: "feedback-modal-next",
							defaultMessage: "Next",
					  })}
			</button>
			<button
				className={`sequencer_actions--button button primary close ${actionVisibility.close}`}
				onClick={onComplete}
				aria-label="Close"
			>
				{currentStep.buttonClose
					? currentStep.buttonClose
					: intl.formatMessage({
							id: "feedback-modal-close",
							defaultMessage: "Close",
					  })}
			</button>
		</div>
	);
};

export const Sequencer = ({ config }: { config: SequencerConfig<any> }) => {
	const [currentIdx, setCurrentIdx] = useState<number>(0);
	const total = config.steps.length;

	const setActiveGroup = () => {
		config.groups?.forEach((g) => {
			g.active = g.label === config.steps[currentIdx].group;
		});
	};

	setActiveGroup();

	const setStep = (direction: number) => {
		let idx = currentIdx;

		if (direction === 1) {
			idx = config.steps.findIndex((s, i) => i > currentIdx && s.show);
		} else {
			// really wish we had findLastIndex support... :(
			for (let i = 0; i < config.steps.length; i++) {
				if (i < currentIdx && config.steps[i].show) {
					idx = i;
				}
			}
		}
		setCurrentIdx(idx);
		setActiveGroup();
	};

	const getCurrentContent = () => {
		if (config.steps[currentIdx]) {
			return config.steps[currentIdx].element;
		} else {
			return <React.Fragment key={currentIdx}></React.Fragment>;
		}
	};

	const completeCleanup = () => {
		config.onComplete();
		setTimeout(() => {
			setCurrentIdx(0);
		}, 1000); // visual to prevent flash of first step
	};

	return (
		<>
			{config.groups ? <SequencerGroups groups={config.groups} /> : <></>}
			<div className="primary-dialog__content--body">{getCurrentContent()}</div>
			<SequencerActions
				setStep={setStep}
				onComplete={completeCleanup}
				currentIdx={currentIdx}
				currentStep={config.steps[currentIdx]}
				total={total}
			/>
		</>
	);
};
