import { ReactNode, useState } from "react";

import { IntlProvider } from "i18n/Internationalization";
import { Locale, LocaleProvider, useLocale } from "i18n/LocaleContext";

const getLocale = () => {
	const browserLanguage = navigator.language.split(/[-_]/)[0] as Locale;
	if ([Locale.ENGLISH, Locale.SPANISH].includes(browserLanguage)) {
		return browserLanguage;
	}
	return Locale.ENGLISH;
};

const LibraryWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { currentLocale, messages } = useLocale();

	return (
		<IntlProvider locale={currentLocale} messages={messages[currentLocale]} defaultLocale={Locale.ENGLISH}>
			{children}
		</IntlProvider>
	);
};

export const TranslationsWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [currentLocale, setCurrentLocale] = useState<Locale>(getLocale());

	return (
		<LocaleProvider currentLocale={currentLocale} setCurrentLocale={setCurrentLocale}>
			<LibraryWrapper>{children}</LibraryWrapper>
		</LocaleProvider>
	);
};
