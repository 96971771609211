import { randomishString } from "utils/util";

/**
 * Hook to create an unique ID
 * @param {string} token String to prepend to the returned ID
 * @returns {string}
 */
const useId = (token?: string) => {
	const id = randomishString();
	return token ? `${token}-${id}` : id;
};

export default useId;
