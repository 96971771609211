import Loader from "components/Loader";
import PrimaryFooter from "components/PrimaryFooter";
import PrimaryHeader from "components/PrimaryHeader";
import PrimarySidebar from "components/PrimarySidebar";
import PrimaryContent from "layouts/PrimaryContent";
import ErrorLayout from "layouts/ErrorLayout";
import FatalError from "pages/error/FatalError";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Link } from "react-router-dom";
import { PortalContext } from "contexts/PortalContext";
import { prepareOtherPortals } from "utils/portal";

const PortalPrivacyES = () => {
	const { portal, portalList, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	if (error) {
		return (
			<ErrorLayout>
				<FatalError />
			</ErrorLayout>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || !portalList || isLoading) {
		return <Loader></Loader>;
	}

	const otherPortalsList = prepareOtherPortals(portalList, publicId);

	return (
		<>
			<Helmet />
			<PrimaryHeader
				publicId={publicId}
				claim={portal.claim}
				otherPortalsList={otherPortalsList}
				hideHistory={false}
			/>
			<PrimaryContent>
				<div className="g-section">
					<div className="g-layout-sidebar">
						<Link
							className="back-btn"
							to={`/${publicId}`}
							title="Back to dashboard"
							aria-label="Back to dashboard"
						>
							Volver al panel
						</Link>
					</div>
				</div>

				<div className="g-section">
					<div className="g-layout-sidebar">
						<div className="copy-content">
							<div className="copy-content--title">
								<h1>Política de privacidad de Accuserve</h1>
							</div>
							<div className="copy-content--card">
								<p className="copy-content--updated">Última actualización: 2 de septiembre de 2021</p>
								<p>
									Accuserve Solutions, Inc. y sus empresas afiliadas, incluidas Code Blue, LLC, Code
									Blue 360, LLC, FV Holdings, LLC dba MadSky Managed Repair Program, Accuwin, LLC, y
									DK Construction, LLC (en conjunto denominadas “Empresa”, “nosotros” “nuestro/a,” o
									“nos”) toman muy en serio su seguridad. Queremos que conozca cómo recopilamos,
									usamos, compartimos y protegemos su Información personal. Específicamente para los
									residentes de California, queremos que conozcan los derechos de los Consumidores de
									California según la ley de California.
								</p>
								<p>
									<strong>La Política de privacidad aborda los siguientes temas:</strong>
								</p>
								<ul>
									<li>Alcance de esta Política de privacidad</li>
									<li>
										Recopilación, uso y divulgación de Información personal por parte de la Empresa
									</li>
									<li>Los derechos de privacidad de California que le competen a usted</li>
									<li>Cómo ejercer sus derechos</li>
									<li>Derechos adicionales de la ley de California</li>
									<li>Servicios y enlaces de terceros</li>
									<li>Personas menores de 16 años</li>
									<li>Modificaciones a esta Política de privacidad</li>
									<li>Para obtener más información</li>
									<li>
										Solicitud de acceso a los datos del interesado (DSAR, Data Subject Access
										Request)
									</li>
								</ul>

								<p>
									La sección de esta política titulada “Recopilación, uso y divulgación de Información
									personal por parte de la Empresa” funciona como un aviso de recopilación cuando
									recopilamos información personal en línea sobre usted. Para los Consumidores de
									California, esto funciona como un aviso de recopilación conforme a lo requerido por
									la ley de California.
								</p>

								<h2>Alcance de esta Política de privacidad</h2>
								<p>
									Esta sección de la Política de privacidad describe, para los 12 meses previos a la
									fecha de la última actualización mencionada anteriormente, (a) las categorías de
									Información personal que recopilamos sobre usted, (b) la fuente de esa Información
									personal, (c) los fines comerciales y empresariales del uso de esa información y (d)
									las categorías de los terceros destinatarios de dicha Información personal. A menos
									que le informemos lo contrario, esta sección también funciona como nuestro “aviso de
									recopilación”, al notificarle que se recopilará su Información personal en el futuro
									y con qué fin se usará.
								</p>

								<p>
									<strong>¿Qué es la Información personal?</strong>
								</p>
								<p>
									La Información personal es la información que identifica o se relaciona, describe,
									se puede asociar o vincular de manera razonable, directa o indirectamente con un
									consumidor particular o familia.
								</p>

								<p>
									<strong>¿Quiénes están cubiertos por esta Política de Información personal?</strong>
								</p>
								<p>
									Esta Política de privacidad aplica sobre la Información personal de nuestros
									consumidores, incluidos los residentes del estado de California en su calidad de
									consumidores (“Consumidores de California”).
								</p>

								<p>
									<strong>¿Qué Información personal cubre esta Política de privacidad?</strong>
								</p>
								<p>
									Esta Política de privacidad aplica sobre toda la Información personal de los
									Consumidores y los Consumidores de California que recopilamos en línea mediante las
									visitas de los Consumidores a nuestro sitio web y (b) sobre el uso de nuestros
									servicios en relación con esta Política de privacidad (en conjunto, denominados
									“Servicios”).
								</p>

								<p>
									<strong>
										¿Quiénes no están cubiertos por esta Política de privacidad de Información
										personal?
									</strong>
								</p>
								<p>
									Para los fines de esta Política de privacidad, la “Información personal” no incluye
									información personal de los consumidores o residentes de California que sean:
								</p>

								<ul>
									<li>
										empleados de la Empresa, postulantes de empleo, directores, funcionarios o
										contratistas independientes (en conjunto denominados “Integrantes de RR. HH.”) o
										los contactos de emergencia de los Integrantes de RR. HH. o de los subordinados
										o cónyuges que reciben los beneficios de la Empresa en virtud de la relación con
										un Integrante de RR. HH. en su función de Integrante de RR. HH. o de los
										contactos de emergencia, subordinados o cónyuges; o
									</li>
									<li>
										empleados u otros representantes de un negocio que participen en una transacción
										con la Empresa en su función de empleados o representantes de ese negocio.
									</li>
								</ul>

								<h3>Recopilación, uso y divulgación de Información personal por parte de la Empresa</h3>
								<p>
									Esta sección de la Política de privacidad describe, para los 12 meses previos a la
									fecha de la última actualización mencionada anteriormente, (a) las categorías de
									Información personal que recopilamos sobre usted, (b) la fuente de esa Información
									personal, (c) los fines comerciales y empresariales del uso de esa información y (d)
									las categorías de los terceros destinatarios de dicha Información personal. A menos
									que le informemos lo contrario, esta sección también funciona como nuestro “aviso de
									recopilación”, al notificarle que se recopilará su Información personal en el futuro
									y con qué fin se usará.
								</p>

								<strong>Categorías de la Información personal recopilada</strong>
								<p>
									En los últimos 12 meses, la Empresa recopiló las siguientes categorías de
									Información personal sobre los Consumidores y los Consumidores de California.
								</p>

								<ul>
									<li>
										<strong>Identificadores</strong>, incluidos: nombre real, alias, dirección
										postal, dirección de correo electrónico, número de teléfono residencial y móvil
										u otro número de teléfono.
									</li>
									<li>
										<strong>
											Categorías descritas en el Código Civil de California § 1798.80(e)
										</strong>{" "}
										(excepto aquellas enumeradas anteriormente en “Identificadores”), incluidas:
										número de la póliza de seguro y número de reclamo de seguro.
									</li>
								</ul>
								<p>
									<strong>
										Fuentes de Información personal y propósitos para el uso de la Información
										personal
									</strong>
								</p>
								<p>
									En los últimos 12 meses, la Empresa recopiló las categorías de Información personal
									enumeradas anteriormente de las siguientes fuentes y utilizó dichas categorías de
									Información personal para los siguientes propósitos:
								</p>

								<table id="personal-info-table">
									<thead>
										<tr>
											<th scope="column">
												Categorías de Información personal recopilada en los últimos 12 meses
											</th>
											<th scope="column">Fuentes de dicha Información personal</th>
											<th scope="column">
												Propósitos comerciales y empresariales para recopilar dicha Información
												personal
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td data-label="Categorías de Información personal recopilada en los últimos 12 meses">
												<p className="table--emphasized">Identificadores</p>
											</td>
											<td data-label="Fuentes de dicha Información personal">
												<p>Consumidor (por sí mismo o por sí misma)</p>
												<p>Terceros autorizados por el consumidor (compañías de seguro)</p>
											</td>
											<td data-label="Propósitos comerciales y empresariales para recopilar dicha Información personal">
												<p>
													Prestación de servicios, incluida la programación, la supervisión y
													los servicios relacionados con las reparaciones de la propiedad y
													los servicios de mitigación
												</p>
												<p>
													Facilitar las comunicaciones, por ejemplo, recopilando y organizando
													la información de contacto, estableciendo medios de comunicación y
													comunicándose con clientes actuales y potenciales, incluso con
													relación a preguntas y comentarios
												</p>
												<p>Comunicaciones sobre encuestas de satisfacción del cliente</p>
											</td>
										</tr>
										<tr>
											<td data-label="Categorías de Información personal recopilada en los últimos 12 meses">
												<p className="table--emphasized">
													Categorías descritas en el Código Civil § 1798.80(e)
												</p>
											</td>
											<td data-label="Fuentes de dicha Información personal">
												<p>Consumidor (por sí mismo o por sí misma)</p>
												<p>Terceros autorizados por el consumidor (compañías de seguro)</p>
											</td>
											<td data-label="Propósitos comerciales y empresariales para recopilar dicha Información personal">
												<p>
													Prestación de servicios, incluida la programación, la supervisión y
													los servicios relacionados con las reparaciones de la propiedad y
													los servicios de mitigación
												</p>
												<p>
													Comunicaciones sobre detalles relacionados con el reclamo de seguro
													para el cual brindamos servicios, incluidos los detalles de
													estimación, pago y cobertura
												</p>
												<p>Verificación de información</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<PrimarySidebar
							publicId={publicId}
							claim={portal.claim}
							isAbsolute={true}
							otherPortalsList={null}
							hideHistory={false}
						/>
					</div>
				</div>
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalPrivacyES;
