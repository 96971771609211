import * as path from "api/path";
import { useMemo } from "react";
import { useRoutes } from "react-router-dom";

const AppRoutes = () => {
	const routes = useMemo(() => path.getRouteObjects(path.AppPathTrees) || [], []);
	return useRoutes(routes);
};

export default AppRoutes;
