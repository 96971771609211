import { useIntl } from "i18n/Internationalization";
import LanguageSelector from "components/LanguageSelector";

const PrimaryHeaderSimple = () => {
	const intl = useIntl();
	const i18nstrings = {
		"header-title": intl.formatMessage({
			id: "header-title",
			defaultMessage: "Claim Tracker",
		}),
		"simple-header-title": intl.formatMessage({
			id: "simple-header-title",
			defaultMessage: "Claim Tracker",
		}),
		"skip-to-main-content": intl.formatMessage({
			id: "skip-to-main-content",
			defaultMessage: "skip to main content",
		}),
	};

	return (
		<>
			<a href="#main-content" className="skip-btn" arial-label="Skip to main content">
				{i18nstrings["skip-to-main-content"]}
			</a>
			<header className="primary-header">
				<div className="primary-header__inner">
					<div className="primary-header__brand">
						<img src="/img/logo-accuserve.svg" alt="Accuserve" />
					</div>
					<div className="primary-header__title">{i18nstrings["header-title"]}</div>
					<LanguageSelector />
				</div>
			</header>
		</>
	);
};

export default PrimaryHeaderSimple;
