import { MouseEventHandler } from "react";
import { ReactComponent as CloseIcon } from "assets/img/icon-close.svg";

type PaniniProps = {
	toggleOpen: MouseEventHandler;
	controls: string;
	expanded: boolean;
};

const Panini = ({ expanded, toggleOpen, controls }: PaniniProps) => {
	return (
		<button
			className="primary-header__menu-btn"
			onClick={toggleOpen}
			aria-expanded={expanded}
			aria-controls={controls}
		>
			<div className="primary-header__menu-btn-inner">
				<span className="primary-header__btn-open" title="Open Menu">
					<svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2 18H27" stroke="white" strokeWidth="4" strokeLinecap="round" />
						<path d="M2 10H27" stroke="white" strokeWidth="4" strokeLinecap="round" />
						<path d="M2 2H27" stroke="white" strokeWidth="4" strokeLinecap="round" />
					</svg>
				</span>
				<span className="primary-header__btn-close">
					<CloseIcon />
				</span>
			</div>
		</button>
	);
};

export default Panini;
