import { useState, useRef } from "react";

import Panini from "components/Panini";
import SidebarClaimMeta from "components/SidebarClaimMeta";
import LanguageSelector from "components/LanguageSelector";
import useId from "hooks/useId";
import { Claim } from "model/dto/claim";
import { PortalInfo } from "model/dto/portal-info";
import { useIntl } from "i18n/Internationalization";
import { useContext } from "react";
import { FeedbackContext } from "contexts/FeedbackContext";

type PrimaryHeaderProps = {
	publicId: String | undefined;
	claim: Claim;
	otherPortalsList: Array<PortalInfo> | null;
	hideHistory: boolean;
};

const PrimaryHeader = ({ publicId, claim, otherPortalsList, hideHistory }: PrimaryHeaderProps) => {
	const [open, setOpen] = useState(false);
	const { current: navID } = useRef(useId("nav"));

	const intl = useIntl();

	const i18nstrings = {
		"header-title": intl.formatMessage({
			id: "header-title",
			defaultMessage: "Claim Tracker",
		}),
		"feedback-modal-elective-feedback": intl.formatMessage({
			id: "feedback-modal-elective-feedback",
			defaultMessage: "Feedback",
		}),
		"skip-to-main-content": intl.formatMessage({
			id: "skip-to-main-content",
			defaultMessage: "skip to main content",
		}),
	};

	const { elective } = useContext(FeedbackContext);

	function toggleOpen() {
		setOpen(!open);
	}

	return (
		<>
			<a href="#main-content" className="skip-btn" arial-label="Skip to main content">
				{i18nstrings["skip-to-main-content"]}
			</a>
			<header className={`primary-header ${open ? "open" : ""}`.trim()}>
				<div className="primary-header__inner">
					<div className="primary-header__brand">
						<img src="/img/logo-accuserve.svg" alt="Accuserve" />
					</div>

					<div className="primary-header__title">{i18nstrings["header-title"]}</div>
					<LanguageSelector publicId={publicId} />
					<button
						onClick={() => elective.showDialog(true)}
						className="primary-header__btn-feedback js-elective-reveal"
						title="Give feedback"
						aria-label="Give feedback"
					>
						{i18nstrings["feedback-modal-elective-feedback"]}
					</button>

					<Panini toggleOpen={toggleOpen} expanded={open} controls={navID} />
				</div>

				<nav id={navID} className="claim-meta header-nav header-nav--absolute">
					<SidebarClaimMeta
						classes={"claim-meta__claim-number header-nav__claim-number"}
						publicId={publicId}
						claim={claim}
						hideHistory={hideHistory}
						otherPortalsList={otherPortalsList}
					/>
				</nav>
			</header>
		</>
	);
};

export default PrimaryHeader;
