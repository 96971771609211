import { FormEvent, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useIntl } from "i18n/Internationalization";

export interface ConfirmCodeProps {
	invalidCode: boolean;
	validCode: boolean;
	codeUsed: boolean;
	onConfirm: (confirmCode: string) => void;
}

const ConfirmCode = ({ invalidCode, validCode, codeUsed, onConfirm }: ConfirmCodeProps) => {
	const [confirmCode, setConfirmCode] = useState<string>("");

	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		onConfirm(confirmCode);
	};

	const intl = useIntl();

	const i18nstrings = {
		"confirm-code-different-device": intl.formatMessage({
			id: "confirm-code-different-device",
			defaultMessage:
				"You've activated your Accuserve portal on a different device, but you can enter the 6 digit code to activate this device.",
		}),
		"confirm-code-incorrect-code": intl.formatMessage({
			id: "confirm-code-incorrect-code",
			defaultMessage: "Incorrect code.",
		}),
		"confirm-code-success": intl.formatMessage({
			id: "confirm-code-success",
			defaultMessage: "Verification successful. Redirecting to the portal...",
		}),
		"confirm-code-verification-code": intl.formatMessage({
			id: "confirm-code-verification-code",
			defaultMessage: "Verification code",
		}),
		"confirm-code-button-ok": intl.formatMessage({
			id: "confirm-code-button-ok",
			defaultMessage: "OK",
		}),
	};

	return (
		<div>
			<Alert variant="info" show={codeUsed}>
				<div className="p-3">{i18nstrings["confirm-code-different-device"]}</div>
			</Alert>

			<Alert variant="danger" show={invalidCode}>
				<div className="p-3">{i18nstrings["confirm-code-incorrect-code"]}</div>
			</Alert>

			<Alert variant="success" show={validCode}>
				<div className="p-3">{i18nstrings["confirm-code-success"]}</div>
			</Alert>

			<Form onSubmit={handleSubmit}>
				<Form.Group className="mb-3" controlId="formConfirmContact">
					<Form.Control
						type="TEXT"
						placeholder={i18nstrings["confirm-code-verification-code"]}
						onChange={(event) => setConfirmCode(event.target.value)}
					/>
				</Form.Group>
				<Button className="button" type="submit">
					OK
				</Button>
			</Form>
		</div>
	);
};

export default ConfirmCode;
