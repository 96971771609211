import { Helmet } from "react-helmet-async";
import ErrorCard from "components/ErrorCard";
import { useIntl } from "i18n/Internationalization";

const Page404 = () => {
	const intl = useIntl();

	const i18nstrings = { 
		"page-404-error-title" : intl.formatMessage({
			id: "page-404-error-title",
			defaultMessage: "404 Error"
		}),
		"page-404-page-not-found" : intl.formatMessage({
			id: "page-404-page-not-found",
			defaultMessage: "404 PAGE NOT FOUND"
		}),
		"page-404-does-not-exist" : intl.formatMessage({
			id: "page-404-does-not-exist",
			defaultMessage: "Sorry, but the page you're looking for does not exist."
		})
	};

	return (
		<>
			<Helmet title={i18nstrings['page-404-error-title']} />
			<ErrorCard title={i18nstrings['page-404-page-not-found']} copy={i18nstrings['page-404-does-not-exist']} />
		</>
	);
};

export default Page404;
