import { Service } from "model/dto/service";
import { SubstatusType } from "model/dto/substatus-type";
import { StatusType } from "model/dto/status-type";
import moment from "moment";
import { EASTERN_TZ } from "model/types/TimeZonedDate";

/**
 * Relevant service date strings/keys
 */
export type ServiceDateType =
	| "targetJobStartDatetime"
	| "jobCompletionDateTime"
	| "inspectionAppointmentDatetime"
	| "targetJobStartDate"
	| "jobCompletionDate"
	| "inspectionAppointmentDate";

/**
 * Represents a service date
 */
export type ServiceDate = {
	type: ServiceDateType;
	datetime: Date | null | undefined;
	date: String | null | undefined;
	prettyDate: String;
	prettyDatetime: string;
};

/* some constants for service dates (for type checking!!) */
const TARGET_JOB_START_DATETIME: ServiceDateType = "targetJobStartDatetime";
const JOB_COMPLETION_DATETIME: ServiceDateType = "jobCompletionDateTime";
const INSPECTION_APPOINTMENT_DATETIME: ServiceDateType = "inspectionAppointmentDatetime";
const TARGET_JOB_START_DATE: ServiceDateType = "targetJobStartDate";
const JOB_COMPLETION_DATE: ServiceDateType = "jobCompletionDate";
const INSPECTION_APPOINTMENT_DATE: ServiceDateType = "inspectionAppointmentDate";

/** the substatus that represents the "stopped" state */
export const PORTAL_SUBSTATUS_STOPPED: SubstatusType = "Accuserve Not Handling Service";

const PORTAL_STATUS_ORDERING: StatusType[] = [
	"Assigning Service",
	"Assessing & Planning",
	"Service Complete",
	"Service In-Progress",
	"Service Stopped",
];

/**
 * Checks if a service is stopped
 *
 * @param service the service to check for stoppage n'at
 * @returns true if it is stopped, false otherwise
 */
export function isServiceStopped(service: Service | undefined) {
	return !!(service && service.portalSubStatus && service.portalSubStatus === PORTAL_SUBSTATUS_STOPPED);
}

/**
 * Checks if the supplied substatus is stopped or not
 *
 * @param portalSubStatus the status to check
 * @returns true if it is a stopped substatus, false otherwise
 */
export function isStoppedStatus(portalSubStatus: SubstatusType | undefined) {
	return portalSubStatus === PORTAL_SUBSTATUS_STOPPED;
}

/**
 * Given a service, this function will return a string describing the service
 * in a user friendly way
 *
 * @param service the service for which we want to resolve a user facing name
 */
export function getServiceName(service: Service | undefined) {
	if (typeof service === "undefined") {
		return "?";
	} else if (service.serviceType === "Tree Removal") {
		return "Tree Removal";
	} else if (service.serviceType === "Fire Mitigation") {
		return "Fire Mitigation";
	} else if (service.serviceType === "Reconstruction") {
		return "Reconstruction";
	} else if (service.serviceType === "Contents Cleaning") {
		return "Contents Cleaning";
	} else if (service.serviceType === "Contents Packout") {
		return "Contents Packout";
	} else if (service.serviceType === "Roofing") {
		return "Roof Restoration";
	} else if (service.serviceType === "Water Mitigation") {
		return "Water Mitigation";
	} else if (service.serviceType === "Board Up") {
		return "Board Up";
	} else if (service.serviceType === "Window") {
		return "Window Restoration";
	} else {
		return "?";
	}
}

/**
 * Checks if a service is valid.
 *
 * @param service the service to check for validity
 * @returns true if it is a valid service, false otherwise
 */
export function isValidService(service: Service): boolean {
	return typeof service.portalStatus === "string" && typeof service.portalSubStatus === "string";
}

/**
 * Make sure the list of services are fully valid for display.
 *
 * @param services full set of claim services
 * @returns a filtered set of claim services that are considered "valid" (this likely
 * means that they aren't missing portal status/substatus)
 */
export function getValidServices(services: Array<Service>): Array<Service> {
	return services.filter(isValidService);
}

/**
 * Retrieves the set of services that are considered "on going", which is
 * defined as a service that has a valid stats/substatus and
 *
 * @param services current list of portal services
 * @returns an array of services that are valid and on going (i.e. not stopped)
 */
export function getOnGoingServices(services: Array<Service>): Array<Service> {
	return services.filter((service) => {
		return service.portalSubStatus !== PORTAL_SUBSTATUS_STOPPED;
	});
}

/**
 * Gathers and sorts relevant service dates.
 *
 * NOTE: this function will also filter out any undefined dates. so the
 * returned array represents the set of currently defined dates.
 *
 * @param service the service from which we want to pull service dates
 * @param upcomingOnly true if we only want upcoming dates, false otherwise
 * @returns a sorted array of ServiceDates, the most recent date will appear first
 */
export function pullServiceDates(
	service: Service,
	tz: string = EASTERN_TZ,
	upcomingOnly: boolean = true
): Array<ServiceDate> {
	return [
		{
			type: TARGET_JOB_START_DATETIME,
			datetime: service[TARGET_JOB_START_DATETIME],
			date: service[TARGET_JOB_START_DATE],
			prettyDate: "",
		},
		{
			type: JOB_COMPLETION_DATETIME,
			datetime: service[JOB_COMPLETION_DATETIME],
			date: service[JOB_COMPLETION_DATE],
			prettyDate: "",
		},
		{
			type: INSPECTION_APPOINTMENT_DATETIME,
			datetime: service[INSPECTION_APPOINTMENT_DATETIME],
			date: service[INSPECTION_APPOINTMENT_DATE],
			prettyDate: "",
		},
	]
		.filter((serviceDate) => {
			const date = serviceDate.datetime || serviceDate.date;
			return !!date && (!upcomingOnly || new Date(date).getTime() > Date.now());
		})
		.sort((a, b) => {
			const aDate = a.datetime || a.date;
			const bDate = b.datetime || b.date;

			if (aDate && bDate) {
				return new Date(aDate).getTime() - new Date(bDate).getTime();
			}

			return 1;
		})
		.map((serviceDate) => {
			return {
				...serviceDate,
				prettyDate: serviceDate.datetime ? moment(serviceDate.datetime).format("MMM Do") : serviceDate.date,
				prettyDatetime: serviceDate.datetime
					? moment(serviceDate.datetime).tz(tz).format("MMM Do h:mmA z")
					: serviceDate.date,
			};
		});
}

/**
 * Given a list of services, this provides a version with the correct ordering.
 *
 * @param services an array of services to order
 * @returns an array of services properly ordered
 */
export function getSortedServiceList(services: Array<Service>): Array<Service> {
	return services.sort((a, b) => {
		const statusA = a.portalStatus;
		const statusB = b.portalStatus;

		if (!statusA || statusA === "Service Stopped") {
			return 1;
		} else if (!statusB) {
			return -1;
		}

		return PORTAL_STATUS_ORDERING.indexOf(statusA) - PORTAL_STATUS_ORDERING.indexOf(statusB);
	});
}
