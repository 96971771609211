import ContactMethods from "components/ContactMethods";
import { AssignedContractor } from "model/dto/assigned-contractor";
import { Service } from "model/dto/service";
import { useIntl } from "i18n/Internationalization";

type ServiceCardContractorProps = {
	assignedContractorCompany: AssignedContractor | undefined | null;
	service: Service;
	open?: boolean;
};

const ServiceCardContractor = ({ assignedContractorCompany, service, open }: ServiceCardContractorProps) => {
	const intl = useIntl();

	const i18nstrings = {
		"service-card-contractor-and-contact": intl.formatMessage({
			id: "service-card-contractor-and-contact",
			defaultMessage: "Contractor and contact assigned to your claim",
		}),
		"service-card-contractor": intl.formatMessage({
			id: "service-card-contractor",
			defaultMessage: "Contractor assigned to your claim",
		}),
	};

	const baseKey = `${service.serviceType}-${assignedContractorCompany?.contactId}`;
	const listClasses = [
		"service-card__contractor-list",
		"col",
		open ? "service-card__contractor-list--open" : "",
	].filter((cls) => cls.length);

	const hasContractor = typeof assignedContractorCompany !== "undefined" && assignedContractorCompany !== null;
	const hasContact =
		hasContractor &&
		typeof assignedContractorCompany.contractorEmployees !== "undefined" &&
		assignedContractorCompany.contractorEmployees !== null &&
		assignedContractorCompany.contractorEmployees.length > 0;

	let contractorAddress = <></>,
		employeeInfo: React.ReactElement[] = [],
		heading = "";

	if (hasContractor) {
		contractorAddress = (
			<address key={`${baseKey}-${assignedContractorCompany.contactId}`} className="service-card__contractor col">
				<p
					className="service-card__contractor--title"
					key={`${baseKey}-${assignedContractorCompany.contactId}-fullName`}
				>
					{assignedContractorCompany.fullName}
				</p>{" "}
				{
					<ContactMethods
						contactMethods={assignedContractorCompany.contactMethods}
						includeBreaks={true}
						sortEmailsTop={true}
					/>
				}
			</address>
		);
	}

	if (hasContact) {
		employeeInfo = (assignedContractorCompany.contractorEmployees || []).map((employee) => {
			return (
				<address key={`${baseKey}-${employee.contactId}`} className="service-card__contractor col">
					<p key={`${baseKey}-${employee.contactId}-fullName`}>{employee.fullName}</p> <br />
					<ContactMethods
						contactMethods={employee.contactMethods}
						includeBreaks={true}
						sortEmailsTop={true}
					/>
				</address>
			);
		});
	}

	if (hasContractor && hasContact) {
		heading = i18nstrings["service-card-contractor-and-contact"];
	} else {
		heading = i18nstrings["service-card-contractor"];
	}

	return (
		<div className="primary-dialog__content--body">
			<div key={baseKey} className={listClasses.join(" ")}>
				<div key={`${baseKey}-heading`} className="primary-dialog__content--title">
					{heading}
				</div>
				<div key={`${baseKey}-info`} className="d-flex flex-wrap">
					{contractorAddress}
					{employeeInfo}
				</div>
			</div>
		</div>
	);
};

export default ServiceCardContractor;
