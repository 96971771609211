import { PrimaryDialog } from "components/PrimaryDialog";
import { Feedback, FeedbackValue } from "contexts/FeedbackContext";
import { FieldSaver } from "model/types/Fields";
import { actorIcons, faceIcons } from "components/FeedbackIcons";
import { CheckBoxSelectItem } from "components/CheckboxSelectItem";
import { ContentTitle } from "components/ContentTitle";
import { IconItem, IconSelectField, IconSelectList } from "components/IconSelectList";
import { FieldTextArea, TextField } from "components/FieldTextArea";
import { Sequencer, SequencerConfig, SequencerStep } from "components/Sequencer";
import { useContext } from "react";
import { PortalContext } from "contexts/PortalContext";
import { useIntl } from "i18n/Internationalization";

type ElectiveFeedbackDialogProps = {
	visible: boolean;
	saveValue: FieldSaver<FeedbackValue>;
	feedback: Feedback;
	close: () => void;
	submitFeedback: () => void;
};

export const ElectiveFeedbackDialog = ({
	feedback,
	saveValue,
	visible,
	close,
	submitFeedback,
}: ElectiveFeedbackDialogProps) => {
	const { portal } = useContext(PortalContext);
	const intl = useIntl();
	const isStaffMessage = portal?.isStaff ? (
		<>
			<div className="primary-dialog__content--warning">
				{intl.formatMessage({
					id: "feedback-modal-staff-message",
					defaultMessage: "Feedback submitted by staff is not saved",
				})}
			</div>
		</>
	) : (
		<></>
	);

	const getContractors = (): IconItem[] => {
		let contractors: { [K: string]: IconItem } = {};
		if (portal && portal.claim.services && portal.claim.services.length > 0) {
			portal.claim.services.forEach((service) => {
				if (service.assignedContractor) {
					let label = service.assignedContractor.preferredName
						? service.assignedContractor.preferredName
						: service.assignedContractor.fullName;
					if (!label) label = "Contractor assigned to " + service.serviceType;
					const element = <CheckBoxSelectItem label={label} />;
					contractors[service.assignedContractor.contractorId] = {
						element: element,
						value: service.assignedContractor.contractorId,
					};
				}
			});
		}
		return Object.values(contractors);
	};

	const contentTitles = {
		overall_experience: intl.formatMessage({
			id: "feedback-modal-elective-how-has",
			defaultMessage: "How has your experience been?",
		}),
		details_experience: intl.formatMessage({
			id: "feedback-modal-elective-care-to-share",
			defaultMessage: "Care to share anything else?",
		}),
		thank_you: intl.formatMessage({
			id: "feedback-modal-thank-you",
			defaultMessage: "Thank you for your feedback!",
		}),
	};

	const getElectiveSequencerSteps = (saveValue: FieldSaver<FeedbackValue>, feedback: Feedback): SequencerStep[] => {
		const contractors = getContractors();
		const contractorList =
			contractors.length > 0 ? (
				<IconSelectList
					selectType="checkbox"
					field={{
						name: "feedback_for_contractor",
						value: feedback["feedback_for_contractor"],
						isMulti: false,
						saveValue: saveValue,
						label: intl.formatMessage({
							id: "feedback-modal-elective-select-contractor",
							defaultMessage: "Please select a contractor:",
						}),
						items: getContractors(),
					}}
				/>
			) : (
				<></>
			);

		return [
			{
				terminal: false,
				show: true,
				allowProceed: () => {
					return Object.keys(feedback.overall_experience).some((key) => !!feedback.overall_experience[key]);
				},
				onComplete: () => {
					saveValue("overall_experience", {
						...feedback.overall_experience,
						question: contentTitles.overall_experience,
					});
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.overall_experience}</ContentTitle>
						<IconSelectList
							selectType="icon"
							size="small"
							field={
								{
									name: "overall_experience",
									value: feedback["overall_experience"],
									saveValue: saveValue,
									isMulti: false,
									items: faceIcons,
								} as IconSelectField
							}
						/>
					</>
				),
			},
			{
				terminal: false,
				show: true,
				onComplete: () => {
					saveValue("details_experience", {
						...feedback.details_experience,
						textKey: "details_experience",
						question: contentTitles.details_experience,
					});
					submitFeedback();
				},
				allowProceed: () => {
					return Object.keys(feedback.feedback_for).some((key) => !!feedback.feedback_for[key]);
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.details_experience}</ContentTitle>
						<FieldTextArea
							field={
								{
									name: "details_experience",
									value: feedback["details_experience"],
									saveValue: saveValue,
									label: intl.formatMessage({
										id: "feedback-modal-elective-details",
										defaultMessage: "Please provide details about your experience:",
									}),
								} as TextField
							}
						/>
						<IconSelectList
							selectType="icon"
							size="small"
							field={
								{
									name: "feedback_for",
									value: feedback["feedback_for"],
									saveValue: saveValue,
									isMulti: false,
									items: actorIcons,
									label: intl.formatMessage({
										id: "feedback-modal-elective-who-is-it-for",
										defaultMessage: "Who is the feedback for?",
									}),
								} as IconSelectField
							}
						/>
						{feedback["feedback_for"].contractor ? contractorList : <></>}
					</>
				),
			},
			{
				terminal: true,
				show: true,
				element: <ContentTitle>{contentTitles.thank_you}</ContentTitle>,
			},
		];
	};
	const sequencerConfig: SequencerConfig<SequencerStep> = {
		steps: getElectiveSequencerSteps(saveValue, feedback),
		onComplete: close,
	};

	return (
		<PrimaryDialog show={visible} close={close}>
			<Sequencer config={sequencerConfig} />
		</PrimaryDialog>
	);
};
