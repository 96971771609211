import { PortalProvider } from "contexts/PortalContext";
import PortalDashboard from "components/PortalDashboard";
import PortalHistory from "components/PortalHistory";
import PortalPrivacy from "components/PortalPrivacy";
import PortalPrivacyES from "components/PortalPrivacy--es";
import PortalTerms from "components/PortalTerms";
import PortalTermsES from "components/PortalTerms--es";
import { FeedbackProvider } from "contexts/FeedbackContext";
import { useIntl } from "i18n/Internationalization";

export type PortalContent = "history" | "dashboard" | "privacy" | "terms";

type PortalPageProps = {
	contentType: PortalContent;
};

const PortalPage = ({ contentType }: PortalPageProps) => {
	const intl = useIntl();
	const determinPortalTerms = () => {
		if (intl.locale === "es") {
			return <PortalTermsES />;
		} else {
			return <PortalTerms />;
		}
	};
	const determinPortalPrivacy = () => {
		if (intl.locale === "es") {
			return <PortalPrivacyES />;
		} else {
			return <PortalPrivacy />;
		}
	};

	function determineContent() {
		if (contentType === "history") {
			return <PortalHistory />;
		} else if (contentType === "privacy") {
			return determinPortalPrivacy();
		} else if (contentType === "terms") {
			return determinPortalTerms();
		} else {
			return <PortalDashboard />;
		}
	}
	return (
		<PortalProvider>
			<FeedbackProvider>{determineContent()}</FeedbackProvider>
		</PortalProvider>
	);
};

export default PortalPage;
