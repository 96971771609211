import { Service } from "model/dto/service";
import { useIntl } from "i18n/Internationalization";
import { ServiceIcon } from "components/ServiceIcons";
import { useProse } from "utils/prose";

type ServiceCardContentProgressProps = {
	service: Service;
	includeProse: boolean;
};

const ServiceCardProgressBar = ({ service, includeProse }: ServiceCardContentProgressProps) => {
	const barStyle = {
		width: "0%",
	};

	const intl = useIntl();
	// const prose = useProse(intl);
	const { buildCardStatus, getTranslatedService } = useProse(intl);

	const i18nstrings = {
		"service-card-progress-status": intl.formatMessage({
			id: "service-card-progress-status",
			defaultMessage: "service status",
		}),
		"service-card-progress-assigning": intl.formatMessage({
			id: "service-card-progress-assigning",
			defaultMessage: "Assigning Service",
		}),
		"service-card-progress-assessing": intl.formatMessage({
			id: "service-card-progress-assessing",
			defaultMessage: "Assessing & Planning",
		}),
		"service-card-progress-in-progress": intl.formatMessage({
			id: "service-card-progress-in-progress",
			defaultMessage: "Service in Progress",
		}),
		"service-card-progress-complete": intl.formatMessage({
			id: "service-card-progress-complete",
			defaultMessage: "Service Complete",
		}),
	};

	let barProgress = "0";

	if (service.portalStatus === "Assigning Service") {
		barStyle.width = "0";
		barProgress = "1/4";
	} else if (service.portalStatus === "Assessing & Planning") {
		barStyle.width = "33.33%";
		barProgress = "2/4";
	} else if (service.portalStatus === "Service In-Progress") {
		barStyle.width = "66.66%";
		barProgress = "3/4";
	} else if (service.portalStatus === "Service Complete") {
		barStyle.width = "100%";
		barProgress = "4/4";
	}

	let serviceStatusCopy = <></>;

	if (includeProse) {
		serviceStatusCopy = <p className="service-card__copy">{buildCardStatus(service)}</p>;
	}

	return (
		<>
			<div className="d-flex flex-wrap">
				<h2 className="service-card__title col-12">
					<strong>{service.serviceType ? getTranslatedService(service.serviceType) : ""}</strong>{" "}
					{i18nstrings["service-card-progress-status"]}
				</h2>

				<div className="service-card__icon flex-shrink-1">
					<ServiceIcon service={service} />
				</div>

				<div className="service-card__progress col">
					<div className="progress-bar">
						<div className="progress-bar__bar">
							<div className="progress-bar__bar-inner" style={barStyle} tabIndex={0}>
								{barProgress}
							</div>
						</div>

						<ol className="progress-bar__list">
							<li
								className={`progress-bar__step ${
									service.portalStatus === "Assigning Service" ? "current" : ""
								}`}
							>
								<div className="progress-bar__state">
									<span>1</span>
								</div>
								<div className="progress-bar__label">
									{i18nstrings["service-card-progress-assigning"]}
								</div>
							</li>

							<li
								className={`progress-bar__step ${
									service.portalStatus === "Assessing & Planning" ? "current" : ""
								}`}
							>
								<div className="progress-bar__state">
									<span>2</span>
								</div>
								<div className="progress-bar__label">
									{i18nstrings["service-card-progress-assessing"]}
								</div>
							</li>

							<li
								className={`progress-bar__step ${
									service.portalStatus === "Service In-Progress" ? "current" : ""
								}`}
							>
								<div className="progress-bar__state">
									<span>3</span>
								</div>
								<div className="progress-bar__label">
									{i18nstrings["service-card-progress-in-progress"]}
								</div>
							</li>

							<li
								className={`progress-bar__step ${
									service.portalStatus === "Service Complete" ? "current" : ""
								}`}
							>
								<div className="progress-bar__state">
									<span>4</span>
								</div>
								<div className="progress-bar__label">
									{i18nstrings["service-card-progress-complete"]}
								</div>
							</li>
						</ol>
					</div>
				</div>
			</div>

			{serviceStatusCopy}
		</>
	);
};

export default ServiceCardProgressBar;
