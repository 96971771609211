import { AxiosInstance, AxiosResponse, Method } from "axios";
import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";
import { coreAxios } from "api/axios";

/**
 * Thrown by wrapped api calls.
 */
export class ApiError extends Error {
	constructor(
		public code: string,
		message?: string,
		public status?: number,
		public extra?: any,
		public cause?: Error
	) {
		super(message);
	}
}

type WrappedApi<RESPONSE_TYPE extends t.Any> = (
	url: string,
	params?: any,
	data?: any
) => Promise<t.TypeOf<RESPONSE_TYPE>>;

/**
 * Base api wrapper - Produces an async method that can make an api calls and validates the return type.
 * On any error (validation error or otherwise), rejects with an ApiError object.
 * Does not perform any ui or navigation side-effects.
 *
 * @param method
 * @param responseType
 * @returns
 */
function apiWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	method: Method,
	responseType: RESPONSE_TYPE
): WrappedApi<RESPONSE_TYPE> {
	return async function (url: string, params?: any, data?: any): Promise<t.TypeOf<RESPONSE_TYPE>> {
		let response: AxiosResponse;

		// Perform the api call
		try {
			response = await axios({
				method,
				url,
				params,
				data,
			});
		} catch (error: any) {
			if (error.response) {
				// Status code falls outside of 2xx range
				const errorResponse = error.response;
				throw new ApiError(
					"response_error_code",
					errorResponse.data ? errorResponse.data.message : undefined,
					errorResponse.status,
					errorResponse.data,
					error
				);
			} else if (error.request) {
				// The request was made but no response was received
				throw new ApiError("no_response", undefined, undefined, undefined, error);
			}

			// Something happened in setting up the request
			throw new ApiError("request_error", undefined, undefined, undefined, error);
		}

		// Validate the response
		const candidate = response.data;

		// allow empty string response if return type is undefined or void
		if (
			(responseType.name === t.undefined.name || responseType.name === t.void.name) &&
			(candidate == null || candidate === "")
		) {
			return undefined;
		}

		const validation = responseType.decode(candidate);
		if (validation.isRight()) {
			return validation.value;
		} else {
			const report = PathReporter.report(validation);
			console.error(`Return type validation failed for ${method} ${url}`, report);
			throw new ApiError("parse_failure", undefined, undefined, { parseError: report });
		}
	};
}

/*
 * Generic wrappers with a specified axios instance.
 */

export function getWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	responseType: RESPONSE_TYPE
): (url: string, params?: any) => Promise<t.TypeOf<RESPONSE_TYPE>> {
	return apiWrapper(axios, "GET", responseType);
}

export function postWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	responseType: RESPONSE_TYPE
): WrappedApi<RESPONSE_TYPE> {
	return apiWrapper(axios, "POST", responseType);
}

export function putWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	responseType: RESPONSE_TYPE
): WrappedApi<RESPONSE_TYPE> {
	return apiWrapper(axios, "PUT", responseType);
}

export function patchWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	responseType: RESPONSE_TYPE
): WrappedApi<RESPONSE_TYPE> {
	return apiWrapper(axios, "PATCH", responseType);
}

export function deleteWrapper<RESPONSE_TYPE extends t.Any>(
	axios: AxiosInstance,
	responseType: RESPONSE_TYPE
): WrappedApi<RESPONSE_TYPE> {
	return apiWrapper(axios, "DELETE", responseType);
}

/*
 * Core api wrappers using the global core axios instance.
 */

export function coreGet<RESPONSE_TYPE extends t.Any>(responseType: RESPONSE_TYPE) {
	return getWrapper(coreAxios, responseType);
}

export function corePost<RESPONSE_TYPE extends t.Any>(responseType: RESPONSE_TYPE) {
	return postWrapper(coreAxios, responseType);
}

export function corePut<RESPONSE_TYPE extends t.Any>(responseType: RESPONSE_TYPE) {
	return putWrapper(coreAxios, responseType);
}

export function corePatch<RESPONSE_TYPE extends t.Any>(responseType: RESPONSE_TYPE) {
	return patchWrapper(coreAxios, responseType);
}

export function coreDelete<RESPONSE_TYPE extends t.Any>(responseType: RESPONSE_TYPE) {
	return deleteWrapper(coreAxios, responseType);
}
