import { Claim } from "model/dto/claim";
import { PortalInfo } from "model/dto/portal-info";
import SidebarClaimMeta from "components/SidebarClaimMeta";

type PrimarySidebarProps = {
	publicId: String | undefined;
	claim: Claim;
	isAbsolute: Boolean;
	otherPortalsList: Array<PortalInfo> | null;
	hideHistory: boolean;
};

const PrimarySidebar = ({ claim, isAbsolute, publicId, otherPortalsList, hideHistory }: PrimarySidebarProps) => {
	const asideClass = isAbsolute ? "claim-meta claim-meta--absolute" : "claim-meta";
	return (
		<aside className={asideClass}>
			<SidebarClaimMeta
				classes={"claim-meta__claim-number"}
				publicId={publicId}
				claim={claim}
				hideHistory={hideHistory}
				otherPortalsList={otherPortalsList}
			/>
		</aside>
	);
};

export default PrimarySidebar;
