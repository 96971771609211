import axios from "axios";

const env = process.env as any;

const coreAxios = axios.create({
	baseURL: (window as any).REACT_APP_API_URL + env.REACT_APP_API_PATH,
	maxRedirects: 0,
	responseType: "json",
	xsrfCookieName: "XSRF-TOKEN",
	xsrfHeaderName: "X-XSRF-TOKEN",
	headers: {
		"Core-Api-Version": env.REACT_APP_API_VERSION,
	},
});

export { coreAxios };
