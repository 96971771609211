import AxiosMockAdapter from "axios-mock-adapter";
import mockConfig from "config/mock";
import "mock/portal-mock";
import * as mocks from "mock/axios-mock-adapter";

// Remove all mock adapters if no mock config
if (mockConfig === null) {
	Object.getOwnPropertyNames(mocks).forEach((mockName) => {
		const mock = mocks[mockName];
		if (mock instanceof AxiosMockAdapter) {
			console.debug("Removing mock from " + mockName);
			mock.restore();
		}
	});
}
