import { ReactComponent as IconChevron } from "assets/img/icon-chevron.svg";

type ToggleButtonProps = {
	expanded: boolean;
	toggleParent: Function;
	label?: boolean;
	additionalClasses?: string;
	controls: string;
};

const ToggleButton = ({
	expanded,
	toggleParent,
	label = true,
	additionalClasses = "",
	controls,
}: ToggleButtonProps) => {
	// Toggle state on this element and its parent card
	const handleClick = () => {
		toggleParent(!expanded);
	};

	// Base CSS class, state class, optional additional classes
	const buttonClasses = ["toggleButton", expanded ? " toggleButton--open" : "", ` ${additionalClasses}`]
		.join("")
		.trim();

	return (
		<button
			aria-expanded={expanded ? "true" : "false"}
			aria-controls={controls}
			className={buttonClasses}
			onClick={handleClick}
		>
			<IconChevron />
			{label ? <span>{!expanded ? "open" : "close"}</span> : null}
		</button>
	);
};

export default ToggleButton;
