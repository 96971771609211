import { ContactMethod } from "model/dto/contact-method";
import { FormEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { useIntl } from "i18n/Internationalization";

export interface SelectContactMethodProps {
	contactMethods: ContactMethod[];
	selectedContactMethod: ContactMethod;
	onSelectContactMethod: (contactMethod: ContactMethod) => void;
	onConfirm: (contactMethod: ContactMethod) => void;
}

const SelectContactMethod = ({
	contactMethods,
	selectedContactMethod,
	onSelectContactMethod,
	onConfirm,
}: SelectContactMethodProps) => {
	const handleSubmit = (event: FormEvent) => {
		event.preventDefault();
		onConfirm(selectedContactMethod);
	};

	const intl = useIntl();

	const i18nstrings = {
		"select-contact-method-choose-method": intl.formatMessage({
			id: "select-contact-method-choose-method",
			defaultMessage:
				"Please choose a contact method to receive an activation code for the Accuserve Claim Tracker.",
		}),
		"select-contact-method-send-activation-code": intl.formatMessage({
			id: "select-contact-method-send-activation-code",
			defaultMessage: "Send Activation Code",
		}),
	};

	return (
		<>
			<Form className="activate-card__form" onSubmit={handleSubmit}>
				<Form.Group className="mb-3">
					<p className="activate-card__form-intro">{i18nstrings["select-contact-method-choose-method"]}</p>
					{contactMethods.map((contactMethod) => (
						<Form.Check
							key={`${contactMethod.contactMethodId}-${contactMethod.methodType}`}
							type="radio"
							id={`contactMethod-${contactMethod.contactMethodId}`}
							className="activate-card__radio"
							name="contactMethod"
							label={
								<>
									{contactMethod.methodType === "Email" ? "Email code to " : "Text code to "}
									{contactMethod.methodType === "Email"
										? contactMethod.emailAddress
										: contactMethod.phoneNumber}
								</>
							}
							value={contactMethod.contactMethodId}
							checked={contactMethod.contactMethodId === selectedContactMethod.contactMethodId}
							onChange={() => onSelectContactMethod(contactMethod)}
						/>
					))}
				</Form.Group>

				<Button className="activate-card__button button" type="submit">
					{i18nstrings["select-contact-method-send-activation-code"]}
				</Button>
			</Form>
		</>
	);
};

export default SelectContactMethod;
