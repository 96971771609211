import { FeedbackQuestionKey } from "model/dto/feedback-question-key";
import {
	detailIconsAccuserveBad,
	detailIconsAccuserveGood,
	detailIconsContractorBad,
	detailIconsContractorGood,
} from "components/FeedbackIcons";
import { Feedback } from "contexts/FeedbackContext";
import { ACCUSERVE_SUBJECT, CONTRACTOR_SUBJECT, OTHER_SUBJECT, FeedbackSubject } from "model/dto/feedback-response";
import { FeedbackPrompt } from "model/dto/feedback-prompt";
import { FeedbackSubmission, FeedbackSubmissionLanguage } from "model/dto/feedback-submission";
import { FeedbackResponse } from "model/dto/feedback-response";
import { useIntl } from "i18n/Internationalization";
import { UUID } from "io-ts-types/lib/UUID";

export type FeedbackType = "prompted" | "elective";

export type FeedbackSectionKey =
	| "accuserve_overall"
	| "accuserve_details"
	| "contractor_overall"
	| "contractor_details"
	| "additional_feedback"
	| "details_experience"
	| "overall_experience";

export type FeedbackValueInfo = {
	section: FeedbackSectionKey;
	questionType: "ENUM" | "TEXT";
	enum?: string;
	label?: string;
};

export type FeedbackSectionInfo = {
	question: string;
	subject: FeedbackSubject;
};

type FeedbackMapping = {
	[key in FeedbackQuestionKey]: FeedbackValueInfo;
};

type FeedbackSectionLookup = {
	enum?: FeedbackQuestionKey;
	subject?: FeedbackSubject;
};

type SectionMapping = {
	[key in FeedbackSectionKey]: FeedbackSectionLookup;
};

const sectionMapping: SectionMapping = {
	accuserve_overall: {
		enum: "ACC_OVERALL",
		subject: ACCUSERVE_SUBJECT,
	},
	contractor_overall: {
		enum: "KOTR_OVERALL",
		subject: CONTRACTOR_SUBJECT,
	},
	accuserve_details: {
		subject: ACCUSERVE_SUBJECT,
	},
	contractor_details: {
		subject: CONTRACTOR_SUBJECT,
	},
	additional_feedback: {
		enum: "ADDITIONAL_FEEDBACK",
		subject: OTHER_SUBJECT,
	},
	details_experience: {
		enum: "ELEC_DETAILS",
	},
	overall_experience: {
		enum: "ELEC_OVERALL",
	},
};

export const getFeedbackQuestionKey = (section: string): FeedbackQuestionKey => {
	return sectionMapping[section].enum;
};

const labelIds = [
	...detailIconsAccuserveBad,
	...detailIconsAccuserveGood,
	...detailIconsContractorBad,
	...detailIconsContractorGood,
].reduce((acc, { value, labelId }) => ({ ...acc, [value]: labelId }), {});

/**
 * @todo Integrate this with the FeedbackIcons.tsx components
 */
const feedbackMapping: FeedbackMapping = {
	ACC_OVERALL: {
		section: "accuserve_overall",
		questionType: "ENUM",
	},
	ACC_POS_GOOD_ATTENTION: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_GOOD_ATTENTION",
		label: labelIds["ACC_POS_GOOD_ATTENTION"],
	},
	ACC_POS_TIMELY_COMM: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_TIMELY_COMM",
		label: labelIds["ACC_POS_TIMELY_COMM"],
	},
	ACC_POS_GOOD_VALUE: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_GOOD_VALUE",
		label: labelIds["ACC_POS_GOOD_VALUE"],
	},
	ACC_POS_GOOD_RESOLUTIONS: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_GOOD_RESOLUTIONS",
		label: labelIds["ACC_POS_GOOD_RESOLUTIONS"],
	},
	ACC_POS_TRUSTWORTHY: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_TRUSTWORTHY",
		label: labelIds["ACC_POS_TRUSTWORTHY"],
	},
	ACC_POS_FLEXIBLE: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_POS_FLEXIBLE",
		label: labelIds["ACC_POS_FLEXIBLE"],
	},
	ACC_NEG_BAD_ATTENTION: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_BAD_ATTENTION",
		label: labelIds["ACC_NEG_BAD_ATTENTION"],
	},
	ACC_NEG_UNTIMELY_COMM: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_UNTIMELY_COMM",
		label: labelIds["ACC_NEG_UNTIMELY_COMM"],
	},
	ACC_NEG_BAD_VALUE: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_BAD_VALUE",
		label: labelIds["ACC_NEG_BAD_VALUE"],
	},
	ACC_NEG_BAD_RESOLUTIONS: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_BAD_RESOLUTIONS",
		label: labelIds["ACC_NEG_BAD_RESOLUTIONS"],
	},
	ACC_NEG_NOT_TRUSTWORTHY: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_NOT_TRUSTWORTHY",
		label: labelIds["ACC_NEG_NOT_TRUSTWORTHY"],
	},
	ACC_NEG_NOT_FLEXIBLE: {
		section: "accuserve_details",
		questionType: "ENUM",
		enum: "ACC_NEG_NOT_FLEXIBLE",
		label: labelIds["ACC_NEG_NOT_FLEXIBLE"],
	},
	KOTR_OVERALL: {
		section: "contractor_overall",
		questionType: "ENUM",
	},
	KOTR_POS_CLEAR_EXPECTATIONS: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_CLEAR_EXPECTATIONS",
		label: labelIds["KOTR_POS_CLEAR_EXPECTATIONS"],
	},
	KOTR_POS_ON_TIME: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_ON_TIME",
		label: labelIds["KOTR_POS_ON_TIME"],
	},
	KOTR_POS_GOOD_WORKMANSHIP: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_GOOD_WORKMANSHIP",
		label: labelIds["KOTR_POS_GOOD_WORKMANSHIP"],
	},
	KOTR_POS_SAFE: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_SAFE",
		label: labelIds["KOTR_POS_SAFE"],
	},
	KOTR_POS_PROFESSIONAL: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_PROFESSIONAL",
		label: labelIds["KOTR_POS_PROFESSIONAL"],
	},
	KOTR_POS_GOOD_COMM: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_POS_GOOD_COMM",
		label: labelIds["KOTR_POS_GOOD_COMM"],
	},
	KOTR_NEG_UNCLEAR_EXPECTATIONS: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_UNCLEAR_EXPECTATIONS",
		label: labelIds["KOTR_NEG_UNCLEAR_EXPECTATIONS"],
	},
	KOTR_NEG_LATE: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_LATE",
		label: labelIds["KOTR_NEG_LATE"],
	},
	KOTR_NEG_BAD_WORKMANSHIP: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_BAD_WORKMANSHIP",
		label: labelIds["KOTR_NEG_BAD_WORKMANSHIP"],
	},
	KOTR_NEG_UNSAFE: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_UNSAFE",
		label: labelIds["KOTR_NEG_UNSAFE"],
	},
	KOTR_NEG_UNPROFESSIONAL: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_UNPROFESSIONAL",
		label: labelIds["KOTR_NEG_UNPROFESSIONAL"],
	},
	KOTR_NEG_BAD_COMM: {
		section: "contractor_details",
		questionType: "ENUM",
		enum: "KOTR_NEG_BAD_COMM",
		label: labelIds["KOTR_NEG_BAD_COMM"],
	},
	ADDITIONAL_FEEDBACK: {
		section: "additional_feedback",
		questionType: "TEXT",
	},
	ELEC_DETAILS: {
		section: "details_experience",
		questionType: "TEXT",
	},
	ELEC_OVERALL: {
		section: "overall_experience",
		questionType: "ENUM",
	},
};

export const getFeedbackValueInfo = (questionKey: FeedbackQuestionKey): FeedbackValueInfo => {
	return feedbackMapping[questionKey];
};

export const getFeedbackSectionEnum = (section: string): FeedbackQuestionKey => {
	return sectionMapping[section];
};
export const getFeedbackValue = (feedback: Feedback, valueInfo: FeedbackValueInfo): string | undefined => {
	// For questions addressing multiselect IconSelectList (details_accuserve, details_contractor etc.)
	if (valueInfo.questionType === "ENUM" && valueInfo.enum) {
		if (!feedback[valueInfo.section]) {
			return undefined;
		}

		return feedback[valueInfo.section][valueInfo.enum] ? "true" : "false";
		// For questions addressing single select IconSelectList (overall_experience etc.)
	} else if (valueInfo.questionType === "ENUM" && !valueInfo.enum) {
		if (!feedback[valueInfo.section]) {
			return undefined;
		}
		for (const key of Object.keys(feedback[valueInfo.section])) {
			if (feedback[valueInfo.section][key]) {
				// The way this is setup the key will be the value of the enum (good:true | bad:true | okay:true)
				return key;
			}
		}
	}
	// For questions addressing text input (additional_feedback)
	if (valueInfo.questionType === "TEXT") {
		if (!feedback[valueInfo.section]) {
			return undefined;
		}
		// There is only one text input per section so we can just return the value of the first key
		// This should be refactored to be more explicit but in the interest of time this is fine for now
		const textKey = feedback[valueInfo.section]["textKey"];
		return feedback[valueInfo.section][textKey] ? feedback[valueInfo.section][textKey] : "";
	}

	return undefined;
};

export const getFeedbackSectionInfo = (section: FeedbackSectionKey, feedback: Feedback): FeedbackSectionInfo => {
	let subject;

	// If the feedback section has a subject defined, use that
	if (feedback[section]?.subject) {
		subject = feedback[section].subject;
		// If the sectionMapping has a sbuject defined, use that
	} else if (sectionMapping[section]?.subject) {
		subject = sectionMapping[section].subject;
	} // Otherwise, use OTHER_SUBJECT
	else {
		subject = OTHER_SUBJECT;
	}

	const sectionInfo: FeedbackSectionInfo = {
		question: feedback[section]?.question,
		subject: subject,
	};
	return sectionInfo;
};

export const getDefinedFeedbackSectionKey = (feedback: Feedback, section: string) => {
	if (feedback[section]) {
		//Search for the first key that is not undefined for sections with one key
		return Object.keys(feedback[section]).find((key) => {
			return feedback[section][key] !== undefined;
		});
	} else {
		return undefined;
	}
};

export const constructFeedbackSubmission = (
	intl: ReturnType<typeof useIntl>,
	feedback: Feedback,
	feedbackType: FeedbackType,
	feedbackPrompt?: FeedbackPrompt,
	feedbackDeclined: boolean = false
): FeedbackSubmission => {
	let prompted = false;
	let promptDeclined = feedbackDeclined;
	const responses: FeedbackResponse[] = [];

	const language = intl.locale.toUpperCase() as FeedbackSubmissionLanguage;

	if (feedbackType === "prompted") {
		prompted = true;
		if (feedbackDeclined) {
			return {
				language: language,
				prompted,
				promptDeclined,
				responses,
			};
		}

		if (feedbackPrompt) {
			for (const question of feedbackPrompt.questions) {
				const valueInfo = getFeedbackValueInfo(question.key);
				const sectionInfo = getFeedbackSectionInfo(valueInfo.section, feedback);
				const value = getFeedbackValue(feedback, valueInfo);
				if (!value) {
					// Sentry.captureException(
					// 	new Error("No value found for requested feedback question: " + question.key)
					// );
					continue;
				}
				const response: FeedbackResponse = {
					questionKey: question.key,
					questionType: valueInfo.questionType,
					displayedText: valueInfo.label
						? intl.formatMessage({
								id: valueInfo.label,
								defaultMessage:
									"Default text was displayed. Software team should update language enumerations",
						  })
						: //This allows us to override the displayed text for a question
						  //for the prompted feedback individual feedback icons
						  sectionInfo.question,
					responseEnum: valueInfo.questionType === "ENUM" ? value : undefined,
					responseText: valueInfo.questionType === "TEXT" ? value : undefined,
					subject: sectionInfo.subject,
					contractorId: undefined,
				};
				responses.push(response);
			}
		}
		return {
			language: language,
			prompted,
			promptDeclined,
			responses,
		};
	} else if (feedbackType === "elective") {
		let subject: FeedbackSubject = OTHER_SUBJECT;
		const subjectKey = getDefinedFeedbackSectionKey(feedback, "feedback_for")?.toUpperCase();
		if (subjectKey === CONTRACTOR_SUBJECT || subjectKey === ACCUSERVE_SUBJECT) {
			subject = subjectKey;
		}

		const contractorId =
			subject === CONTRACTOR_SUBJECT
				? (getDefinedFeedbackSectionKey(feedback, "feedback_for_contractor") as UUID)
				: undefined;

		const sentiment = getDefinedFeedbackSectionKey(feedback, "overall_experience");

		if (!subject || !sentiment) {
			return {
				language: language,
				prompted,
				promptDeclined,
				responses: [],
			};
		}

		const electiveSections = ["details_experience", "overall_experience"] as FeedbackSectionKey[];

		for (const section of electiveSections) {
			const questionKey = getFeedbackQuestionKey(section);
			const elecValueInfo = getFeedbackValueInfo(questionKey);
			const electSectionInfo = getFeedbackSectionInfo(section, feedback);
			const electValue = getFeedbackValue(feedback, elecValueInfo);
			if (!electValue) {
				// Sentry.captureException(new Error("No value found for requested feedback question: " + questionKey));
				continue;
			}
			const response: FeedbackResponse = {
				questionKey,
				questionType: elecValueInfo.questionType,
				displayedText: electSectionInfo.question,
				responseEnum: elecValueInfo.questionType === "ENUM" ? electValue : undefined,
				responseText: elecValueInfo.questionType === "TEXT" ? electValue : undefined,
				subject: subject,
				contractorId,
			};
			responses.push(response);
		}

		return {
			language: language,
			prompted,
			promptDeclined,
			responses,
		};
	}

	return {
		language: language,
		prompted: false,
		promptDeclined: false,
		responses: [],
	};
};
