import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { ContractorEmployeeCodec } from "model/dto/contractor-employee";
import { ContactMethodCodec } from "model/dto/contact-method";

export const AssignedContractorRequired = t.type({
	contactId: UUID,
	contractorId: UUID,
	contactMethods: t.array(ContactMethodCodec),
	contractorEmployees: t.array(ContractorEmployeeCodec),
});

export const AssignedContractorOptional = t.partial({
	fullName: t.union([t.null, t.string]),
	firstName: t.union([t.null, t.string]),
	lastName: t.union([t.null, t.string]),
	preferredName: t.union([t.null, t.string]),
	preferredContactMethod: t.union([t.null, t.string]),
});

export const AssignedContractorCodec = t.intersection([AssignedContractorRequired, AssignedContractorOptional]);

export type AssignedContractor = t.TypeOf<typeof AssignedContractorCodec>;
