import { Link } from "react-router-dom";
import { useIntl } from "i18n/Internationalization";
import { useLocation } from "react-router-dom";

type ErrorProps = {
	title: string;
	copy: string;
};

const ErrorCard = ({ title, copy }: ErrorProps) => {
	const intl = useIntl();

	const i18nstrings = {
		"error-card-return":
			intl.formatMessage({
				id: "error-card-return",
				defaultMessage: "Return Home",
			}) || "Return Home",
	};
	const location = useLocation();
	let returnHomeLink;

	// We do not have access to the portal context on this page so we need to check the url for the publicId
	if (location.pathname.length > 1 && location.pathname.match(/\/[a-zA-Z0-9]{8}/)) {
		const splits = location.pathname.split("/");
		// If we get an error on activation, we want to return to the home page, not to portal dashboard
		if (splits.length > 2 && splits[2] === "activate") {
			returnHomeLink = "/";
		} else {
			// If we get an error on any other page, we want to return to the portal dashboard, this grabs the publicId from the url
			returnHomeLink = "/" + location.pathname.split("/")[1];
		}
	}

	return (
		<div className="error-card">
			<div className="error-card__inner">
				<h1 className="error-card__title">{title}</h1>
				<div className="error-card__content card">
					<div className="error-card__graphic">
						<img
							className=""
							src="/img/graphic-washing-machine.png"
							alt="Illustration of a washing machine leaking water"
						/>
					</div>
					<div className="error-card__copy">
						<p>{copy}</p>
						<p>
							<Link
								role="button"
								to={returnHomeLink}
								className="button secondary"
								aria-label="Return home"
								reloadDocument={true}
							>
								{i18nstrings["error-card-return"]}
							</Link>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ErrorCard;
