import * as t from "io-ts";

export const FeedbackQuestionKeyCodec = t.union([
	t.literal("ACC_NEG_UNTIMELY_COMM"),
	t.literal("ACC_NEG_BAD_ATTENTION"),
	t.literal("ACC_NEG_BAD_RESOLUTIONS"),
	t.literal("ACC_NEG_NOT_FLEXIBLE"),
	t.literal("ACC_NEG_NOT_TRUSTWORTHY"),
	t.literal("ACC_NEG_BAD_VALUE"),
	t.literal("ACC_OVERALL"),
	t.literal("ACC_POS_TIMELY_COMM"),
	t.literal("ACC_POS_GOOD_ATTENTION"),
	t.literal("ACC_POS_GOOD_RESOLUTIONS"),
	t.literal("ACC_POS_FLEXIBLE"),
	t.literal("ACC_POS_TRUSTWORTHY"),
	t.literal("ACC_POS_GOOD_VALUE"),
	t.literal("KOTR_NEG_BAD_COMM"),
	t.literal("KOTR_NEG_UNCLEAR_EXPECTATIONS"),
	t.literal("KOTR_NEG_UNPROFESSIONAL"),
	t.literal("KOTR_NEG_BAD_WORKMANSHIP"),
	t.literal("KOTR_NEG_UNSAFE"),
	t.literal("KOTR_NEG_LATE"),
	t.literal("KOTR_OVERALL"),
	t.literal("KOTR_POS_GOOD_COMM"),
	t.literal("KOTR_POS_CLEAR_EXPECTATIONS"),
	t.literal("KOTR_POS_PROFESSIONAL"),
	t.literal("KOTR_POS_GOOD_WORKMANSHIP"),
	t.literal("KOTR_POS_SAFE"),
	t.literal("KOTR_POS_ON_TIME"),
	t.literal("ELEC_OVERALL"),
	t.literal("ELEC_DETAILS"),
	t.literal("ADDITIONAL_FEEDBACK"),
]);

export type FeedbackQuestionKey = t.TypeOf<typeof FeedbackQuestionKeyCodec>;
