import { FieldItem, FieldRegistry, Field } from "model/types/Fields";
import { ReactNode } from "react";
import { useIntl } from "i18n/Internationalization";

type TextItem = FieldItem<string>;
type TextValue = FieldRegistry<string>;
export type TextField = Field<TextValue, TextItem> & {
	label?: string | ReactNode;
};

export const FieldTextArea = ({ field }: { field: TextField }) => {
	const intl = useIntl();
	const updateValue = (ev) =>
		field.saveValue(field.name, {
			...field.value,
			...{
				[field.name]: ev.target.value,
			},
		});

	const labelElement = field.label ? <label>{field.label}</label> : <></>;

	return (
		<>
			<div className="field__textArea">
				{labelElement}
				<textarea
					name={field.name}
					value={field.value[field.name]}
					onChange={updateValue}
					placeholder={intl.formatMessage({
						id: "feedback-modal-prompted-additional-comments",
						defaultMessage: "Please enter any additional comments...",
					})}
				></textarea>
			</div>
		</>
	);
};
