import * as t from "io-ts";

export const SubstatusTypeCodec = t.union([
	t.literal("N/A"),
	t.literal("Accuserve Assigned"),
	t.literal("Accuserve Not Handling Service"),
	t.literal("Assigning Contractor"),
	t.literal("Assigning Service"),
	t.literal("Attempting to Contact"),
	t.literal("Awaiting COS"),
	t.literal("Awaiting Inspection"),
	t.literal("Awaiting Work Authorization"),
	t.literal("Board-up In Progress"),
	t.literal("Board-up Planning"),
	t.literal("Board-up Scheduled"),
	t.literal("Contents being stored"),
	t.literal("Contents Cleaning In Progress"),
	t.literal("Contents Cleaning Planning"),
	t.literal("Contents Cleaning Scheduled"),
	t.literal("Contractor Assigned"),
	t.literal("Drying"),
	t.literal("Estimate Approved"),
	t.literal("Estimate In Progress"),
	t.literal("Mitigation In Progress"),
	t.literal("Mitigation Planning"),
	t.literal("Mitigation Scheduled"),
	t.literal("Packout Planning"),
	t.literal("Packout Scheduled"),
	t.literal("Reconstruction In Progress"),
	t.literal("Reconstruction Planning"),
	t.literal("Reconstruction Scheduled"),
	t.literal("Repairs Being Planned"),
	t.literal("Repairs In Progress"),
	t.literal("Repairs On Hold"),
	t.literal("Roof Complete"),
	t.literal("Service Complete"),
	t.literal("Submitting to Carrier"),
	t.literal("Tree Removal In Progress"),
	t.literal("Tree Removal Planning"),
	t.literal("Tree Removal Scheduled"),
	t.literal("Water Mitigation On Hold"),
	t.literal("Water Mitigation Planning"),
	t.literal("Water Mitigation In Progress"),
]);

export type SubstatusType = t.TypeOf<typeof SubstatusTypeCodec>;
