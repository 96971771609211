import Page404 from "pages/error/Page404";
import ErrorLayout from "layouts/ErrorLayout";
import { Navigate, useLocation } from "react-router-dom";

const REDIRECT_PATH = "/redirect";

const RedirectPage = () => {
	const { pathname, search } = useLocation();

	let dest = pathname.substring(REDIRECT_PATH.length);
	if (dest === "") {
		dest = "/";
	}

	// survey
	if (dest.startsWith("/s/")) {
		// not implemented yet
		return (
			<ErrorLayout>
				<div className="errorWrap">
					<Page404 />
				</div>
			</ErrorLayout>
		);
	}

	// portal (portal.app.accuserve.com/abc)
	if (!dest.includes("/", 1)) {
		return <Navigate to={dest + search} />;
	}

	// anything else is invalid
	return (
		<ErrorLayout>
			<div className="errorWrap">
				<Page404 />
			</div>
		</ErrorLayout>
	);
};

export default RedirectPage;
