import { Claim } from "model/dto/claim";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";

type PageIntroGreetingProps = {
	claim: Claim;
};

const PageIntroGreeting = ({ claim }: PageIntroGreetingProps) => {
	const i18n = useIntl();
	const { getIntroStrings } = useProse(i18n);
	const { greeting, summary } = getIntroStrings(claim);

	return (
		<div className="dash-intro__greeting">
			<h1 className="dash-intro__title">{greeting}</h1>
			<p className="dash-intro__description">{summary}</p>
		</div>
	);
};

export default PageIntroGreeting;
