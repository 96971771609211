import { ReactElement, useEffect } from "react";

type PrimaryDialogProps = {
	children: ReactElement;
	show: boolean;
	close: () => void;
};

export const PrimaryDialog = ({ children, show, close }: PrimaryDialogProps) => {
	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				close();
			}
		};

		window.addEventListener("keydown", handleEsc);

		return () => {
			window.removeEventListener("keydown", handleEsc);
		};
	}, [close]);
	if (!show) {
		return <></>;
	}
	return (
		<section className="primary-dialog" role="dialog">
			<div className="primary-dialog__inner">
				<div className="primary-dialog__content">
					<button className="primary-dialog-close" onClick={close} aria-label="Close" title="Close">
						close
					</button>
					{children}
				</div>
			</div>
		</section>
	);
};
