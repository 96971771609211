import PrimarySidebar from "components/PrimarySidebar";
import PageIntroGreeting from "components/PageIntroGreeting";
import PageIntroStatus from "components/PageIntroStatus";
import { Claim } from "model/dto/claim";
import { PortalInfo } from "model/dto/portal-info";

type PageIntroProps = {
	publicId: String | undefined;
	claim: Claim;
	otherPortalsList: Array<PortalInfo> | null;
};

const PageIntro = ({ claim, publicId, otherPortalsList }: PageIntroProps) => {
	return (
		<section className="dash-intro">
			<div className="dash-intro__inner d-flex">
				<PageIntroGreeting claim={claim} />
				<PageIntroStatus tz={claim.lossAddressTimeZone || undefined} services={claim.services || []} />
				<PrimarySidebar
					publicId={publicId}
					claim={claim}
					isAbsolute={true}
					otherPortalsList={otherPortalsList}
					hideHistory={false}
				/>
			</div>
		</section>
	);
};

export default PageIntro;
