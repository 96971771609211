// import PageIntro from "components/PageIntro";
// import PrimaryFooter from "components/PrimaryFooter";
// import PrimaryHeader from "components/PrimaryHeader";
// import ServiceList from "components/ServiceList";
// import PrimaryContent from "layouts/PrimaryContent";

const StaticTestPage = () => {
  return (
    <>
      {/* <PrimaryHeader />
      <PrimaryContent>
        <PageIntro />
        <ServiceList />
      </PrimaryContent>
      <PrimaryFooter /> */}
    </>
  );
}

export default StaticTestPage;