import { left } from "fp-ts/lib/Either";
import { Decoder, Errors } from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { PathReporter } from "io-ts/lib/PathReporter";

export const throwDecoderError = (errors: Errors) => {
	throw PathReporter.report(left(errors));
};

function typeInstance<I, A>(decoder: Decoder<I, A>, value: I) {
	return decoder.decode(value).getOrElseL(throwDecoderError);
}

export const uuidInstance = (value: string) => typeInstance(UUID, value);

export const NIL_UUID = uuidInstance("00000000-0000-0000-0000-000000000000");

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Updates the specified properties of an object in an array and returns a new array
 * in the original order with the updated element.
 */
export function updateFirstInArray<T>(arr: T[], filter: (elem: T) => boolean, newValues: Partial<T>): T[] {
	var found = false;
	return arr.map((e) => {
		if (found || !filter(e)) {
			return e;
		}
		found = true;
		return {
			...e,
			...newValues,
		};
	});
}

export function compareArraysById<T>(a: T[], b: T[], idAccessor: (elem: T) => any): boolean {
	if (a.length !== b.length) {
		return false;
	}
	const idLookup = new Set<any>();
	a.forEach((aElem) => idLookup.add(idAccessor(aElem)));
	return !b.find((bElem) => !idLookup.has(idAccessor(bElem)));
}

export function localeComparator(a: string, b: string): number {
	return a.localeCompare(b);
}

export function groupBy<T>(arr: T[], groupKey: string): { groupBy: { [group: string]: T[] }; extra: T[] } {
	const groupBy: { [group: string]: T[] } = {};
	const extra: T[] = [];
	arr.forEach((elem) => {
		const group = elem[groupKey];
		if (group) {
			if (!groupBy[group]) {
				groupBy[group] = [elem];
			} else {
				groupBy[group].push(elem);
			}
		} else {
			extra.push(elem);
		}
	});
	return {
		groupBy,
		extra,
	};
}

export function toHash<T>(arr: Array<T> = [], key: string = ""): { [key: string]: T } {
	return arr.reduce((h, entry) => {
		h[entry[key]] = entry;
		return h;
	}, {});
}

export function firstCap(str: string): string {
	return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export function oxfordCommatize(list: Array<string>, joiner: string): string {
	if (list.length === 2) {
		return list.join(` ${joiner} `);
	} else if (list.length >= 3) {
		const csv = list.slice(0, list.length - 1).join(", ");

		return `${csv}, ${joiner} ${list[list.length - 1]}`;
	}

	return list.join(",");
}

export function randomishString(): string {
	return (Math.random() + Math.random() + 1).toString(36).substring(2);
}
