import React, { ReactNode, createContext, useContext } from "react";
import { Locale } from "i18n/LocaleContext";
import { renderToString } from "react-dom/server";

type MessageDescriptor = {
	id: string; // react-intl lists this as optional
	description?: string;
	defaultMessage: string; // react-intl lists this as optional
};

type FormatMessageProps = {
	id: string; // react-intl lists this as optional
	description?: string;
	defaultMessage: string; // react-intl lists this as optional
	values?: Record<string, string>;
};

export type I18nContextProps = {
	locale: Locale;
	defaultLocale: Locale;
	messages: Record<string, string>;
	formatMessage: (descriptor: MessageDescriptor, values?: Record<string, string>) => string;
	formatMessageWithElements: (descriptor: MessageDescriptor, values: Record<string, JSX.Element>) => JSX.Element;
};

type IntlProviderProps = {
	children: ReactNode;
	defaultLocale: Locale;
	locale: Locale;
	messages: Record<string, string>;
};

const I18nContext = createContext<I18nContextProps>({
	locale: Locale.ENGLISH,
	defaultLocale: Locale.ENGLISH,
	messages: {},
	formatMessage: () => "",
	formatMessageWithElements: () => <></>,
});

export const IntlProvider: React.FC<IntlProviderProps> = ({ children, defaultLocale, locale, messages }) => {
	const formatMessage = (descriptor: MessageDescriptor, values?: Record<string, string>) => {
		const message = messages && messages[descriptor.id] ? messages[descriptor.id] : descriptor.defaultMessage;

		// skip replacement if no message
		if (!message) return descriptor.defaultMessage;

		if (values) {
			const replacementKeys = Object.keys(values);

			return replacementKeys.reduce((acc, key) => {
				return acc.replace(`{${key}}`, values[key]);
			}, message);
		}
		return message;
	};

	const formatMessageWithElements = (descriptor: MessageDescriptor, values: Record<string, JSX.Element>) => {
		const message = messages ? messages[descriptor.id] : descriptor.defaultMessage;
		if (values) {
			const replacementKeys = Object.keys(values);
			const newElement = (
				<div
					dangerouslySetInnerHTML={{
						__html: replacementKeys.reduce((acc, key) => {
							return acc.replace(`{${key}}`, renderToString(values[key]));
						}, message),
					}}
				/>
			);
			return newElement;
		}
		return <div dangerouslySetInnerHTML={{ __html: message }} />;
	};

	return (
		<I18nContext.Provider
			value={{
				locale,
				defaultLocale,
				messages,
				formatMessage,
				formatMessageWithElements,
			}}
		>
			{children}
		</I18nContext.Provider>
	);
};

export const useIntl = () => useContext(I18nContext);

export function FormattedMessage({ id, description, defaultMessage, values }: FormatMessageProps) {
	const intl = useIntl();
	return (
		<>
			{intl.formatMessage(
				{
					id,
					description,
					defaultMessage,
				},
				{
					...values,
				}
			)}
		</>
	);
}
