import React, { ReactNode } from "react";
import moment from "moment";
import { Service } from "model/dto/service";
import { ServiceType } from "model/dto/service-type";
import { SubstatusType } from "model/dto/substatus-type";
import { BOARD_UP } from "prose/board-up";
import { CONTENTS_CLEANING } from "prose/contents-cleaning";
import { CONTENTS_PACKOUT } from "prose/contents-packout";
import { FIRE_MITIGATION } from "prose/fire-mitigation";
import { PLURALS } from "prose/plurals";
import { RECONSTRUCTION } from "prose/reconstruction";
import { ROOF_RESTORATION } from "prose/roof-restoration";
import { TREE_REMOVAL } from "prose/tree-removal";
import { WATER_MITIGATION } from "prose/water-mitigation";
import { firstCap, oxfordCommatize } from "utils/util";
import { getValidServices, getSortedServiceList } from "utils/serviceUtil";
import * as Sentry from "@sentry/browser";
import { I18nContextProps } from "i18n/Internationalization";
import { extractName } from "utils/claim";
import { Claim } from "model/dto/claim";
import { JournalEntry } from "model/dto/journal-entry";
import { HistoryEntry } from "utils/claim";

export const STATUS_CHANGE_EVENT: string = "statusChange";

export type FeedbackSentiment = "good" | "bad" | "okay";
export type FeedbackSubject = "contractor" | "accuserve" | "other";

export type ProseEntry = {
	type: string;
	state: string;
	terminal?: boolean;
	prose: Array<string>;
};

export type Sentence = {
	service: string;
	text: string;
};

/**
 * Strings representing the possible time of day segments.
 */
export type TimeOfDayString = "morning" | "afternoon" | "evening";

/**
 * Represents teh time of day object containing an i18n-fied label
 * and an icon for the particular time of day.
 */
export type TimeOfDay = {
	label: string;
	icon: ReactNode;
};

export type PageIntro = {
	timeOfDay: TimeOfDay;
	greeting: string;
	summary: string;
};

/**
 * Given a prose array, this function will return a substatus ordering
 * array.
 *
 * NOTE: any "terminal" states will not be included in this array
 *
 * @param proseArr the prose array to extract orderings from
 * @returns an ordering array for the provided prose array
 */
const extractOrdering = (proseArr: Array<ProseEntry>): Array<SubstatusType> => {
	return proseArr.filter((entry) => !entry.terminal).map((entry) => entry.state as SubstatusType);
};

// substatus orderings for each service
const ORDERING = {
	Reconstruction: extractOrdering(RECONSTRUCTION),
	"Fire Mitigation": extractOrdering(FIRE_MITIGATION),
	"Contents Cleaning": extractOrdering(CONTENTS_CLEANING),
	"Contents Packout": extractOrdering(CONTENTS_PACKOUT),
	"Board Up": extractOrdering(BOARD_UP),
	"Tree Removal": extractOrdering(TREE_REMOVAL),
	Roofing: extractOrdering(ROOF_RESTORATION),
	"Water Mitigation": extractOrdering(WATER_MITIGATION),
};

export const useProse = ({ formatMessage, locale }: I18nContextProps) => {
	/**
	 * Retrieves the current time of day.
	 *
	 * @returns a TimeOfDay string that represents the current time of day.
	 */
	function getTimeOfDay(): TimeOfDay {
		const hourOfDay = moment().get("hour");

		let timeOfDayString: TimeOfDayString = "evening";
		if (hourOfDay < 12) {
			timeOfDayString = "morning";
		} else if (hourOfDay < 17) {
			timeOfDayString = "afternoon";
		}

		return {
			label: formatMessage({
				id: `intro-utilities-time-of-day-${timeOfDayString}`,
				defaultMessage: timeOfDayString,
			}),
			icon: getTimeOfDayIcon(timeOfDayString),
		};
	}

	/**
	 * Given a TimeOfDay this will build the appropriate icon img
	 *
	 * @param timeOfDay the time of day to build an icon for
	 * @returns an img representing the appropriate time of day icon
	 */
	function getTimeOfDayIcon(timeOfDay: TimeOfDayString) {
		return React.createElement("img", { src: `img/icon-${timeOfDay}.svg`, alt: timeOfDay }, null);
	}

	function getGreeting(services: Array<Service> = []): string {
		const validServices = getValidServices(services);

		let greeting = "";

		if (validServices.length > 0) {
			greeting = buildGreetingStatus(services);
		} else {
			const serviceNames = services.map((service) => {
				return getTranslatedService(service.serviceType).toLowerCase();
			});

			if (serviceNames.length === 1) {
				greeting = `Details on your ${serviceNames[0]} service are currently not available; please check back soon.`;
			} else if (serviceNames.length === 2) {
				greeting = `Details on your ${serviceNames.join(
					" and "
				)} services are currently not available; please check back soon.`;
			} else {
				const firstFew = serviceNames.slice(0, serviceNames.length - 2).join(", ");
				const allTogether = `${firstFew}, and ${serviceNames[serviceNames.length - 1]}`;

				greeting = `Details on your ${allTogether} services are currently not available; please check back soon.`;
			}
		}

		return greeting;
	}

	function getIntroStrings(claim: Claim): PageIntro {
		const timeOfDay = getTimeOfDay();
		const prefix = formatMessage({
			id: "introduction-greeting",
			defaultMessage: "Good",
		});
		const name = extractName(claim);

		return {
			timeOfDay: timeOfDay,
			greeting: `${prefix} ${timeOfDay.label} ${name}`,
			summary: getGreeting(claim.services || []),
		};
	}

	/**env
	 * Finds the index of the current substatus.
	 *
	 * @param list prose list to search through
	 * @param status the substatus to find an index for
	 * @returns the index of the substatus within the prose list
	 */
	function findStatusIdx(list: Array<SubstatusType> = [], status: SubstatusType) {
		return list.findIndex((value) => {
			return value === status;
		});
	}

	/**
	 * A string with any service placeholders replaced.
	 *
	 * @param service the service string that will appear in the sentence
	 * @param sentence the sentence that will have the service inserted into
	 * @returns a string with any placeholders replaced with the service.
	 */
	function replaceServiceInSentence(service: string, sentence: string): string {
		return firstCap(sentence.replace("{service}", service.toLowerCase()));
	}

	/**
	 * Pulls the actual substatus path to the current substatus from the
	 * service's history.
	 *
	 * @param service the service to pull substatus path from
	 */
	function getSubstatusPath(service: Service): Array<SubstatusType> {
		return (service.serviceHistoryEvents || [])
			.filter((evt) => {
				return typeof evt.portalSubStatus !== "undefined" && evt.eventType === "statusChange";
			})
			.sort((a, b) => {
				return a.eventTimestamp.getTime() - b.eventTimestamp.getTime();
			})
			.map((evt) => {
				if (typeof evt.portalSubStatus === "undefined") {
					return "N/A";
				}

				return evt.portalSubStatus;
			});
	}

	/**
	 * Checks if the current substatus progression is the expected
	 * progression
	 *
	 * @param currentIdx the current substatus index
	 * @param service service we are checking the path for
	 * @returns
	 */
	function isExpectedPath(currentIdx: number, service: Service): boolean {
		const serviceType = service.serviceType;

		if (typeof serviceType !== "string") {
			return false;
		}

		// substatus paths (actual v. expected)
		const actualPath = getSubstatusPath(service);
		const expectedPath = ORDERING[serviceType];

		// past substatus (actual v. expected)
		const currentPast = actualPath[actualPath.length - 2];
		const expectedPast = expectedPath[currentIdx - 1];

		// if we don't have an actual, we can't
		// figure out if we have not followed the
		// expected path, so just assume things are
		// good (as we have been)
		if (actualPath.length === 0) {
			return true;
		}

		return currentPast === expectedPast;
	}

	/**
	 *
	 * @param serviceType
	 * @returns
	 */
	function getTranslatedService(serviceType: ServiceType | null | undefined): string {
		return serviceType
			? formatMessage({
					id: `service-utilities-${serviceType.trim().replace(/\s/g, "-")}`.toLowerCase(),
					defaultMessage: serviceType,
			  })
			: "Service"; // TODO: there should be a default translation for this for now it's english
	}

	const TENSE_SHIFT = {
		past: -1,
		present: 0,
		future: 1,
	};

	function getTranslationKey(serviceType: ServiceType, substatus: SubstatusType, tense: string) {
		return `${serviceType}-${substatus}-${tense}`.trim().replace(/\s/g, "-").toLowerCase();
	}

	function getTranslatedServicePhrase(serviceType: ServiceType, substatus: SubstatusType, tense: string) {
		return formatMessage(
			{
				id: getTranslationKey(serviceType, substatus, tense),
				defaultMessage: substatus,
			},
			{
				service: getTranslatedService(serviceType),
			}
		);
	}

	/**
	 * Builds a substatus paragraph for a given service + state
	 *
	 * @param {ServiceType} service the service for which we are generating this substatus
	 */
	function buildCardStatus(service: Service | undefined | null): string {
		const serviceType = service?.serviceType;
		const currentSubstatus = service?.portalSubStatus;

		if (!service || typeof serviceType !== "string" || typeof currentSubstatus !== "string") {
			return "";
		}

		const statusIdx = findStatusIdx(ORDERING[serviceType], currentSubstatus);

		const translate = (tense: string) => {
			// NOTE: the substatus will return undefined if the service is at the end of its lifecycle for future
			// This is fine because is will not be displayed in the UI and a default empty string will be returned from the default message
			const substatus = ORDERING[serviceType][statusIdx + TENSE_SHIFT[tense]];
			const translationKey = getTranslationKey(serviceType, substatus, tense);

			return formatMessage(
				{
					id: translationKey,
					defaultMessage: "",
				},
				{ service: getTranslatedService(serviceType) }
			);
		};

		// for non-terminal (mostly) status we will find it
		// in the ordering array, so use that to build the
		// prose.
		if (statusIdx > -1) {
			let previous: string, current: string, next: string;

			if (!isExpectedPath(statusIdx, service)) {
				previous = "";
				current = translate("present");
				next = translate("future");
			} else {
				previous = translate("past");
				current = translate("present");
				next = translate("future");
			}

			return [previous, current, next].filter((v) => v).join(" ");
		}

		// in this case, we have a "terminal" status, so just show the
		// present version of that status (e.g. "Accuserve Not Handling Service")
		return formatMessage(
			{
				id: getTranslationKey(serviceType, currentSubstatus, "present"),
				defaultMessage: "",
			},
			{ service: getTranslatedService(serviceType) }
		);
	}

	/**
	 * Returns a paragraph that represents the current status for the active services.
	 *
	 * @param {Array<Service>} services an array of the current services being tracked
	 * @returns
	 */
	function buildGreetingStatus(services: Array<Service>): string {
		if (services.length === 1) {
			const [service] = services;

			return buildCardStatus(service);
		}

		const condensed = getSortedServiceList(services)
			.map(({ serviceType, portalSubStatus }) => {
				if (typeof serviceType !== "string" || typeof portalSubStatus !== "string") {
					return {
						service: "",
						text: "",
					};
				}

				return {
					service: getTranslatedService(serviceType),
					status: portalSubStatus,
					text: formatMessage({
						id: getTranslationKey(serviceType, portalSubStatus, "present"),
						defaultMessage: "",
					}),
				} as Sentence;
			})
			.reduce((h, sentence) => {
				if (!h[sentence.text]) {
					h[sentence.text] = [];
				}

				h[sentence.text].push(sentence.service);

				return h;
			}, {} as { [key: string]: Array<string> });

		return Object.entries(condensed)
			.map(([text, serviceTypes]) => {
				const joiner = formatMessage({
					id: "utility-oxford-command-and",
					defaultMessage: "",
				});

				const service = oxfordCommatize(serviceTypes, joiner).toLowerCase();
				const sentence = (function () {
					if (serviceTypes.length <= 1) return text;
					if (PLURALS[text] === undefined) {
						Sentry.captureMessage('No plural text defined for: "' + text + '"');
						return text;
					} else return PLURALS[text];
				})();
				const replaced = replaceServiceInSentence(service, sentence);

				return firstCap(replaced);
			})
			.join(" ");
	}

	/**
	 * Returns the present status phrase
	 *
	 * @param serviceType the service type to retrieve language for
	 * @param substatus the substatus for which we want to retrieve language
	 * @returns the present status phrase
	 */
	function getPresent(serviceType: ServiceType, substatus: SubstatusType) {
		return formatMessage(
			{
				id: getTranslationKey(serviceType, substatus, "present"),
				defaultMessage: "",
			},
			{
				service: getTranslatedService(serviceType),
			}
		);
	}

	/**
	 * Returns the past status phrase
	 *
	 * @param serviceType the service type to retrieve language for
	 * @param substatus the substatus for which we want to retrieve language
	 * @returns the past status phrase
	 */
	function getPast(serviceType: ServiceType, substatus: SubstatusType) {
		return formatMessage(
			{
				id: getTranslationKey(serviceType, substatus, "past"),
				defaultMessage: "",
			},
			{
				service: getTranslatedService(serviceType),
			}
		);
	}

	/**
	 * Retrieve appropriate history entry text.
	 *
	 * @param entry a history event that we want to extract appropriate event text
	 * from
	 * @returns a string that represents that appropriate event text to display. if not
	 * enough information is present an empty string will be returned...
	 */
	function getHistoryStrings(entry: HistoryEntry): { title: string; description: string } {
		const journalEntry: JournalEntry = entry.entry as JournalEntry;
		const eventType = journalEntry.eventType;
		const service = entry.service;
		const portalSubStatus = journalEntry.portalSubStatus;

		const title = formatMessage({ id: journalEntry.eventTitle, defaultMessage: "Status Update" });

		if (eventType === STATUS_CHANGE_EVENT && portalSubStatus && service && service.serviceType) {
			return {
				title,
				description: getPresent(service.serviceType, portalSubStatus),
			};
		} else if (journalEntry.eventType !== STATUS_CHANGE_EVENT && journalEntry.eventTextToken) {
			const args = journalEntry.eventTextToken.arguments.reduce((acc, argument) => {
				let val = argument.value;
				if (argument.name === "date") {
					const date = new Date(argument.value);
					// for now everyone will get US date formatting we can conditionally
					// format this later when we have more locales to support
					val = new Intl.DateTimeFormat(`en-US`).format(date);
				} else if (argument.name === "serviceType") {
					val = getTranslatedService(argument.value as ServiceType);
				}
				return {
					...acc,
					[argument.name]: val,
				};
			}, {});

			return {
				title,
				description: formatMessage(
					{
						id: journalEntry.eventTextToken.token,
						defaultMessage: journalEntry.eventText || "",
					},
					args
				),
			};
		}

		return { title, description: journalEntry.eventText || "" };
	}

	function getFeedbackSentimentQuestion(sentiment: FeedbackSentiment, subject: FeedbackSubject) {
		const key = `feedback-modal-whats-${sentiment}-${subject}`;

		return formatMessage({
			id: key,
			defaultMessage: `How has your experience been with ${subject}?`,
		});
	}

	return {
		getTranslatedService,
		getTranslatedServicePhrase,
		buildCardStatus,
		buildGreetingStatus,
		getIntroStrings,
		getPast,
		getPresent,
		getHistoryStrings,
		getFeedbackSentimentQuestion,
	};
};

export default useProse;
