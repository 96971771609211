import { Service } from "model/dto/service";
import { isServiceStopped } from "utils/serviceUtil";

type ServiceIconProps = {
	service: Service;
	ignoreStatus?: boolean;
	stopped?: boolean;
};

export const TreeRemovalIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M94.3902 45.8378C94.5502 45.0273 94.5502 44.2169 94.5502 43.2459C94.5502 29.3308 83.3449 18 69.5784 18C55.8118 18 44.6065 29.3308 44.6065 43.2459C44.6065 44.0563 44.6065 45.0273 44.7666 45.8378C36.6027 50.0429 31 58.6213 31 68.4918C31 82.4069 42.2053 93.7377 55.9719 93.7377L60.1966 68.3635C60.4375 66.9167 61.6892 65.8562 63.1559 65.8562H74.9133C76.3402 65.8562 77.5696 66.8612 77.8534 68.2595L83.0248 93.7377C96.7913 93.7377 107.997 82.4069 107.997 68.4918C108.157 58.4607 102.554 49.8823 94.3902 45.8378Z"
						fill="#6B7B81"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M54.9834 122.771L64.4998 71.0166H73.9998L84.0162 122.771H54.9834Z"
						fill="#C9DADF"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M89.2761 51.822C89.3972 51.2091 89.3972 50.5962 89.3972 49.8619C89.3972 39.3391 80.9235 30.7705 70.5129 30.7705C60.1024 30.7705 51.6287 39.3391 51.6287 49.8619C51.6287 50.4748 51.6287 51.2091 51.7498 51.822C45.5761 55.002 41.3392 61.4891 41.3392 68.9534C41.3392 79.4762 49.8129 88.0448 60.2235 88.0448L63.4183 68.8564C63.6005 67.7623 64.547 66.9603 65.6562 66.9603H74.5474C75.6264 66.9603 76.5561 67.7203 76.7707 68.7777L80.6814 88.0448C91.0919 88.0448 99.5656 79.4762 99.5656 68.9534C99.6867 61.3677 95.4498 54.8805 89.2761 51.822Z"
						fill="#C3855A"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M59.4761 110L66.6725 70.8628H73.8566L81.4312 110H59.4761Z"
						fill="#E6B293"
					/>
				</svg>
			</>
		);
	}
};
export const FireMitigationIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M83.3729 34.8654C81.8929 32.9654 80.4129 31.0554 78.9229 29.3654V29.1554C73.6329 23.0154 71.5129 18.5754 70.4529 16.0354C70.0329 14.9754 68.5529 14.5554 67.7029 15.6154C61.9929 23.0254 61.3529 27.4654 61.3529 31.9154C61.3529 36.7854 70.4529 53 65 60C59.5471 67 49.5 62 49.5 53C49.5 44 51.4029 40.5854 50.9729 40.5854C50.7629 40.5854 50.5529 40.7954 50.3329 41.2254C43.3529 51.3854 39.7529 59.8554 39.7529 65.9954C39.7529 69.8054 40.6029 73.6154 42.0829 77.2154C43.5629 80.8154 45.6829 83.9854 48.4329 86.7454C51.1829 89.4954 54.3629 91.6154 57.9529 93.0954C61.5529 94.5754 65.3629 95.4254 69.1729 95.4254C72.9829 95.4254 76.7929 94.5754 80.3929 93.0954C83.9929 91.6154 87.1629 89.4954 89.9129 86.7454C92.6629 83.9954 94.7829 80.8154 96.2629 77.2154C97.7429 73.6154 98.5929 69.8054 98.5929 65.9954C98.5929 58.5854 93.3029 48.0054 83.3529 34.8754L83.3729 34.8654Z"
						fill="#6B7B81"
					/>
					<path
						d="M111.523 107.255L94.8032 99.0052C90.3532 96.8852 85.0632 96.8852 80.8332 99.4252C74.2732 103.235 66.2232 103.235 59.6632 99.4252L59.2432 99.2152C55.0132 96.8852 49.7232 96.6752 45.2732 98.7952L28.5532 107.055C27.0732 107.695 26.6532 109.385 27.2832 110.865L33.4232 123.145C34.0632 124.625 35.7532 125.055 37.2332 124.415L55.0132 116.155C64.1132 111.925 77.2432 112.135 86.7632 116.365L103.063 124.405C104.543 125.045 106.023 124.615 106.873 123.135L113.013 110.855C113.433 109.585 112.803 107.895 111.533 107.255H111.523Z"
						fill="#C9DADF"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M135.489 40.3284C136.462 41.3095 137.006 42.6371 137 44.019L136.789 95.5338C136.783 96.9157 136.229 98.2387 135.248 99.2119L98.6717 135.489C97.6906 136.462 96.363 137.006 94.9811 137L43.4663 136.789C42.0844 136.783 40.7613 136.229 39.7882 135.248L3.51106 98.6717C2.53791 97.6906 1.99438 96.363 2.00004 94.9811L2.21126 43.4664C2.21693 42.0844 2.77132 40.7614 3.75249 39.7882L40.3283 3.51106C41.3095 2.5379 42.637 1.99438 44.019 2.00004L95.5337 2.21136C96.9156 2.21703 98.2387 2.77142 99.2118 3.75259L135.489 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M79.9318 43.9166C78.8347 42.5081 77.7375 41.0923 76.633 39.8395V39.6838C72.7115 35.1322 71.14 31.8409 70.3542 29.958C70.0429 29.1722 68.9458 28.8609 68.3157 29.6466C64.0829 35.1397 63.6084 38.431 63.6084 41.7298C63.6084 45.3399 70.3542 57.3597 66.312 62.5488C62.2698 67.7379 54.8219 64.0314 54.8219 57.3597C54.8219 50.688 56.2325 48.1568 55.9138 48.1568C55.7581 48.1568 55.6024 48.3125 55.4393 48.6313C50.2651 56.1628 47.5964 62.4416 47.5964 66.9932C47.5964 69.8175 48.2265 72.6418 49.3236 75.3105C50.4208 77.9792 51.9923 80.3291 54.0309 82.3751C56.0694 84.4136 58.4268 85.9852 61.088 87.0823C63.7567 88.1794 66.581 88.8095 69.4054 88.8095C72.2297 88.8095 75.054 88.1794 77.7227 87.0823C80.3914 85.9852 82.7413 84.4136 84.7799 82.3751C86.8184 80.3365 88.39 77.9792 89.4871 75.3105C90.5842 72.6418 91.2143 69.8175 91.2143 66.9932C91.2143 61.5002 87.2928 53.6572 79.9169 43.924L79.9318 43.9166Z"
						fill="#C3855A"
					/>
					<path
						d="M100.8 97.5787L88.4051 91.463C85.1063 89.8914 81.1849 89.8914 78.0492 91.7743C73.1863 94.5986 67.2189 94.5986 62.356 91.7743L62.0446 91.6186C58.9089 89.8914 54.9875 89.7357 51.6887 91.3073L39.2942 97.4304C38.1971 97.9048 37.8858 99.1576 38.3528 100.255L42.9043 109.358C43.3788 110.455 44.6315 110.774 45.7287 110.299L58.9089 104.176C65.6547 101.041 75.3879 101.196 82.4451 104.332L94.5282 110.292C95.6254 110.766 96.7225 110.448 97.3526 109.35L101.904 100.247C102.215 99.3059 101.748 98.0531 100.807 97.5787H100.8Z"
						fill="#E6B293"
					/>
				</svg>
			</>
		);
	}
};
export const ReconstructionIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M53.5544 20.6094C65.9437 20.6094 76.0014 22.9402 84.9071 29.5472C86.8455 30.9053 88.5828 32.658 90.3292 34.2089C94.1968 38.0905 97.8817 41.9813 101.941 45.6702C104.072 47.6156 104.84 49.9465 104.84 52.47C104.84 54.4154 105.032 56.3516 105.425 58.297C106.394 62.1786 109.101 64.5095 112.776 65.0968C113.362 65.0968 113.746 65.0968 114.13 64.904C116.452 63.5459 118.966 63.9313 120.905 65.8768C121.682 66.6567 122.45 67.6203 123.419 68.593C125.55 71.1165 125.742 74.2273 123.611 76.7508C119.744 81.027 115.675 85.1014 111.414 89.1849C109.091 91.323 105.608 91.1303 103.285 88.7995C102.316 87.8268 101.548 86.8541 100.579 85.8906C98.4486 83.5597 98.2566 81.6143 99.8018 79.0908C100.186 78.3108 100.186 77.54 100.186 76.5673C99.4086 72.2911 92.2493 65.4913 85.6661 66.8586C84.5048 67.0513 83.5356 67.6386 82.7676 68.4094C81.6064 69.5749 80.2531 70.7403 79.0919 72.0984C78.5159 72.6857 78.1227 72.8784 77.3547 72.0984C72.7098 67.4367 68.065 62.7751 63.2281 58.1135C62.4509 57.3335 62.4509 56.9481 63.2281 56.1681C64.3893 55.0027 65.5505 53.8373 66.5197 52.6719C69.8114 48.9829 69.6194 44.8994 66.1357 41.4032C59.9456 35.3834 52.3931 31.8872 44.0726 29.7491C40.973 28.9691 37.6814 28.5837 34.3989 28.391C33.4297 28.391 33.2376 28.0056 33.2376 27.2256C33.1096 24.7663 34.1428 23.2767 36.3373 22.7567C42.5274 21.3985 48.7266 20.6186 53.5635 20.6186L53.5544 20.6094Z"
						fill="#6B7B81"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M57.0345 60.0371C54.9709 62.0241 53.2082 63.6511 51.3223 65.3918C47.7495 68.6896 43.7347 72.3953 36.3946 79.7296C32.1348 83.7974 21.3025 94.6298 16.2749 99.6574C14.73 101.202 14.73 103.524 16.2749 105.078L29.0451 118.232C30.59 119.777 32.9118 119.777 34.4658 118.232C41.8608 110.273 62.7941 87.2766 73.1087 75.7951L57.0345 60.0371Z"
						fill="#C9DADF"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M59.669 36.0005C68.1977 36.0005 75.1213 37.605 81.2518 42.1532C82.5862 43.0881 83.7821 44.2946 84.9843 45.3622C87.6467 48.0342 90.1833 50.7126 92.9779 53.252C94.4445 54.5912 94.9732 56.1957 94.9732 57.9329C94.9732 59.272 95.1054 60.6049 95.376 61.9441C96.0432 64.6162 97.9063 66.2207 100.437 66.6249C100.839 66.6249 101.104 66.6249 101.368 66.4923C102.967 65.5574 104.698 65.8227 106.032 67.1619C106.567 67.6988 107.096 68.3621 107.763 69.0317C109.23 70.7688 109.362 72.9103 107.895 74.6474C105.233 77.5911 102.432 80.3958 99.4987 83.2069C97.9 84.6787 95.5019 84.5461 93.9032 82.9416C93.236 82.272 92.7073 81.6024 92.0401 80.9391C90.5736 79.3346 90.4414 77.9954 91.5051 76.2583C91.7695 75.7213 91.7695 75.1907 91.7695 74.5211C91.2345 71.5774 86.3061 66.8966 81.7743 67.8378C80.9749 67.9705 80.3077 68.3747 79.779 68.9054C78.9797 69.7076 78.0481 70.5099 77.2487 71.4448C76.8522 71.849 76.5815 71.9817 76.0528 71.4448C72.8554 68.2358 69.6579 65.0268 66.3283 61.8178C65.7933 61.2808 65.7933 61.0155 66.3283 60.4786C67.1277 59.6763 67.927 58.8741 68.5942 58.0718C70.8601 55.5324 70.728 52.7214 68.3299 50.3146C64.0687 46.1707 58.8697 43.764 53.142 42.2921C51.0082 41.7552 48.7423 41.4899 46.4827 41.3572C45.8155 41.3572 45.6833 41.0919 45.6833 40.555C45.5952 38.8621 46.3064 37.8366 47.8171 37.4786C52.0782 36.5437 56.3457 36.0068 59.6753 36.0068L59.669 36.0005Z"
						fill="#C3855A"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M62.0649 63.1416C60.6443 64.5095 59.4308 65.6295 58.1326 66.8278C55.6731 69.0979 52.9094 71.6488 47.8566 76.6977C44.9242 79.4979 37.4674 86.9547 34.0065 90.4157C32.943 91.4792 32.943 93.0775 34.0065 94.1472L42.7973 103.202C43.8608 104.266 45.4591 104.266 46.5289 103.202C51.6195 97.7235 66.0297 81.8928 73.1301 73.9892L62.0649 63.1416Z"
						fill="#E6B293"
					/>
				</svg>
			</>
		);
	}
};
export const ContentsCleaningIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.7534 38.3352C38.9695 28.9189 50.1009 18.4566 66.2045 18.4566C66.2045 18.4566 109.566 17.4292 112.134 18.4566C114.703 19.484 115.73 25.1345 112.134 27.1892C108.539 29.2439 95.7088 35.1241 86.3651 39.6902L92.8012 58.9982C93.9068 62.3152 89.5094 64.6192 87.4115 61.8221L75.9901 46.5936C73.2894 49.0644 71.6815 52.2481 70.3226 55.7016C69.5501 57.6647 67.6912 58.9982 65.5816 58.9982H56.1195C53.5523 58.9982 51.4918 57.0373 50.9873 54.5201C49.9489 49.3379 47.2894 44.572 38.2006 45.3957C33.9419 45.7816 30.3975 41.9038 32.7534 38.3352Z"
						fill="#6B7B81"
					/>
					<path
						d="M91.4418 121.371H30.0066C26.2651 121.371 23.8492 117.412 25.5599 114.085L49.3568 67.7987C50.2135 66.1323 51.9299 65.0848 53.8035 65.0848H69.4978L95.8439 114C97.638 117.331 95.2254 121.371 91.4418 121.371Z"
						fill="#C9DADF"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M49.8503 49.4783C53.857 43.409 61.0319 36.6654 71.4116 36.6654C71.4116 36.6654 99.3606 36.0032 101.016 36.6654C102.672 37.3276 103.334 40.9697 101.016 42.2941C98.6984 43.6185 90.4289 47.4086 84.4063 50.3517L88.5547 62.7969C89.2674 64.9349 86.433 66.42 85.0808 64.6171L77.719 54.8014C75.9783 56.394 74.9419 58.446 74.066 60.672C73.5681 61.9374 72.3699 62.7969 71.0101 62.7969H64.9112C63.2565 62.7969 61.9284 61.533 61.6032 59.9105C60.9339 56.5702 59.2197 53.4983 53.3614 54.0292C50.6164 54.278 48.3319 51.7785 49.8503 49.4783Z"
						fill="#C3855A"
					/>
					<path
						d="M87.6785 103H48.0799C45.6682 103 44.111 100.448 45.2137 98.3036L60.5522 68.4694C61.1044 67.3954 62.2107 66.7202 63.4184 66.7202H73.5343L90.5159 98.249C91.6724 100.396 90.1173 103 87.6785 103Z"
						fill="#E6B293"
					/>
				</svg>
			</>
		);
	}
};
export const ContentsPackoutIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M71 23L92.5 28C96 29 102 33 102 44L102.5 105L71 116.5V23Z" fill="#6B7B81" />
					<path
						d="M105.5 31.5L118.283 34.5985C118.283 34.5985 125.152 38.0985 125.152 47.5985C125.152 57.0985 125.152 84.5 125.152 84.5L109.5 91.0985C109.5 91.0985 109.5 56.5 109.5 45C109.5 33.5 105.5 31.5 105.5 31.5Z"
						fill="#C9DADF"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M22.9901 34.8306L71 23V116.5L22.57 100.231C20.47 99.6007 19 97.4807 19 95.1507V40.1206C19 37.5806 20.6801 35.4706 22.9901 34.8306ZM47.1641 48.0982L68.3614 67.6642C69.2361 68.4716 69.2162 69.8597 68.3188 70.6417L47.1215 89.1124C45.8275 90.2399 43.8076 89.3209 43.8076 87.6045V77.2902C43.8076 76.1856 42.9122 75.2902 41.8076 75.2902H32C30.8954 75.2902 30 74.3948 30 73.2902V63.4127C30 62.3081 30.8954 61.4127 32 61.4127H41.8076C42.9122 61.4127 43.8076 60.5173 43.8076 59.4127V49.5679C43.8076 47.8244 45.883 46.9157 47.1641 48.0982Z"
						fill="#6B7B81"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M135.489 40.3284C136.462 41.3095 137.006 42.6371 137 44.019L136.789 95.5338C136.783 96.9157 136.229 98.2387 135.248 99.2119L98.6717 135.489C97.6906 136.462 96.363 137.006 94.9811 137L43.4663 136.789C42.0844 136.783 40.7613 136.229 39.7882 135.248L3.51106 98.6717C2.53791 97.6906 1.99438 96.363 2.00004 94.9811L2.21126 43.4664C2.21693 42.0844 2.77132 40.7614 3.75249 39.7882L40.3283 3.51106C41.3095 2.5379 42.637 1.99438 44.019 2.00004L95.5337 2.21136C96.9156 2.21703 98.2387 2.77142 99.2118 3.75259L135.489 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M71.3107 36.5L86.6021 40.0561C89.0914 40.7674 93.3587 43.6123 93.3587 51.4358L93.7144 94.8206L71.3107 103V36.5Z"
						fill="#C3855A"
					/>
					<path
						d="M95.8481 42.5459L104.94 44.7496C104.94 44.7496 109.825 47.2389 109.825 53.9956C109.825 60.7522 109.825 80.241 109.825 80.241L98.693 84.934C98.693 84.934 98.693 60.3266 98.693 52.1475C98.693 43.9684 95.8481 42.5459 95.8481 42.5459Z"
						fill="#E6B293"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M37.1647 44.9143L71.3107 36.5V103L36.866 91.4286C35.3724 90.9805 34.3269 89.4727 34.3269 87.8155V48.6767C34.3269 46.8702 35.5218 45.3695 37.1647 44.9143ZM54.358 54.3507L69.4341 68.2665C70.0562 68.8408 70.0421 69.828 69.4037 70.3842L54.3277 83.5211C53.4073 84.323 51.9707 83.6694 51.9707 82.4486V75.1128C51.9707 74.3272 51.3339 73.6904 50.5483 73.6904H43.5728C42.7872 73.6904 42.1504 73.0535 42.1504 72.2679V65.2428C42.1504 64.4572 42.7872 63.8203 43.5728 63.8203H50.5483C51.3339 63.8203 51.9707 63.1834 51.9707 62.3978V55.3959C51.9707 54.1559 53.4468 53.5096 54.358 54.3507Z"
						fill="#C3855A"
					/>
				</svg>
			</>
		);
	}
};
export const RoofingIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M68.1748 49.8318L29.1748 89.8108C28.8104 90.1844 28.6064 90.6856 28.6064 91.2074V115.5C28.6064 116.605 29.5019 117.5 30.6064 117.5H108.606C109.711 117.5 110.606 116.605 110.606 115.5V91.2074C110.606 90.6856 110.402 90.1844 110.038 89.8108L71.0381 49.8318C70.2532 49.0273 68.9597 49.0273 68.1748 49.8318Z"
						fill="#C9DADF"
					/>
					<path
						d="M13.2748 93.5691C14.0629 94.3431 15.3292 94.3317 16.1031 93.5436L68.1793 40.5207C68.9631 39.7225 70.2492 39.7225 71.0331 40.5207L123.109 93.5436C123.883 94.3317 125.149 94.3431 125.938 93.5691L133.785 85.8613C134.574 85.0873 134.585 83.8211 133.811 83.033L78.5243 26.7411C73.6251 21.7529 65.5872 21.753 60.6881 26.7412L5.40142 83.033C4.62744 83.8211 4.63884 85.0873 5.4269 85.8613L13.2748 93.5691Z"
						fill="#6B7B81"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M111 23C112.105 23 113 23.8954 113 25V65.0342C113 66.676 111.131 67.6184 109.811 66.6425L93.8111 54.8139C93.301 54.4368 93 53.84 93 53.2057V25C93 23.8954 93.8954 23 95 23H111Z"
						fill="#6B7B81"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M69.7193 57.7584L46.8145 81.2381C46.6005 81.4575 46.4807 81.7518 46.4807 82.0583V96.3254C46.4807 96.9741 47.0066 97.5 47.6553 97.5H93.4648C94.1136 97.5 94.6394 96.9741 94.6394 96.3254V82.0583C94.6394 81.7518 94.5196 81.4575 94.3056 81.2381L71.4009 57.7584C70.9399 57.2859 70.1802 57.2859 69.7193 57.7584Z"
						fill="#E6B293"
					/>
					<path
						d="M37.4764 83.4454C37.9392 83.8999 38.6829 83.8932 39.1375 83.4304L69.7219 52.2899C70.1823 51.8212 70.9376 51.8212 71.3979 52.2899L101.982 83.4304C102.437 83.8932 103.181 83.8999 103.643 83.4454L108.253 78.9186C108.715 78.464 108.722 77.7203 108.267 77.2575L75.7975 44.1972C72.9203 41.2676 68.1995 41.2676 65.3223 44.1972L32.8524 77.2575C32.3978 77.7203 32.4045 78.464 32.8673 78.9186L37.4764 83.4454Z"
						fill="#C3855A"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M94.8706 42C95.5193 42 96.0452 42.5259 96.0452 43.1746V66.6868C96.0452 67.651 94.9477 68.2045 94.1724 67.6313L84.7755 60.6843C84.4759 60.4629 84.2992 60.1124 84.2992 59.7398V43.1746C84.2992 42.5259 84.8251 42 85.4738 42H94.8706Z"
						fill="#C3855A"
					/>
				</svg>
			</>
		);
	}
};
export const WaterMitigationIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M70.5 96.0006C87.3447 96.0006 101 82.3453 101 65.5006C101 50.7737 77.6871 27.7836 71.8251 22.2356C71.0778 21.5283 69.9222 21.5283 69.1749 22.2356C63.3129 27.7836 40 50.7737 40 65.5006C40 82.3453 53.6553 96.0006 70.5 96.0006ZM80.8875 42.1497C78.7608 39.4504 74.8485 38.9862 72.1492 41.1129C69.45 43.2396 68.9858 47.1519 71.1125 49.8512C72.7281 51.9018 77.178 58.0153 81.2791 65.1133C82.9982 68.0887 86.804 69.1072 89.7795 67.388C92.755 65.6688 93.7735 61.8631 92.0543 58.8876C87.622 51.2163 82.8274 44.6119 80.8875 42.1497Z"
						fill="#6B7B81"
					/>
					<path
						d="M128 100.915C128 100.094 127.501 99.3557 126.736 99.0588C121.296 96.9493 112.885 94 105.203 94C96.8421 94 91.3284 96.6695 86.8906 98.8181C86.5957 98.9609 86.3055 99.1013 86.0196 99.2386C81.7885 101.27 77.8425 103 70.7026 103C70.5344 103 70.3676 103.006 70.2022 103.016C70.0368 103.006 69.8699 103 69.7017 103C62.5618 103 58.6158 101.27 54.3847 99.2386C54.0988 99.1013 53.8086 98.9609 53.5137 98.8181C49.0758 96.6695 43.5622 94 35.2017 94C27.7962 94 19.7149 96.7401 14.2697 98.8271C13.5014 99.1216 13 99.8609 13 100.684V112.843C13 114.087 14.2492 114.943 15.4092 114.494V114.494C15.8968 114.306 16.3982 114.11 16.9113 113.909C22.5004 111.726 29.4782 109 35.2017 109C40.0729 109 42.8672 110.344 47.3028 112.477C47.4965 112.571 47.6934 112.665 47.8937 112.761C53.0376 115.23 59.3416 118 69.7017 118C69.8699 118 70.0368 117.994 70.2022 117.984C70.3676 117.994 70.5344 118 70.7026 118C81.0627 118 87.3667 115.23 92.5106 112.761C92.7109 112.665 92.9078 112.571 93.1015 112.477C97.5371 110.344 100.331 109 105.203 109C110.926 109 117.904 111.726 123.493 113.909L123.496 113.91C124.008 114.11 124.508 114.306 124.995 114.494L125.278 114.604C126.589 115.111 128 114.144 128 112.739V100.915Z"
						fill="#C9DADF"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M71.4538 88.3969C83.4005 88.3969 93.0852 78.7122 93.0852 66.7655C93.0852 56.3208 76.5511 40.0156 72.3936 36.0808C71.8635 35.5792 71.044 35.5792 70.514 36.0808C66.3565 40.0156 49.8224 56.3208 49.8224 66.7655C49.8224 78.7122 59.5071 88.3969 71.4538 88.3969ZM78.8209 50.2044C77.3126 48.29 74.5379 47.9608 72.6235 49.4691C70.7091 50.9775 70.3799 53.7521 71.8882 55.6665C73.034 57.1209 76.19 61.4568 79.0986 66.4908C80.3179 68.6011 83.017 69.3234 85.1273 68.1041C87.2376 66.8849 87.9599 64.1857 86.7407 62.0754C83.5972 56.6347 80.1967 51.9507 78.8209 50.2044Z"
						fill="#C3855A"
					/>
					<path
						d="M112.234 91.8825C112.234 91.3002 111.88 90.7769 111.338 90.5664C107.48 89.0703 101.515 86.9785 96.0657 86.9785C90.1363 86.9785 86.2258 88.8718 83.0784 90.3956C82.8692 90.4969 82.6634 90.5965 82.4606 90.6939C79.4598 92.1342 76.6612 93.3616 71.5974 93.3616C71.4781 93.3616 71.3598 93.3655 71.2425 93.3732C71.1252 93.3655 71.0068 93.3616 70.8876 93.3616C65.8238 93.3616 63.0251 92.1342 60.0243 90.6939C59.8216 90.5965 59.6158 90.4969 59.4066 90.3956C56.2592 88.8718 52.3487 86.9785 46.4193 86.9785C41.167 86.9785 35.4356 88.9219 31.5737 90.402C31.0288 90.6109 30.6732 91.1352 30.6732 91.7188V100.343C30.6732 101.225 31.5592 101.832 32.3819 101.514C32.7277 101.38 33.0833 101.241 33.4472 101.099C37.4112 99.5501 42.36 97.6169 46.4193 97.6169C49.874 97.6169 51.8558 98.5701 55.0016 100.083C55.139 100.149 55.2787 100.216 55.4208 100.285C59.069 102.036 63.5399 104 70.8876 104C71.0068 104 71.1252 103.996 71.2425 103.988C71.3598 103.996 71.4781 104 71.5974 104C78.9451 104 83.416 102.036 87.0642 100.285C87.2063 100.216 87.3459 100.149 87.4833 100.083C90.6291 98.5701 92.6109 97.6169 96.0657 97.6169C100.125 97.6169 105.074 99.5501 109.038 101.099L109.04 101.099C109.403 101.241 109.758 101.38 110.103 101.514L110.304 101.591C111.233 101.951 112.234 101.265 112.234 100.268V91.8825Z"
						fill="#E6B293"
					/>
				</svg>
			</>
		);
	}
};
export const BoardUpIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M47.5089 64.0613L129.843 42L136.572 67.1141L77.4625 82.9525L47.5089 64.0613ZM116 55.0002H125V61.0002H116V55.0002Z"
						fill="#D9E3E5"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M33.0283 67.9414L12 73.5759L18.7293 98.69L62.9819 86.8325L33.0283 67.9414ZM24 79.0002H33V85.0002H24V79.0002Z"
						fill="#D9E3E5"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M29.61 26.8242L132.824 91.8689L118.962 113.865L15.748 48.8207L29.61 26.8242ZM29 42.0003H38V48.0003H29V42.0003ZM111 93.0003H120V99.0003H111V93.0003Z"
						fill="#6B7B81"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="141" height="140" viewBox="0 0 141 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M136.443 40.3284C137.416 41.3095 137.959 42.6371 137.954 44.019L137.742 95.5338C137.737 96.9157 137.182 98.2387 136.201 99.2119L99.6255 135.489C98.6443 136.462 97.3167 137.006 95.9348 137L44.42 136.789C43.0381 136.783 41.715 136.229 40.7419 135.248L4.4648 98.6717C3.49164 97.6906 2.94811 96.363 2.95378 94.9811L3.165 43.4664C3.17066 42.0844 3.72506 40.7614 4.70622 39.7882L41.282 3.51106C42.2632 2.5379 43.5908 1.99438 44.9727 2.00004L96.4875 2.21136C97.8694 2.21703 99.1924 2.77142 100.166 3.75259L136.443 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M52.8765 66.0358L109.339 50.9067L113.954 68.1292L73.4177 78.9907L52.8765 66.0358ZM42.9461 68.6966L28.5259 72.5605L33.1406 89.783L63.4874 81.6516L42.9461 68.6966ZM99.8458 59.8219H106.018V63.9365H99.8458V59.8219ZM36.7551 76.2804H42.927V80.395H36.7551V76.2804Z"
						fill="#E6B293"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M40.6023 40.4995L111.383 85.1051L101.877 100.19L31.0962 55.584L40.6023 40.4995ZM40.184 50.9068H46.3559V55.0214H40.184V50.9068ZM96.417 85.8809H102.589V89.9955H96.417V85.8809Z"
						fill="#C3855A"
					/>
				</svg>
			</>
		);
	}
};
export const GenericIcon = ({ stopped = false }: { stopped: boolean }) => {
	if (!stopped) {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M52.6763 91.0005C63.6658 99.6763 79.6558 98.9425 89.7994 88.7989C99.943 78.6553 100.677 62.6653 92.001 51.6758L52.6763 91.0005Z"
						fill="#C9DADF"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M70 119C97.6142 119 120 96.6142 120 69C120 41.3858 97.6142 19 70 19C42.3858 19 20 41.3858 20 69C20 96.6142 42.3858 119 70 119ZM69.9998 103.524C89.0667 103.524 104.524 88.0673 104.524 69.0003C104.524 49.9333 89.0667 34.4765 69.9998 34.4765C50.9328 34.4765 35.476 49.9333 35.476 69.0003C35.476 88.0673 50.9328 103.524 69.9998 103.524Z"
						fill="#6B7B81"
					/>
					<path
						d="M85.9489 45.9803C75.0341 38.3996 59.9291 39.4732 50.2012 49.2011C40.4733 58.929 39.3997 74.034 46.9804 84.9487L85.9489 45.9803Z"
						fill="#6B7B81"
					/>
				</svg>
			</>
		);
	} else {
		return (
			<>
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M135.489 40.3284C136.462 41.3095 137.006 42.6371 137 44.019L136.789 95.5338C136.783 96.9157 136.229 98.2387 135.248 99.2119L98.6717 135.489C97.6906 136.462 96.363 137.006 94.9811 137L43.4663 136.789C42.0844 136.783 40.7613 136.229 39.7882 135.248L3.51106 98.6717C2.53791 97.6906 1.99438 96.363 2.00004 94.9811L2.21126 43.4664C2.21693 42.0844 2.77132 40.7614 3.75249 39.7882L40.3283 3.51106C41.3095 2.5379 42.637 1.99438 44.019 2.00004L95.5337 2.21136C96.9156 2.21703 98.2387 2.77142 99.2118 3.75259L135.489 40.3284Z"
						fill="#FFE4D9"
					/>
					<path
						d="M56.7708 85.8003C65.1628 92.4255 77.3733 91.8651 85.1193 84.1191C92.8653 76.3731 93.4257 64.1625 86.8005 55.7705L56.7708 85.8003Z"
						fill="#E6B293"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M69.9999 107.182C91.0872 107.182 108.182 90.0874 108.182 69.0002C108.182 47.9129 91.0872 30.8184 69.9999 30.8184C48.9127 30.8184 31.8181 47.9129 31.8181 69.0002C31.8181 90.0874 48.9127 107.182 69.9999 107.182ZM69.9998 95.364C84.56 95.364 96.3634 83.5606 96.3634 69.0004C96.3634 54.4402 84.56 42.6368 69.9998 42.6368C55.4395 42.6368 43.6361 54.4402 43.6361 69.0004C43.6361 83.5606 55.4395 95.364 69.9998 95.364Z"
						fill="#C3855A"
					/>
					<path
						d="M82.1791 51.4215C73.8442 45.6326 62.3094 46.4524 54.8809 53.881C47.4523 61.3096 46.6324 72.8443 52.4214 81.1792L82.1791 51.4215Z"
						fill="#C3855A"
					/>
				</svg>
			</>
		);
	}
};

export const HistoryIcon = () => (
	<>
		<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M27.2783 60.5674V93.8174C27.2783 104.31 35.7856 112.817 46.2783 112.817H93.7782C104.271 112.817 112.778 104.31 112.778 93.8174V60.5674H27.2783Z"
				fill="#C9DADF"
			/>
			<path
				d="M46.2783 27.3174C46.2783 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2782C84.2782 24.6954 86.4062 22.5674 89.0282 22.5674C91.6502 22.5674 93.7782 24.6954 93.7782 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2783 51.3619V46.3174C27.2783 35.8246 35.7856 27.3174 46.2783 27.3174Z"
				fill="#6B7B81"
			/>
		</svg>
	</>
);

export const ServiceIcon = ({ service, ignoreStatus = false, stopped = false }: ServiceIconProps) => {
	if (!stopped && !ignoreStatus) {
		stopped = isServiceStopped(service);
	}
	switch (service.serviceType) {
		case "Tree Removal":
			return <TreeRemovalIcon stopped={stopped} />;
		case "Fire Mitigation":
			return <FireMitigationIcon stopped={stopped} />;
		case "Reconstruction":
			return <ReconstructionIcon stopped={stopped} />;
		case "Contents Cleaning":
			return <ContentsCleaningIcon stopped={stopped} />;
		case "Contents Packout":
			return <ContentsPackoutIcon stopped={stopped} />;
		case "Roofing":
			return <RoofingIcon stopped={stopped} />;
		case "Water Mitigation":
			return <WaterMitigationIcon stopped={stopped} />;
		case "Board Up":
			return <BoardUpIcon stopped={stopped} />;
		default:
			return <GenericIcon stopped={stopped} />;
	}
};
