import PageIntroStatusCard from "components/PageIntroStatusCard";
import { Service } from "model/dto/service";
import { pullServiceDates, getOnGoingServices } from "utils/serviceUtil";
import { useIntl } from "i18n/Internationalization";

type PageIntroStatusProps = {
	services: Array<Service>;
	tz: string | undefined;
};

const PageIntroStatus = ({ services, tz }: PageIntroStatusProps) => {
	const onGoing = getOnGoingServices(services);

	const intl = useIntl();

	const i18nstrings = {
		"status-cards-as-of-today": intl.formatMessage({
			id: "status-cards-as-of-today",
			defaultMessage: "As of today",
		}),
	};

	if (onGoing.length === 0) {
		return null;
	}

	let statusCards;

	if (services.length === 1) {
		const [service] = services;
		const serviceDates = pullServiceDates(service, tz);
		statusCards = serviceDates.map((serviceDate, idx) => {
			return (
				<PageIntroStatusCard key={`${service.serviceId}-${idx}`} service={service} serviceDate={serviceDate} />
			);
		});
	} else {
		statusCards = services
			.map((service) => {
				const [mostRecent] = pullServiceDates(service, tz);
				return {
					service: service,
					serviceDate: mostRecent,
				};
			})
			.filter(({ serviceDate }) => !!serviceDate)
			.map(({ service, serviceDate }, idx) => {
				return (
					<PageIntroStatusCard
						key={`${service.serviceId}-${idx}`}
						service={service}
						serviceDate={serviceDate}
					/>
				);
			});
	}

	if (statusCards.length === 0) {
		return <></>;
	}

	return (
		<div className="dash-intro__status d-flex gap-20">
			<h3 className="dash-intro__status-title">{i18nstrings["status-cards-as-of-today"]}</h3>
			<div className="dash-intro__cards">{statusCards}</div>
		</div>
	);
};

export default PageIntroStatus;
