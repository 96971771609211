import ServiceCardContentProgress from "components/ServiceCardContentProgress";
import ServiceCardContact from "components/ServiceCardContact";
import ServiceCardContentStopped from "components/ServiceCardContentStopped";
import ServiceCardContentUnknown from "components/ServiceCardContentUnknown";
import ServiceCardContractorDialog from "components/ServiceCardContractorDialog";
import { Service } from "model/dto/service";
import { useState } from "react";
import { useIntl } from "i18n/Internationalization";
import { isValidService, PORTAL_SUBSTATUS_STOPPED } from "utils/serviceUtil";

type ServiceCardProps = {
	service: Service;
	includeProse: boolean;
};

const ServiceCard = ({ service, includeProse }: ServiceCardProps) => {
	const intl = useIntl();
	const substatus = service.portalSubStatus;

	const [showContractorDialog, setShowContractorDialog] = useState(false);

	const cardClasses =
		substatus === PORTAL_SUBSTATUS_STOPPED
			? ["service-card", "service-card--stopped", "card"]
			: ["service-card", "card"];

	let cardContent = <></>;
	if (substatus === PORTAL_SUBSTATUS_STOPPED) {
		cardContent = <ServiceCardContentStopped service={service} includeProse={includeProse} />;
	} else if (!isValidService(service)) {
		cardContent = <ServiceCardContentUnknown service={service} />;
	} else {
		cardContent = <ServiceCardContentProgress service={service} includeProse={includeProse} />;
	}

	const showDialog = () => {
		setShowContractorDialog(true);
	};

	const hideDialog = () => {
		setShowContractorDialog(false);
	};

	const cardActions =
		substatus === PORTAL_SUBSTATUS_STOPPED || !service.assignedContractor ? (
			<></>
		) : (
			<div className="service-card__actions d-flex align-items-center gap-1">
				{intl.formatMessage({ id: "service-card-contractor-info-label", defaultMessage: "View:" })}
				<button onClick={showDialog} className="button secondary">
					{intl.formatMessage({ id: "service-card-contractor-info", defaultMessage: "Contractor Info" })}
				</button>
			</div>
		);

	return (
		<>
			<div className={cardClasses.join(" ")}>
				<div className="service-card__inner d-flex">
					<div className="service-card__content d-flex flex-column">{cardContent}</div>

					<ServiceCardContact service={service} />

					{cardActions}
				</div>
			</div>
			<ServiceCardContractorDialog service={service} visible={showContractorDialog} close={hideDialog} />
		</>
	);
};

export default ServiceCard;
