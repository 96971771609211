import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";

export const SubtradeCodec = t.type({
	subtradeId: UUID,
	serviceId: UUID,
	subtradeType: t.union([t.null, t.undefined, t.string]),
});

export type Subtrade = t.TypeOf<typeof SubtradeCodec>;
