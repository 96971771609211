import * as t from "io-ts";
import { ContactMethodCodec } from "model/dto/contact-method";

export const ActivationResultCodec = t.type({
	validated: t.boolean,
	contactMethods: t.array(ContactMethodCodec),
	coreAuthUrl: t.union([t.undefined, t.null, t.string]),
	carrierName: t.union([t.undefined, t.null, t.string]),
	lossDate: t.union([t.undefined, t.null, t.string]),
	firstName: t.union([t.undefined, t.null, t.string]),
	fullName: t.union([t.undefined, t.null, t.string]),
});

export type ActivationResult = t.TypeOf<typeof ActivationResultCodec>;
