import { PrimaryDialog } from "components/PrimaryDialog";
import { Sequencer, SequencerConfig, SequencerGroup, SequencerStepGrouped } from "components/Sequencer";
import { renderToString } from "react-dom/server";
import {
	faceIcons,
	detailIconsContractorGood,
	detailIconsContractorBad,
	detailIconsAccuserveGood,
	detailIconsAccuserveBad,
} from "components/FeedbackIcons";
import { IconSelectField, IconSelectList } from "components/IconSelectList";
import { TextField, FieldTextArea } from "components/FieldTextArea";
import { FieldSaver } from "model/types/Fields";
import { ContentTitle } from "components/ContentTitle";
import { ContentTitleHelperText } from "components/ContentTitleHelperText";
import { Feedback, FeedbackValue } from "contexts/FeedbackContext";
import { useContext } from "react";
import { PortalContext } from "contexts/PortalContext";
import { useIntl } from "i18n/Internationalization";
import useProse, { FeedbackSentiment } from "utils/prose";
import { ACCUSERVE_SUBJECT, CONTRACTOR_SUBJECT, OTHER_SUBJECT } from "model/dto/feedback-response";

type PromptedFeedbackGroupLabel = "Contractor" | "Accuserve" | "Wrap-up";

type PromptedFeedbackGroup = SequencerGroup & {
	label: PromptedFeedbackGroupLabel;
};

type PromptedFeedbackDialogProps = {
	visible: boolean;
	saveValue: FieldSaver<FeedbackValue>;
	feedback: Feedback;
	close: () => void;
	submitFeedback: () => void;
};

export const PromptedFeedbackDialog = ({
	feedback,
	saveValue,
	visible,
	close,
	submitFeedback,
}: PromptedFeedbackDialogProps) => {
	const { portal } = useContext(PortalContext);
	const intl = useIntl();
	const prose = useProse(intl);
	const isStaffMessage = portal?.isStaff ? (
		<>
			<div className="primary-dialog__content--warning">
				{intl.formatMessage({
					id: "feedback-modal-staff-message",
					defaultMessage: "Feedback submitted by staff is not saved",
				})}
			</div>
		</>
	) : (
		<></>
	);

	const getPromptedFeedbackSteps = (
		saveValue: FieldSaver<FeedbackValue>,
		feedback: Feedback
	): SequencerStepGrouped<PromptedFeedbackGroup>[] => {
		const accuSentiment = Object.keys(feedback.accuserve_overall)[0] as FeedbackSentiment;
		const contractorSentiment = Object.keys(feedback.contractor_overall)[0] as FeedbackSentiment;

		const contentTitles = {
			contractor_overall: intl.formatMessageWithElements(
				{
					id: "feedback-modal-experience-with-contractor",
					defaultMessage: "How has your experience been with your contractor?",
				},
				{
					subject: (
						<u>
							{intl.formatMessage({
								id: "feedback-modal-contractor-subject",
								defaultMessage: "contractor",
							})}
						</u>
					),
				}
			),
			contractor_details: prose.getFeedbackSentimentQuestion(contractorSentiment, "contractor"),
			accuserve_overall: intl.formatMessageWithElements(
				{
					id: "feedback-modal-experience-with-accuserve",
					defaultMessage: "How has your experience been with Accuserve?",
				},
				{
					subject: (
						<u>
							{intl.formatMessage({
								id: "feedback-modal-accuserve-subject",
								defaultMessage: "Accuserve",
							})}
						</u>
					),
				}
			),
			accuserve_details: prose.getFeedbackSentimentQuestion(accuSentiment, "accuserve"),
			additional_feedback: intl.formatMessage({
				id: "feedback-modal-care-to-share",
				defaultMessage: "Finally, care to share anything else?",
			}),
			thank_you: intl.formatMessage({
				id: "feedback-modal-thank-you",
				defaultMessage: "Thank you!",
			}),
		};

		return [
			{
				terminal: false,
				show: true,
				group: "Contractor",
				onComplete: () => {
					// Save the question information into the feedback object for later use for submission
					saveValue("contractor_overall", {
						...feedback.contractor_overall,
						question: renderToString(contentTitles.contractor_overall),
						subject: CONTRACTOR_SUBJECT,
						section: "contractor_overall",
					});
				},
				allowProceed: () => {
					return (
						feedback.contractor_overall &&
						Object.keys(feedback.contractor_overall).some((key) => !!feedback.contractor_overall[key])
					);
				},
				onBack: () => {
					saveValue("contractor_overall", {});
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.contractor_overall}</ContentTitle>
						<IconSelectList
							selectType="icon"
							size="small"
							field={
								{
									name: "contractor_overall",
									value: feedback["contractor_overall"],
									saveValue: saveValue,
									isMulti: false,
									items: faceIcons,
								} as IconSelectField
							}
						/>
					</>
				),
			},
			{
				terminal: false,
				show: !feedback.contractor_overall.okay,
				group: "Contractor",
				onComplete: () => {
					// Save the question information into the feedback object for later use for submission
					saveValue("contractor_details", {
						...feedback.contractor_details,
						question: contentTitles.contractor_details,
						subject: CONTRACTOR_SUBJECT,
					});
				},
				allowProceed: () => true,
				onBack: () => {
					saveValue("contractor_details", {});
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.contractor_details}</ContentTitle>
						<ContentTitleHelperText>
							{intl.formatMessage({
								id: "feedback-modal-select-as-many",
								defaultMessage: "Select as many as you want",
							})}
						</ContentTitleHelperText>
						<IconSelectList
							selectType="icon"
							field={
								{
									name: "contractor_details",
									value: feedback["contractor_details"],
									saveValue: saveValue,
									isMulti: true,
									items: feedback.contractor_overall.good
										? detailIconsContractorGood
										: detailIconsContractorBad,
								} as IconSelectField
							}
						/>
					</>
				),
			},
			{
				terminal: false,
				show: true,
				group: "Accuserve",
				onComplete: () => {
					// Save the question information into the feedback object for later use for submission
					saveValue("accuserve_overall", {
						...feedback.accuserve_overall,
						question: renderToString(contentTitles.accuserve_overall),
						subject: ACCUSERVE_SUBJECT,
						section: "accuserve_overall",
					});
				},
				allowProceed: () => {
					return (
						feedback.accuserve_overall &&
						Object.keys(feedback.accuserve_overall).some((key) => !!feedback.accuserve_overall[key])
					);
				},
				onBack: () => {
					saveValue("accuserve_overall", {});
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.accuserve_overall}</ContentTitle>
						<IconSelectList
							selectType="icon"
							size="small"
							field={
								{
									name: "accuserve_overall",
									value: feedback["accuserve_overall"],
									saveValue: saveValue,
									isMulti: false,
									items: faceIcons,
								} as IconSelectField
							}
						/>
					</>
				),
			},
			{
				terminal: false,
				show: !feedback.accuserve_overall.okay,
				group: "Accuserve",
				allowProceed: () => true,
				onComplete: () => {
					// Save the question information into the feedback object for later use for submission
					saveValue("accuserve_details", {
						...feedback.accuserve_details,
						question: contentTitles.accuserve_details,
						subject: ACCUSERVE_SUBJECT,
						section: "accuserve_details",
					});
				},
				onBack: () => {
					saveValue("accuserve_details", {});
				},
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.accuserve_details}</ContentTitle>
						<ContentTitleHelperText>
							{intl.formatMessage({
								id: "feedback-modal-select-as-many",
								defaultMessage: "Select as many as you want",
							})}
						</ContentTitleHelperText>
						<IconSelectList
							selectType="icon"
							field={
								{
									name: "accuserve_details",
									value: feedback["accuserve_details"],
									saveValue: saveValue,
									isMulti: true,
									items: feedback.accuserve_overall.good
										? detailIconsAccuserveGood
										: detailIconsAccuserveBad,
								} as IconSelectField
							}
						/>
					</>
				),
			},
			{
				terminal: false,
				show: true,
				group: "Wrap-up",
				onComplete: () => {
					// Save the question information into the feedback object for later use for submissio
					saveValue("additional_feedback", {
						...feedback.additional_feedback,
						textKey: "additional_feedback",
						question: contentTitles.additional_feedback,
						subject: OTHER_SUBJECT,
						section: "additional_feedback",
					});
					submitFeedback();
				},
				onBack: () => {
					saveValue("additional_feedback", {});
				},
				buttonNext: intl.formatMessage({
					id: "feedback-modal-submit",
					defaultMessage: "Submit",
				}) as PromptedFeedbackGroupLabel,
				element: (
					<>
						{isStaffMessage}
						<ContentTitle>{contentTitles.additional_feedback}</ContentTitle>
						<FieldTextArea
							field={
								{
									name: "additional_feedback",
									value: feedback["additional_feedback"],
									saveValue: saveValue,
								} as TextField
							}
						/>
					</>
				),
			},
			{
				show: true,
				terminal: true,
				group: "Wrap-up",
				element: (
					<>
						<ContentTitle>{contentTitles.thank_you}</ContentTitle>
					</>
				),
			},
		];
	};

	const getPromptedFeedbackGroups = (feedback: Feedback): PromptedFeedbackGroup[] => {
		return [
			{
				label: intl.formatMessage({
					id: "feedback-modal-contractor",
					defaultMessage: "Contractor",
				}) as PromptedFeedbackGroupLabel,
				show: true,
			},
			{
				label: intl.formatMessage({
					id: "feedback-modal-accuserve",
					defaultMessage: "Accuserve",
				}) as PromptedFeedbackGroupLabel,
				show: true,
			},
			{
				label: intl.formatMessage({
					id: "feedback-modal-wrap-up",
					defaultMessage: "Wrap-up",
				}) as PromptedFeedbackGroupLabel,
				show: false,
			},
		];
	};
	const sequencerConfig: SequencerConfig<SequencerStepGrouped<PromptedFeedbackGroup>> = {
		steps: getPromptedFeedbackSteps(saveValue, feedback),
		groups: getPromptedFeedbackGroups(feedback),
		onComplete: close,
	};

	return (
		<PrimaryDialog show={visible} close={close}>
			<Sequencer config={sequencerConfig} />
		</PrimaryDialog>
	);
};
