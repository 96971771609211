import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { ContactMethodCodec } from "model/dto/contact-method";

export const ContractorEmployeeCodec = t.type({
	contactId: UUID,
	contractorId: UUID,
	contractorEmployeeId: UUID,
	contactMethods: t.array(ContactMethodCodec),
	firstName: t.union([t.null, t.undefined, t.string]),
	fullName: t.union([t.null, t.undefined, t.string]),
	lastName: t.union([t.null, t.undefined, t.string]),
	preferredContactMethod: t.union([t.null, t.undefined, t.string]),
	preferredName: t.union([t.null, t.undefined, t.string]),
});

export type ContractorEmployee = t.TypeOf<typeof ContractorEmployeeCodec>;
