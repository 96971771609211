import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "contexts/ThemeContext";
import { TranslationsWrapper } from "i18n/TranslationsWrapper";
import { FatalErrorBoundary } from "pages/error/FatalErrorBoundary";
import AppRoutes from "routes";

const App = () => {
	const env = process.env as any;

	return (
		<HelmetProvider>
			<Helmet
				titleTemplate={env.REACT_APP_APPLICATION_NAME + ": %s"}
				defaultTitle={env.REACT_APP_APPLICATION_NAME}
			/>
			<ThemeProvider>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<FatalErrorBoundary>
						<TranslationsWrapper>
							<AppRoutes />
						</TranslationsWrapper>
					</FatalErrorBoundary>
				</BrowserRouter>
			</ThemeProvider>
		</HelmetProvider>
	);
};

export default App;
