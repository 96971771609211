import { ReactNode } from "react";

export type ToastAction = {
	label: string;
	action: () => void;
};

export type ToastConfig = {
	title: ReactNode;
	show: boolean;
	primaryAction: ToastAction;
	secondaryAction: ToastAction;
};

export const Toast = ({ title, show, primaryAction, secondaryAction }: ToastConfig) => {
	const revealClass = show ? "primary-toast--revealed" : "";

	return (
		<div className={`primary-toast ${revealClass}`} role="dialog">
			<div className="primary-toast__content">
				<div className="primary-toast__content--title">{title}</div>
				<div className="primary-toast__content--actions">
					<button
						className="button secondary"
						onClick={primaryAction.action}
						aria-label={primaryAction.label}
					>
						{primaryAction.label}
					</button>
					<button className="button" onClick={secondaryAction.action} aria-label={secondaryAction.label}>
						{secondaryAction.label}
					</button>
				</div>
			</div>
		</div>
	);
};
