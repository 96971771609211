import Loader from "components/Loader";
import PrimaryFooter from "components/PrimaryFooter";
import PrimaryHeader from "components/PrimaryHeader";
import PrimarySidebar from "components/PrimarySidebar";
import ErrorLayout from "layouts/ErrorLayout";
import PrimaryContent from "layouts/PrimaryContent";
import FatalError from "pages/error/FatalError";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { PortalContext } from "contexts/PortalContext";
import { prepareOtherPortals } from "utils/portal";
import { useIntl } from "i18n/Internationalization";

const PortalTerms = () => {
	const { portal, portalList, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	const intl = useIntl();
	useEffect(() => {
		// ScrollToOptions type is bugged until TS 5.1 where "instant" is properly added
		const scrollToOptions = { top: 0, left: 0, behavior: "instant" } as unknown as ScrollToOptions;
		window.scrollTo(scrollToOptions);
	}, []);

	if (error) {
		return (
			<ErrorLayout>
				<FatalError />
			</ErrorLayout>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || !portalList || isLoading) {
		return <Loader></Loader>;
	}

	const otherPortalsList = prepareOtherPortals(portalList, publicId);

	const i18nstrings = {
		"terms-page-back-to-dashboard": intl.formatMessage({
			id: "terms-page-back-to-dashboard",
			defaultMessage: "Back to dashboard",
		}),
	};

	return (
		<>
			<Helmet />
			<PrimaryHeader
				publicId={publicId}
				claim={portal.claim}
				otherPortalsList={otherPortalsList}
				hideHistory={false}
			/>
			<PrimaryContent>
				<div className="g-section">
					<div className="g-layout-sidebar">
						<Link
							className="back-btn"
							to={`/${publicId}`}
							title={i18nstrings["terms-page-back-to-dashboard"]}
							aria-label={i18nstrings["terms-page-back-to-dashboard"]}
						>
							{i18nstrings["terms-page-back-to-dashboard"]}
						</Link>
					</div>
				</div>

				<div className="g-section">
					<div className="g-layout-sidebar">
						<div className="copy-content">
							<div className="copy-content--title">
								<h1>Terms of Service</h1>
							</div>
							<div className="copy-content--card">
								<p className="copy-content--updated">Last updated: Feb 3, 2022 12:05 PM</p>

								<p>
									<em>
										Please read this Terms of Service ("Terms," "Terms of Service") carefully before
										using the www.accuserve.com Website (the "Website") operated by Accuserve
										Solutions, Inc, a(n) Corporation formed in Delaware, United States ("us," "we,"
										"our") as this Terms of Service contains important information regarding
										limitations of our liability. Your access to and use of this Website is
										conditional upon your acceptance of and compliance with these Terms. These Terms
										apply to everyone, including but not limited to visitors, users and others, who
										wish to access and use the Website.
									</em>
								</p>
								<p>
									<em>
										By accessing or using the Website, you agree to be bound by these Terms. If you
										disagree with any part of the Terms, then you do not have our permission to
										access or use the Website.
									</em>
								</p>

								<strong>
									<em>Our offers and pricing</em>
								</strong>
								<p>
									<em>
										We offer services on this Website. The price of these services is customized
										based on your needs, which means that we will provide you with a quote after we
										determine your needs. This price includes all applicable taxes and other fees.
									</em>
								</p>

								<strong>
									<em>Cancellation policy</em>
								</strong>
								<p>
									<em>
										We do not offer you the ability to cancel any purchases that you have made of
										the services offered on our Website.
									</em>
								</p>
								<p>
									<em>
										We reserve the right to cancel your purchase for any reason, at our sole
										discretion, including but not limited to fraud, inaccuracies, and unavailability
										of the items or services purchased. We will let you know immediately if we plan
										on cancelling your purchase.
									</em>
								</p>
								<p>
									<em>
										We will issue a partial refund of the purchase price that you paid if we cancel
										your purchase.
									</em>
								</p>

								<strong>
									<em>Refund policy</em>
								</strong>
								<p>
									<em>We do not offer refunds on any purchases made on this Website.</em>
								</p>

								<h2>Warranty on Purchases</h2>
								<p>SERVICES WILL BE PERFORMED WITH REASONABLE CARE AND SKILL;</p>

								<ul>
									<li>SERVICES WILL BE PERFORMED WITH REASONABLE CARE AND SKILL;</li>
									<li>SERVICES WILL BE PERFORMED WITHIN A REASONABLE TIME.</li>
								</ul>

								<p>
									EXCEPT AS STATED ABOVE, NO OTHER WARRANTY, EXPRESS OR IMPLIED (INCLUDING ANY IMPLIED
									WARRANTY OF MERCHANTABILITY, OF SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR
									PURPOSE OR USE) SHALL APPLY TO THE SERVICES DISPLAYED OR SOLD ON THIS WEBSITE,
									WHETHER ARISING BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR
									OTHERWISE. WARRANTY TIME FRAMES VARY BY TYPES OF SERVICE PERFORMED. FOR MORE
									INFORMATION, PLEASE REFER TO YOUR AGREEMENT.
								</p>

								<strong>Remedies</strong>
								<p>
									You agree that the remedies for breach of this Terms of Service as it relates to
									your purchase shall be:
								</p>
								<ul>
									<li>repair;</li>
									<li>replacement.</li>
								</ul>
								<p>
									You also agree that the remedy for breach of this Terms of Service as it relates to
									your purchase shall be to pursue dispute resolution as provided in the “governing
									law, severability, dispute resolution, venue and class action waiver” section below.
									These remedies intended to be your sole and exclusive remedies for any breach of
									this Terms of Service as it relates to your purchase.
								</p>

								<strong>Prohibited uses</strong>
								<p>
									You agree that you will use this Website in accordance with all applicable laws,
									rules, regulations and these Terms at all times. The following is a non-exhaustive
									list of prohibited uses of this Website. You agree that you will not perform any of
									the following prohibited uses:
								</p>

								<ol>
									<li>
										Impersonating or attempting to impersonate Accuserve Solutions, Inc or its
										employees, representatives, subsidiaries or divisions;
									</li>
									<li>Misrepresenting your identity or affiliation with any person or entity;</li>
									<li>
										Sending or attempting to send any advertising or promotional material, including
										but not limited to spam, junk mail, chain mail or any similar material;
									</li>
									<li>
										Engaging in any conduct that restricts or inhibits any person’s use or enjoyment
										of the Website, or which, as determined in our sole discretion, may harm us or
										the users of this Website or expose us or other users to liability;
									</li>
									<li>
										Using the Website in any manner that could disable, overburden, damage or impair
										the Website or interfere with another party’s use of the Website;
									</li>
									<li>
										Using any robot, spider or other similar automatic technology, process or means
										to access or use the Website for any purpose, including monitoring or copying
										any of the material on this Website;
									</li>
									<li>
										Using any manual process or means to monitor or copy any of the material on this
										Website or for any other unauthorized purpose;
									</li>
									<li>
										Using any device, software, means or routine that interferes with the proper
										working of the Website, including but not limited to viruses, trojan horses,
										worms, logic bombs or other such materials;
									</li>
									<li>
										Attempting to gain unauthorized access to, interfering with, damaging or
										disrupting any parts of the Website, the server(s) on which the Website is
										stored, or any server, computer or database connected to the Website;
									</li>
									<li>
										Attempting to attack or attacking the Website via a denial-of-service attack or
										a distributed denial-of-service attack;
									</li>
									<li>Otherwise attempting to interfere with the proper working of the Website;</li>
									<li>
										Using the Website in any way that violates any applicable federal, state or
										local laws, rules or regulations.
									</li>
								</ol>

								<h3>No Warranty On Website</h3>
								<p>
									THIS WEBSITE IS PROVIDED “AS IS,” NO WARRANTY, EXPRESS OR IMPLIED (INCLUDING ANY
									IMPLIED WARRANTY OF MERCHANTABILITY, OF SATISFACTORY QUALITY OR FITNESS FOR A
									PARTICULAR PURPOSE OR USE) SHALL APPLY TO THIS WEBSITE, WHETHER ARISING BY LAW,
									COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE OR OTHERWISE.
								</p>

								<strong>Availability, errors and inaccuracies</strong>
								<p>
									We assume no liability for the availability, errors or inaccuracies of the
									information, products or services provided on this Website. We may experience delays
									in updating information on this Website and in our advertising on other websites.
									The information, products and services found on the Website may contain errors or
									inaccuracies or may not be complete or current. Products or services may be
									incorrectly priced or unavailable. We expressly reserve the right to correct any
									pricing errors on our Website. The inclusion or offering of any product or service
									on this Website does not constitute an endorsement or recommendation of such product
									or service by us.
								</p>

								<h3>Damages And Limitation Of Liability</h3>
								<p>
									IN NO EVENT SHALL ACCUSERVE SOLUTIONS, INC BE LIABLE FOR ANY DIRECT, INDIRECT,
									PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF, RELATING TO
									OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO, DISPLAY OF OR USE OF THIS WEBSITE OR
									WITH THE DELAY OR INABILITY TO ACCESS, DISPLAY OR USE THIS WEBSITE, INCLUDING BUT
									NOT LIMITED TO YOUR RELIANCE UPON OPINIONS OR INFORMATION APPEARING ON THIS WEBSITE;
									ANY COMPUTER VIRUSES, INFORMATION, SOFTWARE, LINKED WEBSITES OPERATED BY THIRD
									PARTIES, PRODUCTS OR SERVICES OBTAINED THROUGH THIS WEBSITE, WHETHER BASED ON A
									THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, CONSUMER PROTECTION STATUTES
									OR OTHERWISE, EVEN IF ACCUSERVE SOLUTIONS, INC HAS BEEN ADVISED OF THE POSSIBILITY
									OF SUCH DAMAGES.
								</p>
								<p>
									THIS LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK BETWEEN YOU AND US. THE
									LIMITATIONS SPECIFIED IN THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED
									REMEDY SPECIFIED IN THESE TERMS OF USE IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
									PURPOSE. THE LIMITATIONS OF LIABILITY PROVIDED IN THESE TERMS OF USE INURE TO THE
									BENEFIT OF ACCUSERVE SOLUTIONS, INC. THE AGGREGATE LIABILITY OF ACCUSERVE SOLUTIONS,
									INC. ARISING OUT OF OR RELATING TO THIS WEBSITE, WHETHER ARISING OUT OF OR RELATED
									TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED TO
									THE AMOUNT OF FEES ACTUALLY RECEIVED BY ACCUSERVE SOLUTIONS, INC BY YOU.
								</p>

								<strong>Links to third party websites</strong>
								<p>
									This Website may contain hyperlinks to websites operated by third parties and not by
									us. We provide such hyperlinks for your reference only. We do not control such
									websites and are not responsible for their contents or the privacy or other
									practices of such websites. Further, it is your responsibility to take precautions
									to ensure that whatever links you click on or software that you download, whether
									from this Website or other websites or applications, is free of such items as
									viruses, worms, trojan horses, defects and other items of a destructive nature. Our
									inclusion of hyperlinks to such websites does not imply any endorsement of the
									material on such websites or any association with their operators.
								</p>

								<strong>
									Intellectual property and DMCA notice and procedure for intellectual property
									infringement claims
								</strong>
								<p>
									All contents of this Website are ©2021 - 2022 Accuserve Solutions, Inc or third
									parties. All rights reserved. Unless specified otherwise, this Website and all
									content and other materials on this Website including but not limited to all logos,
									designs, text, graphics, pictures, information, data, software, sound files and
									arrangement thereof (collectively, “Content”) are the proprietary property of
									Accuserve Solutions, Inc and are either registered trademarks, trademarks or
									otherwise protected intellectual property of Accuserve Solutions, Inc or third
									parties in the United States and/or other countries.
								</p>
								<p>
									If you are aware of a potential infringement of our intellectual property, please
									contact marketing@accuserve.com.
								</p>
								<p>
									We respect the intellectual property rights of others. It is our policy to respond
									to any claim that Content posted on the Website infringes on the copyright,
									trademark or other intellectual property rights of any person or entity.
								</p>
								<p>
									If you believe in good faith that the Content infringes on your intellectual
									property rights, you or your agent may send us a written notice of such infringement
									titled “Infringement of Intellectual Property Rights - DMCA.” Your notice to us must
									include the following information:
								</p>

								<ol>
									<li>
										An electronic or physical signature of the person authorized to act on behalf of
										the owner of the intellectual property right’s interest;
									</li>
									<li>
										A description of the work that you claim has been infringed, including the URL
										(i.e., web page address) of the location where the work exists or a copy of the
										work;
									</li>
									<li>Your name, email, address and telephone number; and</li>
									<li>
										A statement by you that you have a good faith belief that the disputed use is
										not authorized by the owner of the work, its agent or the law.
									</li>
								</ol>

								<p>
									Please note that we will not process your complaint if it is not properly filled out
									or is incomplete. You may be held accountable for damages, including but not limited
									to costs and attorneys’ fees for any misrepresentation or bad faith claims regarding
									the infringement of your intellectual property rights by the Content on this
									Website.
								</p>
								<p>You may submit your claim to us by contacting us at:</p>

								<p>
									Accuserve Solutions, Inc
									<br />
									<a href="mailto:marketing@accuserve.com">marketing@accuserve.com</a>
									<br />
									<a href="tel:937-328-4300">937-328-4300</a>
								</p>

								<p>UNITED STATES</p>

								<p>
									<strong>Governing law, severability, dispute resolution and venue</strong>
								</p>
								<p>
									These Terms shall be governed and construed in accordance with the laws of the state
									of Delaware, United States, without regard to its conflict of laws provisions. THESE
									TERMS SHALL NOT BE GOVERNED BY THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE
									SALE OF INTERNATIONAL GOODS, THE UNIFORM COMMERCIAL CODE, NOR INCOTERMS.
								</p>
								<p>
									Our failure to enforce any right or provision of these Terms will not be considered
									a waiver of that right or provision. If any provision of these Terms is held to be
									invalid or unenforceable by a court, the remaining provisions of these Terms will
									remain in effect. These Terms constitute the entire agreement between you and us
									regarding our Website, and supersede and replace any prior agreements we might have
									had with you regarding the Website.
								</p>
								<p>
									Any controversy or claim arising out of or relating to these Terms including but not
									limited to the interpretation or breach thereof shall be resolved in a court of
									competent jurisdiction in County, Delaware.
								</p>
								<p>
									YOU AND ACCUSERVE SOLUTIONS, INC AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
									ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
									ANY CLASS OR REPRESENTATIVE ACTION.
								</p>
								<p>
									We reserve the right to make changes to these Terms of Service at any time. We will
									not provide you with any notice when we make changes to this Terms of Service.
								</p>

								<p>
									<strong>Questions</strong>
								</p>
								<p>
									If you have any questions about our Terms of Service, please contact us at{" "}
									<a href="mailto:info@accuserve.com">info@accuserve.com</a>.
								</p>
							</div>
						</div>

						<PrimarySidebar
							publicId={publicId}
							claim={portal.claim}
							isAbsolute={true}
							otherPortalsList={null}
							hideHistory={false}
						/>
					</div>
				</div>
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalTerms;
