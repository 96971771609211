import Centered from "layouts/Centered";
import { AppPermission } from "model/app-permission";
import ActivatePage from "pages/activate/ActivatePage";
import ErrorLayout from "layouts/ErrorLayout";
import Page404 from "pages/error/Page404";
import Page500 from "pages/error/Page500";
import LandingPage from "pages/LandingPage";
import PortalPage from "pages/portal/PortalPage";
import RedirectPage from "pages/RedirectPage";
import StaticTestPage from "pages/StaticTestPage";
import { ReactNode } from "react";
import { Outlet, RouteObject } from "react-router-dom";

export interface PathTree extends Omit<RouteObject, "children" | "id"> {
	id?: symbol;
	title?: string;
	icon?: ReactNode;
	requiresAll?: AppPermission[];
	requiresAny?: AppPermission[];
	excludeFromRoutes?: boolean;
	children?: PathTree[];
}

/**
 * Any app path that needs to be referenced as a constant
 * from code should be added to this list and set as an
 * id in the AppPath structure.
 */
export const Path = {
	ERROR_404: Symbol("ERROR_404"),
	ERROR_500: Symbol("ERROR_500"),
};

/**
 * The routes for this application.
 */
export const AppPathTrees: PathTree[] = [
	{
		element: <Outlet />,
		children: [
			{
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <LandingPage />,
					},
					{
						path: ":publicId",
						children: [
							{
								index: true,
								element: <PortalPage contentType={"dashboard"} />,
							},
							{
								path: "activate",
								element: <ActivatePage />,
							},
							{
								path: "history",
								element: <PortalPage contentType={"history"} />,
							},
							{
								path: "privacy-policy",
								element: <PortalPage contentType={"privacy"} />,
							},
							{
								path: "terms-conditions",
								element: <PortalPage contentType={"terms"} />,
							},
						],
					},
				],
			},

			{
				path: "redirect/*",
				element: <RedirectPage />,
			},

			{
				element: <Outlet />,
				children: [
					{
						id: Path.ERROR_404,
						path: "error/404",
						element: (
							<ErrorLayout>
								<Page404 />
							</ErrorLayout>
						),
					},
					{
						id: Path.ERROR_500,
						path: "error/500",
						element: (
							<ErrorLayout>
								<Page500 />
							</ErrorLayout>
						),
					},
				],
			},

			{
				path: "/static",
				element: <StaticTestPage />,
			},

			{
				path: "*",
				element: (
					<Centered vertical={true}>
						<Page404 />
					</Centered>
				),
			},
		],
	},
];

export const CoreApiPath = {
	AUTH: {
		PING: "/auth/ping",
		ACTIVATE: "/auth/activate",
		PORTAL_LIST: "/auth/portal-list",
		SEND_VALIDATION_CODE: "/auth/send-validation-code",
		CHECK_VALIDATION_CODE: "/auth/check-validation-code",
		GET_AUTH_STATE: "/auth/auth-state",
		LOGOUT: "/auth/logout",
	},
	PORTAL: "/portal",
	EXPLICIT_LANGUAGE_CHANGE: "/language",
	FEEDBACK: "/feedback",
	PROMPT_SHOWN: "/prompt-shown",
};

/**
 * Transforms the AppPathTree to the react router data structure.
 */
export const getRouteObjects = (children: PathTree[] | undefined): RouteObject[] => {
	if (!children) {
		return [];
	}

	const ros: RouteObject[] = children
		.filter((tree) => !tree.excludeFromRoutes)
		.map((tree) => {
			if (tree.index) {
				return {
					...tree,
					id: tree.id?.toString(),
					index: true,
					children: undefined,
				};
			} else {
				return {
					...tree,
					id: tree.id?.toString(),
					index: false,
					children: getRouteObjects(tree.children),
				};
			}
		});

	return ros;
};

/**
 * Gets the singe path node for the specified path.
 */
export const getAppPathTree = (pathId: symbol): PathTree => {
	const trees = getAppPathTrees(pathId);
	return trees[trees.length - 1];
};

/**
 * Gets this path node, plus all parent nodes. Parent nodes are first.
 */
export const getAppPathTrees = (pathId: symbol): PathTree[] => {
	const trees = findPathTrees(pathId, AppPathTrees);
	if (!trees.length) {
		throw new Error("Undefined AppPath for symbol: " + pathId.toString());
	}
	return trees;
};

/**
 * Gets the relative path (excludes parent paths) for this node.
 */
export const getRelativeAppPath = (pathId: symbol): string => {
	const trees = getAppPathTrees(pathId);
	return trees[trees.length - 1].path || "";
};

/**
 * Gets the full path (includes all parent paths) for this node.
 */
export const getFullAppPath = (pathId: symbol): string => {
	return findPathTrees(pathId, AppPathTrees)
		.filter((tree) => !!tree.path)
		.map((tree) => tree.path)
		.join("/");
};

const findPathTrees = (id: symbol, children: PathTree[]): PathTree[] => {
	let trees: PathTree[] = [];
	children.find((childTree) => {
		if (childTree.id === id) {
			trees = [childTree];
			return true;
		}
		if (childTree.children) {
			const foundTrees = findPathTrees(id, childTree.children);
			if (foundTrees.length) {
				trees = [childTree, ...foundTrees];
				return true;
			}
		}
		return false;
	});
	return trees;
};
