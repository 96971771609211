import HistoryCardList from "components/HistoryCardList";
import Loader from "components/Loader";
import PrimaryHeader from "components/PrimaryHeader";
import { PortalContext } from "contexts/PortalContext";
import PrimaryContent from "layouts/PrimaryContent";
import PrimaryFooter from "components/PrimaryFooter";
import FatalError from "pages/error/FatalError";
import ErrorLayout from "layouts/ErrorLayout";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";

const PortalHistory = () => {
	const { portal, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	if (error) {
		return (
			<ErrorLayout>
				<FatalError />
			</ErrorLayout>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || isLoading) {
		return <Loader></Loader>;
	}

	return (
		<>
			<Helmet />
			<PrimaryHeader publicId={publicId} claim={portal.claim} otherPortalsList={null} hideHistory={true} />
			<PrimaryContent>
				<HistoryCardList publicId={publicId} claim={portal.claim} />
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalHistory;
