import { PortalInfo } from "model/dto/portal-info";
import { PortalList } from "model/dto/portal-list";

/**
 * Prepares other portals list for use in the display
 *
 * @param portalList the portal list to prepare
 * @param publicId the ID of the current portal
 * @returns a sorted list of other portals/claims with the current
 * portal excluded.
 */
export const prepareOtherPortals = (portalList: PortalList, publicId: string | undefined): Array<PortalInfo> => {
	return portalList.portals
		.filter((p) => p.publicId !== publicId)
		.sort((a, b) => {
			const aDate = a.lossDate ? new Date(a.lossDate) : new Date(0);
			const bDate = b.lossDate ? new Date(b.lossDate) : new Date(0);

			return aDate.getTime() < bDate.getTime() ? 1 : aDate.getTime() > bDate.getTime() ? -1 : 0;
		});
};
