import { Service } from "model/dto/service";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";
import { ServiceIcon } from "components/ServiceIcons";

type ServiceCardContentUnknownProps = {
	service: Service;
};

const ServiceCardContentUnknown = ({ service }: ServiceCardContentUnknownProps) => {
	const intl = useIntl();
	const { getTranslatedService } = useProse(intl);
	const translatedService = getTranslatedService(service.serviceType);

	const i18nstrings = {
		"service-card-unknown": intl.formatMessageWithElements(
			{
				id: "service-card-unknown",
				defaultMessage: "Please check back soon for an update on the status of your {serviceName} service.",
			},
			{
				serviceName: <strong>{translatedService}</strong>,
			}
		),
		"service-card-unknown-status": intl.formatMessage({
			id: "service-card-unknown-status",
			defaultMessage: "service status",
		}),
	};

	return (
		<>
			<div className="d-flex flex-wrap">
				<h2 className="service-card__title col-12">{i18nstrings["service-card-unknown-status"]}</h2>

				<div className="service-card__icon flex-shrink-1">
					<ServiceIcon service={service} />
				</div>

				<div className="service-card__copy col align-self-center">
					<p>{i18nstrings["service-card-unknown"]}</p>
				</div>
			</div>
		</>
	);
};

export default ServiceCardContentUnknown;
