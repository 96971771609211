import * as t from "io-ts";

export const StatusTypeCodec = t.union([
	t.literal("Assigning Service"),
	t.literal("Assessing & Planning"),
	t.literal("Service Complete"),
	t.literal("Service Stopped"),
	t.literal("Service In-Progress"),
]);

export type StatusType = t.TypeOf<typeof StatusTypeCodec>;
