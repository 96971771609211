import PrimaryFooterSimple from "components/PrimaryFooterSimple";
import PrimaryHeaderSimple from "components/PrimaryHeaderSimple";

const ErrorLayout = ({ children }) => {
	return (
		<>
			<PrimaryHeaderSimple />
			<main className="main" id="main-content">
				{children}
			</main>
			<PrimaryFooterSimple />
		</>
	);
};

export default ErrorLayout;
