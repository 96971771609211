export const TREE_REMOVAL = [
	{
		type: "Tree Removal",
		state: "N/A",
		prose: ["", "", ""],
	},
	{
		type: "Tree Removal",
		state: "Accuserve Assigned",
		prose: [
			"Your claim has been received.",
			"Accuserve has received your tree removal claim and is reaching out to schedule an inspection of your property.",
			"A contractor will be assigned shortly.",
		],
	},
	{
		type: "Tree Removal",
		state: "Assigning Contractor",
		prose: [
			"A contractor has been assigned to your claim.",
			"We are locating a [service] contractor who will inspect your property.",
			"The contractor will inspect your property.",
		],
	},
	{
		type: "Tree Removal",
		state: "Awaiting Inspection",
		prose: [
			"The contractor has inspected your property, and has determined an estimate for the work.",
			"A [service] contractor has been assigned and the next step is for them to inspect your property.",
			"The contractor will inspect your property at the scheduled appointment time to determine an estimate for the work.",
		],
	},
	{
		type: "Tree Removal",
		state: "Awaiting Work Authorization",
		prose: [
			"Thank you for signing the work authorization!",
			"Good news! The [service] estimate is approved by your insurance provider. Your signature is now needed to authorize the work.",
			"Once you sign a work authorization, the work will be scheduled.",
		],
	},
	{
		type: "Tree Removal",
		state: "Tree Removal Planning",
		prose: [
			"Your service has been planned.",
			"We are working with the contractor to plan and schedule your [service].",
			"We will reach out to you with the plan shortly.",
		],
	},
	{
		type: "Tree Removal",
		state: "Tree Removal Scheduled",
		prose: [
			"The service has been planned and scheduled.",
			"We have a target start date for your [service] service.",
			"Once we determine a plan and schedule, we will provide a target start date.",
		],
	},
	{
		type: "Tree Removal",
		state: "Tree Removal In Progress",
		prose: [
			"Accuserve has been working with the contractor to complete the service at your property.",
			"Accuserve is working with the contractor to complete [service] at your property.",
			"Accuserve will work with the contractor until the service at your property is complete.",
		],
	},
	{
		type: "Tree Removal",
		state: "Awaiting COS",
		prose: [
			"Thank you for signing that you are satisfied with the completed service!",
			"Now that the [service] is complete, we are reaching out to confirm that you are satisfied with the work.",
			"Once the service is complete, we will reach out to confirm that you are satisfied with the work.",
		],
	},
	{
		type: "Tree Removal",
		state: "Service Complete",
		prose: [
			"",
			"It has been a pleasure working to complete the tree removal at your property. If other services are required within this claim, we will continue that work.",
			"The service will be complete after you confirm you are satisfied.",
		],
	},
	{
		type: "Tree Removal",
		state: "Accuserve Not Handling Service",
		terminal: true,
		prose: [
			"The service is no longer stopped.",
			"Accuserve is not currently managing the tree removal service at your property. If anything changes and you would like our help, please contact us.",
			"",
		],
	},
];
