import { Service } from "model/dto/service";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";
import { ServiceIcon } from "components/ServiceIcons";

type ServiceCardContentStoppedProps = {
	service: Service;
	includeProse: boolean;
};

const ServiceCardContentStopped = ({ service, includeProse }: ServiceCardContentStoppedProps) => {
	const intl = useIntl();
	const { getTranslatedService } = useProse(intl);

	const translatedService = getTranslatedService(service.serviceType);

	const i18nstrings = {
		"service-card-progress-stopped": intl.formatMessageWithElements(
			{
				id: "service-card-progress-stopped",
				defaultMessage: "Service is stopped",
			},
			{
				serviceName: <strong>{translatedService}</strong>,
			}
		),
	};

	return (
		<div className="service-card__content-inner d-flex gap-20">
			<div className="service-card__icon flex-shrink-1">
				{/** @todo This need to be verified to confirm in is the right behavior,
				 * the icon may not be in stopped state this way, before it was retrieved as stopped */}
				<ServiceIcon service={service} />
			</div>

			<div className="service-card__description">
				<h2 className="service-card__title w-100">{i18nstrings["service-card-progress-stopped"]}</h2>
			</div>
		</div>
	);
};

export default ServiceCardContentStopped;
