import { Service } from "model/dto/service";
import { ServiceIcon } from "components/ServiceIcons";
import { ServiceDate } from "utils/serviceUtil";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";

type PageIntroStatusCardProps = {
	service: Service;
	serviceDate: ServiceDate;
};

const PageIntroStatusCard = ({ service, serviceDate }: PageIntroStatusCardProps) => {
	const intl = useIntl();
	const { getTranslatedService } = useProse(intl);

	if (!serviceDate) {
		return <></>;
	}

	const i18nstrings = {
		"status-card-expected-to-start": intl.formatMessage(
			{
				id: "status-card-expected-to-start",
				defaultMessage: "{serviceName} is expected to start",
			},
			{
				serviceName: getTranslatedService(service.serviceType),
			}
		),
		"status-card-was-completed-on": intl.formatMessage(
			{
				id: "status-card-was-completed-on",
				defaultMessage: "{serviceName} was completed on",
			},
			{
				serviceName: getTranslatedService(service.serviceType),
			}
		),
		"status-card-appointment-set-for": intl.formatMessage(
			{
				id: "status-card-appointment-set-for",
				defaultMessage: "{serviceName} inspection appointment is set for",
			},
			{
				serviceName: getTranslatedService(service.serviceType),
			}
		),
	};

	let statusText;
	if (serviceDate.type === "targetJobStartDatetime") {
		statusText = (
			<>
				{i18nstrings["status-card-expected-to-start"]} <strong>{serviceDate.prettyDatetime}*</strong>
			</>
		);
	} else if (serviceDate.type === "jobCompletionDateTime") {
		statusText = (
			<>
				{i18nstrings["status-card-was-completed-on"]} <strong>{serviceDate.prettyDatetime}</strong>
			</>
		);
	} else if (serviceDate.type === "inspectionAppointmentDatetime") {
		statusText = (
			<>
				{i18nstrings["status-card-appointment-set-for"]} <strong>{serviceDate.prettyDatetime}</strong>
			</>
		);
	} else {
		statusText = <></>;
	}

	return (
		<div className="dash-intro__card card d-flex align-items-center">
			<div className="d-flex align-items-center gap-1">
				<div className="card__icon">
					<ServiceIcon ignoreStatus={true} service={service} />
				</div>
				<div className="card__copy">{statusText}</div>
			</div>
		</div>
	);
};

export default PageIntroStatusCard;
