import { FieldItem } from "model/types/Fields";
import { useIntl } from "i18n/Internationalization";

export type FeedbackIconProps = {
	labelId?: string;
};

export const FeedbackGood = ({ labelId = "feedback-modal-good" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Good" });
	return (
		<>
			<div className="option-icon--primary">
				<svg width="120" height="121" viewBox="0 0 120 121" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M60 120.5C93.1371 120.5 120 93.6371 120 60.5C120 27.3629 93.1371 0.5 60 0.5C26.8629 0.5 0 27.3629 0 60.5C0 93.6371 26.8629 120.5 60 120.5ZM60 111.5C88.1665 111.5 111 88.6665 111 60.5C111 32.3335 88.1665 9.5 60 9.5C31.8335 9.5 9 32.3335 9 60.5C9 88.6665 31.8335 111.5 60 111.5Z"
						fill="#6DDBAD"
						fill-opacity="0.5"
					/>
					<circle cx="60" cy="60.5" r="43.5789" fill="#53CE9B" />
					<path
						d="M74.2106 68.5C77.4673 68.5 79.0839 71.9905 76.5496 74.0358C72.6508 77.1822 66.8073 80.5 59.5 80.5C52.1927 80.5 46.3492 77.1822 42.4504 74.0358C39.9161 71.9905 41.5327 68.5 44.7894 68.5H74.2106Z"
						fill="#DDFFF1"
					/>
					<path
						d="M42 49.5C42 46.7386 44.2386 44.5 47 44.5C49.7614 44.5 52 46.7386 52 49.5V51.5714C52 52.6365 51.1366 53.5 50.0714 53.5H43.9286C42.8635 53.5 42 52.6365 42 51.5714V49.5Z"
						fill="#DDFFF1"
					/>
					<path
						d="M68 49.5C68 46.7386 70.2386 44.5 73 44.5C75.7614 44.5 78 46.7386 78 49.5V51.5714C78 52.6365 77.1366 53.5 76.0714 53.5H69.9286C68.8635 53.5 68 52.6365 68 51.5714V49.5Z"
						fill="#DDFFF1"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackOkay = ({ labelId = "feedback-modal-just-okay" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Just Okay" });
	return (
		<>
			<div className="option-icon--primary">
				<svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120ZM60 111C88.1665 111 111 88.1665 111 60C111 31.8335 88.1665 9 60 9C31.8335 9 9 31.8335 9 60C9 88.1665 31.8335 111 60 111Z"
						fill="#C9DADF"
					/>
					<circle cx="60" cy="60" r="43.5789" fill="#6B7B81" />
					<path
						d="M42 49C42 46.2386 44.2386 44 47 44C49.7614 44 52 46.2386 52 49V54.2143C52 55.7528 50.7528 57 49.2143 57H44.7857C43.2472 57 42 55.7528 42 54.2143L42 49Z"
						fill="#F1FCFF"
					/>
					<path
						d="M68 49C68 46.2386 70.2386 44 73 44C75.7614 44 78 46.2386 78 49V54.2143C78 55.7528 76.7528 57 75.2143 57H70.7857C69.2472 57 68 55.7528 68 54.2143V49Z"
						fill="#F1FCFF"
					/>
					<path
						d="M74 72C75.6569 72 77 73.3431 77 75C77 76.6569 75.6569 78 74 78H45C43.3431 78 42 76.6569 42 75C42 73.3431 43.3431 72 45 72H74Z"
						fill="#F1FCFF"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBad = ({ labelId = "feedback-modal-not-good" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Bad" });
	return (
		<>
			<div className="option-icon--primary">
				<svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120ZM60 111C88.1665 111 111 88.1665 111 60C111 31.8335 88.1665 9 60 9C31.8335 9 9 31.8335 9 60C9 88.1665 31.8335 111 60 111Z"
						fill="#FECCCC"
					/>
					<circle cx="60" cy="60" r="43.5789" fill="#FC5555" />
					<path
						d="M42 44C42 41.2386 44.2386 39 47 39C49.7614 39 52 41.2386 52 44V57C52 58.6569 50.6569 60 49 60H45C43.3431 60 42 58.6569 42 57V44Z"
						fill="#FCDCDC"
					/>
					<path
						d="M78 44C78 41.2386 75.7614 39 73 39C70.2386 39 68 41.2386 68 44V57C68 58.6569 69.3431 60 71 60H75C76.6569 60 78 58.6569 78 57V44Z"
						fill="#FCDCDC"
					/>
					<path
						d="M73.8368 79C76.3385 79 77.523 76.2951 75.5408 74.7689C71.9976 72.0409 66.4822 69 59.5 69C52.5178 69 47.0024 72.0409 43.4592 74.7689C41.477 76.2951 42.6616 79 45.1632 79H73.8368Z"
						fill="#FECCCC"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackClearExpectations = ({
	labelId = "feedback-modal-contractor-positive-expectations",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Clear expectations and schedule",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.2783 93.8174V60.5674H112.778V93.8174C112.778 104.31 104.271 112.817 93.7782 112.817H46.2783C35.7856 112.817 27.2783 104.31 27.2783 93.8174ZM85.9606 77.8852C86.3423 77.4964 86.3425 76.8736 85.9611 76.4846L81.9516 72.3951C81.5629 71.9986 80.9255 71.9948 80.532 72.3866L67.3803 85.4807C67.003 85.8563 66.3976 85.8705 66.0031 85.5129L61.5259 81.4548C61.1117 81.0793 60.4701 81.1164 60.1018 81.5371L56.3456 85.8284C55.9831 86.2426 56.0235 86.8719 56.4359 87.2364L66.6192 96.2349C67.0178 96.5871 67.6224 96.5657 67.995 96.1861L85.9606 77.8852Z"
						fill="#6B7B81"
					/>
					<path
						d="M46.2784 27.3174C46.2784 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2783C84.2783 24.6954 86.4063 22.5674 89.0283 22.5674C91.6503 22.5674 93.7783 24.6954 93.7783 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2784 51.3619V46.3174C27.2784 35.8246 35.7856 27.3174 46.2784 27.3174Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackOnTime = ({ labelId = "feedback-modal-contractor-positive-on-time" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "On time" });
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M74.5453 110.091C82.5644 110.091 90.4035 107.713 97.0711 103.258C103.739 98.8024 108.936 92.4701 112.004 85.0614C115.073 77.6527 115.876 69.5004 114.312 61.6354C112.747 53.7703 108.886 46.5458 103.215 40.8755C97.5449 35.2051 90.3204 31.3435 82.4553 29.7791C74.5903 28.2146 66.438 29.0176 59.0293 32.0863C51.9946 35.0002 45.9303 39.8327 41.5221 46.0212C51.6309 49.0402 58.9999 58.4095 58.9999 69.5C58.9999 80.6022 51.6153 89.9797 41.4899 92.9884C42.7996 94.8339 44.2697 96.5783 45.891 98.1997C53.4917 105.8 63.7964 110.077 74.5453 110.091ZM80.4338 48.0195C80.4338 44.7058 77.7475 42.0195 74.4338 42.0195C71.1201 42.0195 68.4338 44.7058 68.4338 48.0195V77.1435L82.1365 91.2067C84.449 93.5801 88.2477 93.6294 90.621 91.3169C92.9944 89.0044 93.0437 85.2057 90.7312 82.8323L80.4338 72.264V48.0195Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M34.5 86C43.6127 86 51 78.6127 51 69.5C51 60.3873 43.6127 53 34.5 53C25.3873 53 18 60.3873 18 69.5C18 78.6127 25.3873 86 34.5 86ZM43.6127 66.1466C43.9944 65.7578 43.9946 65.135 43.6132 64.746L41.8972 62.9958C41.5084 62.5993 40.8711 62.5954 40.4776 62.9872L33.4987 69.9354C33.1215 70.311 32.5161 70.3252 32.1216 69.9677L30.1642 68.1935C29.75 67.8181 29.1084 67.8551 28.7402 68.2758L27.1416 70.1021C26.7791 70.5163 26.8194 71.1456 27.2319 71.5101L32.4731 76.1415C32.8717 76.4937 33.4762 76.4723 33.8489 76.0927L43.6127 66.1466Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackGoodWorkmanship = ({
	labelId = "feedback-modal-contractor-positive-work-quality",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "High quality workmanship",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.8135 106.432L42.0453 84.6444C42.7085 83.0793 44.8981 83.0025 45.6692 84.5174L51.309 95.597C51.7709 96.5043 52.8405 96.9199 53.7937 96.5624L68.1199 91.1901L54.6 117.385C53.7484 119.035 51.3034 118.735 50.8764 116.928L48.6934 107.689C48.4345 106.594 47.3232 105.928 46.2352 106.216L35.1668 109.146C33.5592 109.571 32.1647 107.963 32.8135 106.432Z"
						fill="#6B7B81"
					/>
					<path
						d="M108.552 105.432L99.32 83.6444C98.6568 82.0793 96.4672 82.0025 95.6961 83.5174L90.0562 94.597C89.5944 95.5043 88.5248 95.9199 87.5716 95.5624L73.2454 90.1901L86.7653 116.385C87.6169 118.035 90.0619 117.735 90.4889 115.928L92.6719 106.689C92.9307 105.594 94.0421 104.928 95.1301 105.216L106.199 108.146C107.806 108.571 109.201 106.963 108.552 105.432Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M73.3783 21.7214C71.0838 19.6163 67.5603 19.6163 65.2658 21.7214L60.2016 26.3676C58.9198 27.5436 57.1907 28.1054 55.4625 27.9074L48.6346 27.1252C45.541 26.7708 42.6904 28.8419 42.0714 31.8936L40.7054 38.6291C40.3596 40.3339 39.291 41.8048 37.7765 42.6604L31.7928 46.041C29.0817 47.5726 27.9929 50.9237 29.2859 53.7564L32.1397 60.0084C32.8621 61.5909 32.8621 63.409 32.1397 64.9915L29.2859 71.2435C27.9929 74.0762 29.0817 77.4273 31.7928 78.9589L37.7765 82.3395C39.291 83.1951 40.3596 84.666 40.7054 86.3708L42.3327 94.3944C42.833 96.8612 44.8198 98.7536 47.3081 99.1332L59.2352 100.953C61.373 101.279 63.4772 100.189 64.4443 98.2549C65.2885 96.5665 67.0142 95.4999 68.9019 95.4999H73.1445C75.0814 95.4999 76.8416 96.6262 77.6533 98.3848C78.6283 100.497 80.9392 101.65 83.2132 101.157L91.6473 99.3308C93.975 98.8268 95.7843 96.9936 96.2577 94.6594L97.9387 86.3708C98.2844 84.666 99.3531 83.1951 100.868 82.3395L106.851 78.9589C109.562 77.4273 110.651 74.0762 109.358 71.2435L106.504 64.9915C105.782 63.409 105.782 61.5909 106.504 60.0084L109.358 53.7564C110.651 50.9237 109.562 47.5726 106.851 46.041L100.868 42.6604C99.3531 41.8048 98.2844 40.3339 97.9387 38.6291L96.5726 31.8936C95.9537 28.8419 93.1031 26.7708 90.0095 27.1252L83.1816 27.9074C81.4533 28.1054 79.7242 27.5436 78.4424 26.3676L73.3783 21.7214ZM88.8402 53.8066C89.2219 53.4178 89.2221 52.795 88.8407 52.4059L83.5435 47.0031C83.1548 46.6066 82.5174 46.6028 82.1239 46.9946L65.5063 63.5393C65.1291 63.9149 64.5237 63.9291 64.1292 63.5715L58.2372 58.231C57.823 57.8555 57.1814 57.8926 56.8131 58.3133L51.8455 63.9887C51.483 64.4029 51.5233 65.0322 51.9358 65.3967L64.8939 76.8471C65.2925 77.1993 65.8971 77.1778 66.2697 76.7983L88.8402 53.8066Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackSafe = ({ labelId = "feedback-modal-contractor-positive-workspace-tidy" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Workspace safe and tidy",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M68.9874 31.689C69.3392 30.7547 70.6608 30.7547 71.0126 31.689L77.6573 49.3365C77.7686 49.632 78.0034 49.8641 78.3002 49.9721L96.2037 56.4827C97.1534 56.8281 97.1534 58.1712 96.2037 58.5165L78.3002 65.0272C78.0034 65.1351 77.7686 65.3673 77.6573 65.6628L71.0126 83.3102C70.6608 84.2446 69.3392 84.2446 68.9874 83.3102L62.3427 65.6628C62.2314 65.3673 61.9966 65.1351 61.6998 65.0272L43.7963 58.5165C42.8466 58.1712 42.8466 56.8281 43.7963 56.4827L61.6998 49.9721C61.9966 49.8641 62.2314 49.632 62.3427 49.3365L68.9874 31.689Z"
						fill="#6B7B81"
					/>
					<path
						d="M107.182 37.742C107.531 36.8 108.863 36.8 109.212 37.742L110.509 41.2462C110.618 41.5424 110.852 41.7759 111.148 41.8855L114.652 43.1821C115.594 43.5307 115.594 44.8631 114.652 45.2117L111.148 46.5083C110.852 46.6179 110.618 46.8514 110.509 47.1476L109.212 50.6518C108.863 51.5938 107.531 51.5938 107.182 50.6518L105.886 47.1476C105.776 46.8514 105.543 46.6179 105.247 46.5083L101.742 45.2117C100.8 44.8631 100.8 43.5307 101.742 43.1821L105.247 41.8855C105.543 41.7759 105.776 41.5424 105.886 41.2462L107.182 37.742Z"
						fill="#6B7B81"
					/>
					<path
						d="M31.1825 37.742C31.5311 36.8 32.8635 36.8 33.2121 37.742L34.5087 41.2462C34.6183 41.5424 34.8518 41.7759 35.148 41.8855L38.6521 43.1821C39.5942 43.5307 39.5942 44.8631 38.6521 45.2117L35.148 46.5083C34.8518 46.6179 34.6183 46.8514 34.5087 47.1476L33.2121 50.6518C32.8635 51.5938 31.5311 51.5938 31.1825 50.6518L29.8858 47.1476C29.7762 46.8514 29.5427 46.6179 29.2466 46.5083L25.7424 45.2117C24.8004 44.8631 24.8004 43.5307 25.7424 43.1821L29.2466 41.8855C29.5427 41.7759 29.7762 41.5424 29.8858 41.2462L31.1825 37.742Z"
						fill="#6B7B81"
					/>
					<path
						d="M69.2793 93.0249C81.4296 93.0249 91.2793 83.1751 91.2793 71.0249C91.2793 68.61 90.8902 66.2859 90.1713 64.112L111.247 73.9316C114.776 75.5758 115.155 80.444 111.923 82.6146L72.39 109.167C70.6997 110.302 68.4898 110.299 66.8027 109.159L26.7283 82.0787C23.4798 79.8835 23.9065 74.9739 27.4851 73.3722L48.4248 63.9996C47.6819 66.2057 47.2793 68.5683 47.2793 71.0249C47.2793 83.1751 57.129 93.0249 69.2793 93.0249Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackProfessional = ({
	labelId = "feedback-modal-contractor-positive-professional",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Professional",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M109.974 78.9604C109.974 99.969 92.0769 117 70.0001 117C47.9232 117 30.0264 99.969 30.0264 78.9604C30.0264 78.6587 30.0301 78.3579 30.0374 78.0579C37.9612 79.8896 51.1762 82.0005 70 82.0005C88.8239 82.0005 102.039 79.8896 109.963 78.0579C109.97 78.3579 109.974 78.6587 109.974 78.9604ZM87.5496 97.5358C90.084 95.4905 88.4673 92 85.2106 92H55.7894C52.5327 92 50.9161 95.4905 53.4504 97.5358C57.3492 100.682 63.1927 104 70.5 104C77.8073 104 83.6508 100.682 87.5496 97.5358Z"
						fill="#6B7B81"
					/>
					<path
						d="M70.0001 40.9209C51.1308 40.9209 35.3151 53.3624 31.1177 70.0953H108.882C104.685 53.3624 88.8694 40.9209 70.0001 40.9209Z"
						fill="#6B7B81"
					/>
					<path
						d="M85.4736 26.9708V43.5H94.4995V31.6789C101.893 36.7362 107.551 44.0209 110.434 52.5263H119V69.4507C119 69.4507 103.25 74 70 74C36.75 74 21 69.4507 21 69.4507V52.5263H29.5664C32.6147 43.5331 38.7648 35.9047 46.7895 30.831V43.5001H55.8154L55.8158 19H85.4724V26.9703L85.4736 26.9708Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackGoodCommunication = ({
	labelId = "feedback-modal-contractor-positive-communication",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Excellent communication",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M37 58H26.6552C24.6365 58 23 59.6365 23 61.6552V105.163C23 107.188 25.5101 108.131 26.8436 106.607L29.5837 103.476C30.2777 102.683 31.2804 102.228 32.3345 102.228H86.9655C88.9842 102.228 90.6207 100.591 90.6207 98.5724V90H42C39.2386 90 37 87.7614 37 85V58Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M119.5 88.2821V38.2069C119.5 35.8835 117.616 34 115.293 34H45.8793C43.5558 34 41.6724 35.8835 41.6724 38.2069V80.6965C41.6724 83.02 43.5559 84.9034 45.8793 84.9034H108.757C109.97 84.9034 111.124 85.4271 111.923 86.3401L115.076 89.9443C116.611 91.6983 119.5 90.6128 119.5 88.2821ZM65.2941 63.5882C68.218 63.5882 70.5882 61.218 70.5882 58.2941C70.5882 55.3703 68.218 53 65.2941 53C62.3703 53 60 55.3703 60 58.2941C60 61.218 62.3703 63.5882 65.2941 63.5882ZM86.1766 58.2941C86.1766 61.218 83.8064 63.5882 80.8825 63.5882C77.9586 63.5882 75.5884 61.218 75.5884 58.2941C75.5884 55.3703 77.9586 53 80.8825 53C83.8064 53 86.1766 55.3703 86.1766 58.2941ZM96.4709 63.5882C99.3947 63.5882 101.765 61.218 101.765 58.2941C101.765 55.3703 99.3947 53 96.4709 53C93.547 53 91.1768 55.3703 91.1768 58.2941C91.1768 61.218 93.547 63.5882 96.4709 63.5882Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackUnclearExpectations = ({
	labelId = "feedback-modal-contractor-negative-expectations",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Unclear expectations and/or schedule",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M71.5512 88.5637H67.3689V87.7398C67.3422 86.0664 68.0348 84.9336 69.793 83.6206C71.125 82.5908 71.4447 82.0501 71.4447 81.252C71.4447 80.2737 70.752 79.5786 69.6066 79.5786C68.3012 79.5786 67.502 80.3509 67.2889 81.7154L66.8627 81.9214L63 81.4065C63.4795 78.0339 66.0635 76 69.873 76C73.5225 76 76 78.0081 76 80.8659C76 83.0027 75.1742 84.3157 72.9365 85.7832C71.8709 86.5298 71.5779 86.9932 71.5512 87.9201V88.5637Z"
						fill="#6B7B81"
					/>
					<path
						d="M69.5799 95C68.0615 95 66.9959 93.9444 66.9959 92.5799C66.9959 91.2154 68.0881 90.1341 69.5799 90.1341C71.0984 90.1341 72.1906 91.2154 72.1906 92.5799C72.1906 93.9444 71.0984 95 69.5799 95Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.2783 93.8174V60.5674H112.778V93.8174C112.778 104.31 104.271 112.817 93.7782 112.817H46.2783C35.7856 112.817 27.2783 104.31 27.2783 93.8174ZM86 85.5C86 94.6127 78.6127 102 69.5 102C60.3873 102 53 94.6127 53 85.5C53 76.3873 60.3873 69 69.5 69C78.6127 69 86 76.3873 86 85.5Z"
						fill="#6B7B81"
					/>
					<path
						d="M46.2784 27.3174C46.2784 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2783C84.2783 24.6954 86.4063 22.5674 89.0283 22.5674C91.6503 22.5674 93.7783 24.6954 93.7783 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2784 51.3619V46.3174C27.2784 35.8246 35.7856 27.3174 46.2784 27.3174Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackLate = ({ labelId = "feedback-modal-contractor-negative-on-time" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Late or no-show to appointments",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M78.1117 110.091C86.1309 110.091 93.9699 107.713 100.638 103.258C107.305 98.8024 112.502 92.4701 115.571 85.0614C118.64 77.6527 119.442 69.5004 117.878 61.6354C116.314 53.7703 112.452 46.5458 106.782 40.8755C101.111 35.2051 93.8868 31.3435 86.0218 29.7791C78.1567 28.2146 70.0044 29.0176 62.5957 32.0863C56.7431 34.5106 51.5622 38.2628 47.4477 43.019H53C55.2091 43.019 57 44.8099 57 47.019C57 49.2282 55.2091 51.019 53 51.019H42.0465C40.7341 53.5738 39.6995 56.256 38.9567 59.019H45C47.2091 59.019 49 60.8099 49 63.019C49 65.2282 47.2091 67.019 45 67.019H37.6452C37.5928 67.8584 37.5664 68.7009 37.5664 69.5454C37.5688 71.3867 37.6963 73.2151 37.9442 75.019H42C44.2091 75.019 46 76.8099 46 79.019C46 81.2282 44.2091 83.019 42 83.019H39.8861C41.8857 88.677 45.1336 93.8758 49.4575 98.1997C57.0581 105.8 67.3628 110.077 78.1117 110.091ZM84.0002 48.0195C84.0002 44.7058 81.314 42.0195 78.0002 42.0195C74.6865 42.0195 72.0002 44.7058 72.0002 48.0195V77.1435L85.7029 91.2067C88.0154 93.5801 91.8141 93.6294 94.1874 91.3169C96.5608 89.0044 96.6101 85.2057 94.2976 82.8323L84.0002 72.264V48.0195Z"
						fill="#6B7B81"
					/>
					<path
						d="M35 55.019C35 52.8099 36.7909 51.019 39 51.019H58C60.2091 51.019 62 52.8099 62 55.019C62 57.2282 60.2091 59.019 58 59.019H39C36.7909 59.019 35 57.2282 35 55.019Z"
						fill="#6B7B81"
					/>
					<path
						d="M24 55.019C24 52.8099 25.7909 51.019 28 51.019C30.2091 51.019 32 52.8099 32 55.019C32 57.2282 30.2091 59.019 28 59.019C25.7909 59.019 24 57.2282 24 55.019Z"
						fill="#6B7B81"
					/>
					<path
						d="M43 39.019C43 36.8099 44.7909 35.019 47 35.019H55.5264C56.4829 35.019 57.0725 34.9246 57.8829 34.4165C61.0717 32.4174 64.8579 35.8744 63.4601 39.3688L63.1948 40.0319C62.4732 41.836 60.7259 43.019 58.7828 43.019H47C44.7909 43.019 43 41.2282 43 39.019Z"
						fill="#6B7B81"
					/>
					<path
						d="M22 71.019C22 68.8099 23.7909 67.019 26 67.019H58C60.2091 67.019 62 68.8099 62 71.019C62 73.2282 60.2091 75.019 58 75.019H26C23.7909 75.019 22 73.2282 22 71.019Z"
						fill="#6B7B81"
					/>
					<path
						d="M34 87.019C34 84.8099 35.7909 83.019 38 83.019H53.7144C58.2904 83.019 62 86.7286 62 91.3046V92.8093C62 98.7233 55.4513 102.29 50.4832 99.0811C50.1616 98.8734 49.8546 98.6414 49.5717 98.3832C47.9688 96.9204 46.8891 95.7089 45.9024 94.2551C44.6047 92.343 42.5553 91.019 40.2445 91.019H38C35.7909 91.019 34 89.2282 34 87.019Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBadWorkmanship = ({
	labelId = "feedback-modal-contractor-negative-work-quality",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Poor workmanship",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.8135 106.432L42.0453 84.6444C42.7085 83.0793 44.8981 83.0025 45.6692 84.5174L51.309 95.597C51.7709 96.5043 52.8405 96.9199 53.7937 96.5624L68.1199 91.1901L54.6 117.385C53.7484 119.035 51.3034 118.735 50.8764 116.928L48.6934 107.689C48.4345 106.594 47.3232 105.928 46.2352 106.216L35.1668 109.146C33.5592 109.571 32.1647 107.963 32.8135 106.432Z"
						fill="#6B7B81"
					/>
					<path
						d="M108.552 105.432L99.32 83.6444C98.6568 82.0793 96.4672 82.0025 95.6961 83.5174L90.0562 94.597C89.5944 95.5043 88.5248 95.9199 87.5716 95.5624L73.2454 90.1901L86.7653 116.385C87.6169 118.035 90.0619 117.735 90.4889 115.928L92.6719 106.689C92.9307 105.594 94.0421 104.928 95.1301 105.216L106.199 108.146C107.806 108.571 109.201 106.963 108.552 105.432Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M65.2658 21.7214C67.5603 19.6163 71.0838 19.6163 73.3783 21.7214L78.4424 26.3676C79.7242 27.5436 81.4533 28.1054 83.1816 27.9074L90.0095 27.1252C93.1031 26.7708 95.9537 28.8419 96.5726 31.8936L97.9387 38.6291C98.2844 40.3339 99.3531 41.8048 100.868 42.6604L106.851 46.041C109.562 47.5726 110.651 50.9237 109.358 53.7564L106.504 60.0084C105.782 61.5909 105.782 63.409 106.504 64.9915L109.358 71.2435C110.651 74.0762 109.562 77.4273 106.851 78.9589L100.868 82.3395C99.3531 83.1951 98.2844 84.666 97.9387 86.3708L96.2577 94.6594C95.7843 96.9936 93.975 98.8268 91.6473 99.3308L83.2132 101.157C80.9392 101.65 78.6283 100.497 77.6533 98.3848C76.8416 96.6262 75.0814 95.4999 73.1445 95.4999H68.9019C67.0142 95.4999 65.2885 96.5665 64.4443 98.2549C63.4772 100.189 61.373 101.279 59.2352 100.953L47.3081 99.1332C44.8198 98.7536 42.833 96.8612 42.3327 94.3944L40.7054 86.3708C40.3596 84.666 39.291 83.1951 37.7765 82.3395L31.7928 78.9589C29.0817 77.4273 27.9929 74.0762 29.2859 71.2435L32.1397 64.9915C32.8621 63.409 32.8621 61.5909 32.1397 60.0084L29.2859 53.7564C27.9929 50.9237 29.0817 47.5726 31.7928 46.041L37.7765 42.6604C39.291 41.8048 40.3596 40.3339 40.7054 38.6291L42.0714 31.8936C42.6904 28.8419 45.541 26.7708 48.6346 27.1252L55.4625 27.9074C57.1907 28.1054 58.9198 27.5436 60.2016 26.3676L65.2658 21.7214ZM52.7071 51.2424C52.3166 50.8519 52.3166 50.2187 52.7071 49.8282L58.8277 43.7076C59.2182 43.3171 59.8514 43.3171 60.2419 43.7076L69.9996 53.4653L79.7578 43.7071C80.1483 43.3166 80.7815 43.3166 81.172 43.7071L87.2926 49.8277C87.6832 50.2182 87.6832 50.8514 87.2926 51.2419L77.5344 61.0001L87.2926 70.7583C87.6832 71.1488 87.6832 71.782 87.2926 72.1725L81.172 78.2931C80.7815 78.6837 80.1483 78.6837 79.7578 78.2931L69.9996 68.5349L60.2419 78.2926C59.8514 78.6832 59.2182 78.6832 58.8277 78.2926L52.7071 72.172C52.3166 71.7815 52.3166 71.1483 52.7071 70.7578L62.4648 61.0001L52.7071 51.2424Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackUnsafe = ({
	labelId = "feedback-modal-contractor-negative-workspace-tidy",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Workspace unsafe and/or untidy",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M69.752 94.0002C81.9022 94.0002 91.752 84.1505 91.752 72.0002C91.752 69.5853 91.3629 67.2612 90.6439 65.0874L111.72 74.907C115.249 76.5511 115.628 81.4193 112.396 83.5899L72.8627 110.142C71.1724 111.277 68.9625 111.274 67.2754 110.134L27.2009 83.054C23.9524 80.8589 24.3792 75.9492 27.9577 74.3475L48.8975 64.975C48.1546 67.1811 47.752 69.5436 47.752 72.0002C47.752 84.1505 57.6017 94.0002 69.752 94.0002Z"
						fill="#6B7B81"
					/>
					<path
						d="M77.5415 57.5099C77.455 61.4529 74.2338 64.6042 70.2899 64.6042C66.346 64.6042 63.1248 61.4529 63.0383 57.5099L62.435 30H78.1448L77.5415 57.5099ZM70.1761 85C65.0912 85 61.7519 81.4097 61.7519 76.8264C61.7519 72.1667 65.243 68.5764 70.1761 68.5764C75.2609 68.5764 78.7519 72.1667 78.7519 76.8264C78.7519 81.4097 75.2609 85 70.1761 85Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackUnprofessional = ({
	labelId = "feedback-modal-contractor-negative-professional",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Unprofessional",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M89.6916 28.9911L87.6752 45.3968L96.6338 46.4979L98.0758 34.7651C105.059 40.9173 109.889 49.2274 111.548 58.4327L119.728 59.4381L117.664 76.236C117.664 76.236 100.002 78.056 67.0001 74C33.9984 69.9439 20.3955 64.2813 20.3955 64.2813L22.4601 47.4834L30.6405 48.4888C34.6905 39.4883 41.9253 32.3099 50.8255 28.1035L49.28 40.678L58.2385 41.779L60.3116 24.9118C60.3114 24.9118 60.3117 24.9118 60.3116 24.9118L61.2276 17.4619L90.6627 21.0796L89.6904 28.9905C89.6908 28.9907 89.6912 28.9909 89.6916 28.9911Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M109.974 78.9602C109.974 99.9688 92.0768 117 70 117C47.9231 117 30.0263 99.9688 30.0263 78.9602C30.0263 77.7259 30.0881 76.5053 30.2088 75.301C38.1511 77.2615 51.3113 79.4998 69.9999 79.4998C88.6887 79.4998 101.849 77.2615 109.791 75.301C109.912 76.5052 109.974 77.7259 109.974 78.9602ZM89.6717 96.2249C90.9252 96.5713 90.282 98.9173 89.0036 98.6788C80.4725 97.0872 72.9554 96.1771 69.4998 96.9998C64.1426 98.2754 57.744 101.503 52.7614 104.359C49.66 106.137 46.1391 103.536 47.828 100.385C50.4413 95.5105 54.8632 90.4998 61.9998 90.4998C67.3169 90.4998 78.2269 93.0616 89.6717 96.2249Z"
						fill="#6B7B81"
					/>
					<path
						d="M70 40.9207C55.6304 40.9207 43.0317 48.136 35.9864 58.967L108.726 69.4951C104.305 53.0657 88.6431 40.9207 70 40.9207Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBadCommunication = ({
	labelId = "feedback-modal-contractor-negative-communication",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Poor communication",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M37 58H26.6552C24.6365 58 23 59.6365 23 61.6552V105.163C23 107.188 25.5101 108.131 26.8436 106.607L29.5837 103.476C30.2777 102.683 31.2804 102.228 32.3345 102.228H86.9655C88.9842 102.228 90.6207 100.591 90.6207 98.5724V90H42C39.2386 90 37 87.7614 37 85V58Z"
						fill="#6B7B81"
					/>
					<path
						d="M83.5512 62.5637H79.3689V61.7398C79.3422 60.0664 80.0348 58.9336 81.793 57.6206C83.125 56.5908 83.4447 56.0501 83.4447 55.252C83.4447 54.2737 82.752 53.5786 81.6066 53.5786C80.3012 53.5786 79.502 54.3509 79.2889 55.7154L78.8627 55.9214L75 55.4065C75.4795 52.0339 78.0635 50 81.873 50C85.5225 50 88 52.0081 88 54.8659C88 57.0027 87.1742 58.3157 84.9365 59.7832C83.8709 60.5298 83.5779 60.9932 83.5512 61.9201V62.5637Z"
						fill="#6B7B81"
					/>
					<path
						d="M81.5799 69C80.0615 69 78.9959 67.9444 78.9959 66.5799C78.9959 65.2154 80.0881 64.1341 81.5799 64.1341C83.0984 64.1341 84.1906 65.2154 84.1906 66.5799C84.1906 67.9444 83.0984 69 81.5799 69Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M119.5 88.2821V38.2069C119.5 35.8835 117.616 34 115.293 34H45.8793C43.5558 34 41.6724 35.8835 41.6724 38.2069V80.6965C41.6724 83.02 43.5559 84.9034 45.8793 84.9034H108.757C109.97 84.9034 111.124 85.4271 111.923 86.3401L115.076 89.9443C116.611 91.6983 119.5 90.6128 119.5 88.2821ZM98 59.5C98 68.6127 90.6127 76 81.5 76C72.3873 76 65 68.6127 65 59.5C65 50.3873 72.3873 43 81.5 43C90.6127 43 98 50.3873 98 59.5Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackGoodAttention = ({ labelId = "feedback-modal-accuserve-positive-empathy" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Empathetic to my situation",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M44.0645 32C29.7407 32 18.1289 43.5675 18.1289 57.8567C18.1289 72.146 30.4142 84.3939 30.4142 84.3939L47.9272 101.252V96.1458C47.9272 85.1001 56.8815 76.1457 67.9272 76.1457H72.0728C83.1185 76.1457 92.0729 85.1001 92.0729 96.1458V99.6778L106.856 84.3939C106.856 84.3939 121.871 71.4655 121.871 57.8567C121.871 44.2479 110.259 32 95.9356 32C85.2542 32 76.0809 38.4375 72.1063 47.6331C77.8071 48.6303 82.1401 53.6047 82.1401 59.5911C82.1401 66.2959 76.7048 71.7312 70 71.7312C63.2953 71.7312 57.86 66.2959 57.86 59.5911C57.86 53.6047 62.193 48.6303 67.8938 47.6331C63.9192 38.4375 54.7459 32 44.0645 32Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackTimelyCommunication = ({
	labelId = "feedback-modal-accuserve-positive-communication",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Timely and professional communication",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M37 58H26.6552C24.6365 58 23 59.6365 23 61.6552V105.163C23 107.188 25.5101 108.131 26.8436 106.607L29.5837 103.476C30.2777 102.683 31.2804 102.228 32.3345 102.228H86.9655C88.9842 102.228 90.6207 100.591 90.6207 98.5724V90H42C39.2386 90 37 87.7614 37 85V58Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M119.5 88.2821V38.2069C119.5 35.8835 117.616 34 115.293 34H45.8793C43.5558 34 41.6724 35.8835 41.6724 38.2069V80.6965C41.6724 83.02 43.5559 84.9034 45.8793 84.9034H108.757C109.97 84.9034 111.124 85.4271 111.923 86.3401L115.076 89.9443C116.611 91.6983 119.5 90.6128 119.5 88.2821ZM65.2941 63.5882C68.218 63.5882 70.5882 61.218 70.5882 58.2941C70.5882 55.3703 68.218 53 65.2941 53C62.3703 53 60 55.3703 60 58.2941C60 61.218 62.3703 63.5882 65.2941 63.5882ZM86.1766 58.2941C86.1766 61.218 83.8064 63.5882 80.8825 63.5882C77.9586 63.5882 75.5884 61.218 75.5884 58.2941C75.5884 55.3703 77.9586 53 80.8825 53C83.8064 53 86.1766 55.3703 86.1766 58.2941ZM96.4709 63.5882C99.3947 63.5882 101.765 61.218 101.765 58.2941C101.765 55.3703 99.3947 53 96.4709 53C93.547 53 91.1768 55.3703 91.1768 58.2941C91.1768 61.218 93.547 63.5882 96.4709 63.5882Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackGoodValue = ({ labelId = "feedback-modal-accuserve-positive-value" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Clear value of Accuserve",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.8135 106.432L42.0453 84.6444C42.7085 83.0793 44.8981 83.0025 45.6692 84.5174L51.309 95.597C51.7709 96.5043 52.8405 96.9199 53.7937 96.5624L68.1199 91.1901L54.6 117.385C53.7484 119.035 51.3034 118.735 50.8764 116.928L48.6934 107.689C48.4345 106.594 47.3232 105.928 46.2352 106.216L35.1668 109.146C33.5592 109.571 32.1647 107.963 32.8135 106.432Z"
						fill="#6B7B81"
					/>
					<path
						d="M108.552 105.432L99.32 83.6444C98.6568 82.0793 96.4672 82.0025 95.6961 83.5174L90.0562 94.597C89.5944 95.5043 88.5248 95.9199 87.5716 95.5624L73.2454 90.1901L86.7653 116.385C87.6169 118.035 90.0619 117.735 90.4889 115.928L92.6719 106.689C92.9307 105.594 94.0421 104.928 95.1301 105.216L106.199 108.146C107.806 108.571 109.201 106.963 108.552 105.432Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M73.3783 21.7214C71.0838 19.6163 67.5603 19.6163 65.2658 21.7214L60.2016 26.3676C58.9198 27.5436 57.1907 28.1054 55.4625 27.9074L48.6346 27.1252C45.541 26.7708 42.6904 28.8419 42.0714 31.8936L40.7054 38.6291C40.3596 40.3339 39.291 41.8048 37.7765 42.6604L31.7928 46.041C29.0817 47.5726 27.9929 50.9237 29.2859 53.7564L32.1397 60.0084C32.8621 61.5909 32.8621 63.409 32.1397 64.9915L29.2859 71.2435C27.9929 74.0762 29.0817 77.4273 31.7928 78.9589L37.7765 82.3395C39.291 83.1951 40.3596 84.666 40.7054 86.3708L42.3327 94.3944C42.833 96.8612 44.8198 98.7536 47.3081 99.1332L59.2352 100.953C61.373 101.279 63.4772 100.189 64.4443 98.2549C65.2885 96.5665 67.0142 95.4999 68.9019 95.4999H73.1445C75.0814 95.4999 76.8416 96.6262 77.6533 98.3848C78.6283 100.497 80.9392 101.65 83.2132 101.157L91.6473 99.3308C93.975 98.8268 95.7843 96.9936 96.2577 94.6594L97.9387 86.3708C98.2844 84.666 99.3531 83.1951 100.868 82.3395L106.851 78.9589C109.562 77.4273 110.651 74.0762 109.358 71.2435L106.504 64.9915C105.782 63.409 105.782 61.5909 106.504 60.0084L109.358 53.7564C110.651 50.9237 109.562 47.5726 106.851 46.041L100.868 42.6604C99.3531 41.8048 98.2844 40.3339 97.9387 38.6291L96.5726 31.8936C95.9537 28.8419 93.1031 26.7708 90.0095 27.1252L83.1816 27.9074C81.4533 28.1054 79.7242 27.5436 78.4424 26.3676L73.3783 21.7214ZM88.8402 53.8066C89.2219 53.4178 89.2221 52.795 88.8407 52.4059L83.5435 47.0031C83.1548 46.6066 82.5174 46.6028 82.1239 46.9946L65.5063 63.5393C65.1291 63.9149 64.5237 63.9291 64.1292 63.5715L58.2372 58.231C57.823 57.8555 57.1814 57.8926 56.8131 58.3133L51.8455 63.9887C51.483 64.4029 51.5233 65.0322 51.9358 65.3967L64.8939 76.8471C65.2925 77.1993 65.8971 77.1778 66.2697 76.7983L88.8402 53.8066Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackGoodResolutions = ({
	labelId = "feedback-modal-accuserve-positive-solutions",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Proactive with solutions",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M67.5782 117H73.266C78.9181 117 83.5 112.418 83.5 106.766H57.3442C57.3442 112.418 61.9261 117 67.5782 117Z"
						fill="#6B7B81"
					/>
					<path
						d="M83 103V94H57V103C57 103.552 57.4477 104 58 104H82C82.5523 104 83 103.552 83 103Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M90.6701 72.2743C94.7915 65.1229 99 57.8206 99 52C99 35.9837 86.0163 23 70 23C53.9837 23 41 35.9837 41 52C41 58.3849 45.5826 66.5281 49.8428 74.0985C53.543 80.6736 57 86.8166 57 91H82.8412C82.8412 85.8585 86.7156 79.1359 90.6701 72.2743ZM91 51.5C91 62.8218 81.8218 72 70.5 72C60.7378 72 52.5694 65.1764 50.5041 56.0386C50.6627 56.0791 50.8289 56.1006 51.0001 56.1006H70.2481L65.0866 62.8118C64.3231 63.8046 64.6495 65.2469 65.7661 65.8141L70.5161 68.2274C71.3922 68.6725 72.4619 68.4152 73.0398 67.6203L84.1246 52.3719C84.6442 51.6572 84.6331 50.6862 84.0975 49.9835L72.9829 35.4028C72.3719 34.6012 71.2598 34.3796 70.3881 34.8857L65.7134 37.6C64.6682 38.2068 64.396 39.5949 65.1344 40.5516L69.7532 46.5356H51.0001C50.8613 46.5356 50.7259 46.5497 50.595 46.5766C52.7999 37.6327 60.8754 31 70.5 31C81.8218 31 91 40.1782 91 51.5Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackTrustworthy = ({
	labelId = "feedback-modal-accuserve-positive-trustworthy",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Trustworthy",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M68.3735 116.757L42.7702 104.804C37.7611 102.443 33.529 98.7325 30.5619 94.0987C27.5947 89.4649 26.0134 84.0969 26 78.6135V34.392C26 33.721 26.2697 33.0776 26.7499 32.6031C27.2301 32.1287 27.8813 31.8622 28.5603 31.8622C50.5216 31.8622 67.8166 19.6114 67.9894 19.4849C68.4273 19.1698 68.9551 19 69.4968 19C70.0385 19 70.5663 19.1698 71.0042 19.4849C71.177 19.6114 88.5425 31.8622 110.44 31.8622C111.119 31.8622 111.77 32.1287 112.25 32.6031C112.73 33.0776 113 33.721 113 34.392V78.6135C112.981 84.1037 111.39 89.4765 108.411 94.111C105.432 98.7454 101.187 102.452 96.1658 104.804L70.5625 116.757C70.2203 116.917 69.8465 117 69.468 117C69.0895 117 68.7157 116.917 68.3735 116.757ZM95.9649 55.0075C96.3466 54.6187 96.3468 53.9959 95.9654 53.6069L88.7542 46.2519C88.3655 45.8554 87.7281 45.8516 87.3346 46.2433L65.5654 67.9171C65.1881 68.2927 64.5827 68.3069 64.1883 67.9494L56.1933 60.7027C55.7791 60.3273 55.1375 60.3643 54.7693 60.785L48.001 68.5176C47.6384 68.9318 47.6788 69.5611 48.0913 69.9256L65.1738 85.0206C65.5724 85.3728 66.177 85.3514 66.5496 84.9718L95.9649 55.0075Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackFlexible = ({ labelId = "feedback-modal-accuserve-positive-schedule" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Flexible to my schedule",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.2783 93.8174V60.5674H112.778V93.8174C112.778 104.31 104.271 112.817 93.7782 112.817H46.2783C35.7856 112.817 27.2783 104.31 27.2783 93.8174ZM85.9606 77.8852C86.3423 77.4964 86.3425 76.8736 85.9611 76.4846L81.9516 72.3951C81.5629 71.9986 80.9255 71.9948 80.532 72.3866L67.3803 85.4807C67.003 85.8563 66.3976 85.8705 66.0031 85.5129L61.5259 81.4548C61.1117 81.0793 60.4701 81.1164 60.1018 81.5371L56.3456 85.8284C55.9831 86.2426 56.0235 86.8719 56.4359 87.2364L66.6192 96.2349C67.0178 96.5871 67.6224 96.5657 67.995 96.1861L85.9606 77.8852Z"
						fill="#6B7B81"
					/>
					<path
						d="M46.2784 27.3174C46.2784 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2783C84.2783 24.6954 86.4063 22.5674 89.0283 22.5674C91.6503 22.5674 93.7783 24.6954 93.7783 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2784 51.3619V46.3174C27.2784 35.8246 35.7856 27.3174 46.2784 27.3174Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBadAttention = ({ labelId = "feedback-modal-accuserve-negative-empathy" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "More personal attention expected",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M44.0645 32C29.7407 32 18.1289 43.5675 18.1289 57.8567C18.1289 72.146 30.4142 84.3939 30.4142 84.3939L47.9272 101.252V96.1458C47.9272 85.1001 56.8815 76.1457 67.9272 76.1457H72.0728C83.1185 76.1457 92.0729 85.1001 92.0729 96.1458V99.6778L106.856 84.3939C106.856 84.3939 121.871 71.4655 121.871 57.8567C121.871 44.2479 110.259 32 95.9356 32C85.2542 32 76.0809 38.4375 72.1063 47.6331C77.8071 48.6303 82.1401 53.6047 82.1401 59.5911C82.1401 66.2959 76.7048 71.7312 70 71.7312C63.2953 71.7312 57.86 66.2959 57.86 59.5911C57.86 53.6047 62.193 48.6303 67.8938 47.6331C63.9192 38.4375 54.7459 32 44.0645 32Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackUntimely = ({
	labelId = "feedback-modal-accuserve-negative-communication",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Poor communication and/or unknowledgeable",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M71.5512 88.5637H67.3689V87.7398C67.3422 86.0664 68.0348 84.9336 69.793 83.6206C71.125 82.5908 71.4447 82.0501 71.4447 81.252C71.4447 80.2737 70.752 79.5786 69.6066 79.5786C68.3012 79.5786 67.502 80.3509 67.2889 81.7154L66.8627 81.9214L63 81.4065C63.4795 78.0339 66.0635 76 69.873 76C73.5225 76 76 78.0081 76 80.8659C76 83.0027 75.1742 84.3157 72.9365 85.7832C71.8709 86.5298 71.5779 86.9932 71.5512 87.9201V88.5637Z"
						fill="#6B7B81"
					/>
					<path
						d="M69.5799 95C68.0615 95 66.9959 93.9444 66.9959 92.5799C66.9959 91.2154 68.0881 90.1341 69.5799 90.1341C71.0984 90.1341 72.1906 91.2154 72.1906 92.5799C72.1906 93.9444 71.0984 95 69.5799 95Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.2783 93.8174V60.5674H112.778V93.8174C112.778 104.31 104.271 112.817 93.7782 112.817H46.2783C35.7856 112.817 27.2783 104.31 27.2783 93.8174ZM86 85.5C86 94.6127 78.6127 102 69.5 102C60.3873 102 53 94.6127 53 85.5C53 76.3873 60.3873 69 69.5 69C78.6127 69 86 76.3873 86 85.5Z"
						fill="#6B7B81"
					/>
					<path
						d="M46.2784 27.3174C46.2784 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2783C84.2783 24.6954 86.4063 22.5674 89.0283 22.5674C91.6503 22.5674 93.7783 24.6954 93.7783 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2784 51.3619V46.3174C27.2784 35.8246 35.7856 27.3174 46.2784 27.3174Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBadValue = ({ labelId = "feedback-modal-accuserve-negative-value" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Value of Accuserve is unclear",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M32.8135 106.432L42.0453 84.6444C42.7085 83.0793 44.8981 83.0025 45.6692 84.5174L51.309 95.597C51.7709 96.5043 52.8405 96.9199 53.7937 96.5624L68.1199 91.1901L54.6 117.385C53.7484 119.035 51.3034 118.735 50.8764 116.928L48.6934 107.689C48.4345 106.594 47.3232 105.928 46.2352 106.216L35.1668 109.146C33.5592 109.571 32.1647 107.963 32.8135 106.432Z"
						fill="#6B7B81"
					/>
					<path
						d="M108.552 105.432L99.32 83.6444C98.6568 82.0793 96.4672 82.0025 95.6961 83.5174L90.0562 94.597C89.5944 95.5043 88.5248 95.9199 87.5716 95.5624L73.2454 90.1901L86.7653 116.385C87.6169 118.035 90.0619 117.735 90.4889 115.928L92.6719 106.689C92.9307 105.594 94.0421 104.928 95.1301 105.216L106.199 108.146C107.806 108.571 109.201 106.963 108.552 105.432Z"
						fill="#6B7B81"
					/>
					<path
						d="M72.0485 64.3064H66.8522V63.2828C66.8191 61.2037 67.6796 59.7963 69.8641 58.165C71.5189 56.8855 71.9161 56.2138 71.9161 55.2222C71.9161 54.0067 71.0556 53.1431 69.6324 53.1431C68.0106 53.1431 67.0177 54.1027 66.7529 55.798L66.2234 56.0539L61.4242 55.4141C62.02 51.2239 65.2304 48.697 69.9634 48.697C74.4977 48.697 77.5758 51.1919 77.5758 54.7424C77.5758 57.3973 76.5497 59.0286 73.7696 60.8519C72.4457 61.7795 72.0816 62.3552 72.0485 63.5067V64.3064Z"
						fill="#6B7B81"
					/>
					<path
						d="M69.5993 72.303C67.7127 72.303 66.3888 70.9916 66.3888 69.2963C66.3888 67.601 67.7458 66.2576 69.5993 66.2576C71.4858 66.2576 72.8428 67.601 72.8428 69.2963C72.8428 70.9916 71.4858 72.303 69.5993 72.303Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M73.3783 21.7214C71.0838 19.6163 67.5603 19.6163 65.2658 21.7214L60.2016 26.3676C58.9198 27.5436 57.1907 28.1054 55.4625 27.9074L48.6346 27.1252C45.541 26.7708 42.6904 28.8419 42.0714 31.8936L40.7054 38.6291C40.3596 40.3339 39.291 41.8048 37.7765 42.6604L31.7928 46.041C29.0817 47.5726 27.9929 50.9237 29.2859 53.7564L32.1397 60.0084C32.8621 61.5909 32.8621 63.409 32.1397 64.9915L29.2859 71.2435C27.9929 74.0762 29.0817 77.4273 31.7928 78.9589L37.7765 82.3395C39.291 83.1951 40.3596 84.666 40.7054 86.3708L42.3327 94.3944C42.833 96.8612 44.8198 98.7536 47.3081 99.1332L59.2352 100.953C61.373 101.279 63.4772 100.189 64.4443 98.2549C65.2885 96.5665 67.0142 95.4999 68.9019 95.4999H73.1445C75.0814 95.4999 76.8416 96.6262 77.6533 98.3848C78.6283 100.497 80.9392 101.65 83.2132 101.157L91.6473 99.3308C93.975 98.8268 95.7843 96.9936 96.2577 94.6594L97.9387 86.3708C98.2844 84.666 99.3531 83.1951 100.868 82.3395L106.851 78.9589C109.562 77.4273 110.651 74.0762 109.358 71.2435L106.504 64.9915C105.782 63.409 105.782 61.5909 106.504 60.0084L109.358 53.7564C110.651 50.9237 109.562 47.5726 106.851 46.041L100.868 42.6604C99.3531 41.8048 98.2844 40.3339 97.9387 38.6291L96.5726 31.8936C95.9537 28.8419 93.1031 26.7708 90.0095 27.1252L83.1816 27.9074C81.4533 28.1054 79.7242 27.5436 78.4424 26.3676L73.3783 21.7214ZM90 60.5C90 71.8218 80.8218 81 69.5 81C58.1782 81 49 71.8218 49 60.5C49 49.1782 58.1782 40 69.5 40C80.8218 40 90 49.1782 90 60.5Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackBadResolutions = ({
	labelId = "feedback-modal-accuserve-negative-solutions",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Things are still not resolved for me",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M67.5782 117H73.266C78.9181 117 83.5 112.418 83.5 106.766H57.3442C57.3442 112.418 61.9261 117 67.5782 117Z"
						fill="#6B7B81"
					/>
					<path
						d="M83 103V94H57V103C57 103.552 57.4477 104 58 104H82C82.5523 104 83 103.552 83 103Z"
						fill="#6B7B81"
					/>
					<path
						d="M59.1999 58.6583C58.9334 58.9248 58.9334 59.3568 59.1999 59.6233L63.3764 63.7998C63.6429 64.0663 64.075 64.0663 64.3414 63.7998L70.9998 57.1415L77.6586 63.8001C77.925 64.0666 78.3571 64.0666 78.6236 63.8001L82.8001 59.6236C83.0666 59.3572 83.0666 58.9251 82.8001 58.6586L76.1414 52L82.8001 45.3414C83.0666 45.0749 83.0666 44.6428 82.8001 44.3764L78.6236 40.1999C78.3571 39.9334 77.925 39.9334 77.6586 40.1999L70.9998 46.8585L64.3414 40.2002C64.075 39.9337 63.6429 39.9337 63.3764 40.2002L59.1999 44.3767C58.9334 44.6432 58.9334 45.0752 59.1999 45.3417L65.8583 52L59.1999 58.6583Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M90.6701 72.2743C94.7915 65.1229 99 57.8206 99 52C99 35.9837 86.0163 23 70 23C53.9837 23 41 35.9837 41 52C41 58.3849 45.5826 66.5281 49.8428 74.0985C53.543 80.6736 57 86.8166 57 91H82.8412C82.8412 85.8585 86.7156 79.1359 90.6701 72.2743ZM91 51.5C91 62.8218 81.8218 72 70.5 72C59.1782 72 50 62.8218 50 51.5C50 40.1782 59.1782 31 70.5 31C81.8218 31 91 40.1782 91 51.5Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackNotTrustworthy = ({
	labelId = "feedback-modal-accuserve-negative-trustworthy",
}: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "Did not earn my trust",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M68.3735 116.757L42.7702 104.804C37.7611 102.443 33.529 98.7325 30.5619 94.0987C27.5947 89.4649 26.0134 84.0969 26 78.6135V34.392C26 33.721 26.2697 33.0776 26.7499 32.6031C27.2301 32.1287 27.8813 31.8622 28.5603 31.8622C50.5216 31.8622 67.8166 19.6114 67.9894 19.4849C68.4273 19.1698 68.9551 19 69.4968 19C70.0385 19 70.5663 19.1698 71.0042 19.4849C71.177 19.6114 88.5425 31.8622 110.44 31.8622C111.119 31.8622 111.77 32.1287 112.25 32.6031C112.73 33.0776 113 33.721 113 34.392V78.6135C112.981 84.1037 111.39 89.4765 108.411 94.111C105.432 98.7454 101.187 102.452 96.1658 104.804L70.5625 116.757C70.2203 116.917 69.8465 117 69.468 117C69.0895 117 68.7157 116.917 68.3735 116.757ZM69.5379 74.6042C73.4819 74.6042 76.703 71.4529 76.7895 67.5099L77.3929 40H61.683L62.2864 67.5099C62.3729 71.4529 65.594 74.6042 69.5379 74.6042ZM61 86.8264C61 91.4097 64.3393 95 69.4241 95C74.5089 95 78 91.4097 78 86.8264C78 82.1667 74.5089 78.5764 69.4241 78.5764C64.4911 78.5764 61 82.1667 61 86.8264Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const FeedbackNotFlexible = ({ labelId = "feedback-modal-accuserve-negative-schedule" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({
		id: labelId,
		defaultMessage: "No regard for my schedule",
	});
	return (
		<>
			<div className="option-icon">
				<svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M72.5006 85.5496C72.4655 87.1731 71.1394 88.4708 69.5156 88.4708C67.8918 88.4708 66.5657 87.1731 66.5307 85.5496L66.2812 74H72.75L72.5006 85.5496Z"
						fill="#6B7B81"
					/>
					<path
						d="M69.4688 97C67.375 97 66 95.4986 66 93.5819C66 91.6333 67.4375 90.1319 69.4688 90.1319C71.5625 90.1319 73 91.6333 73 93.5819C73 95.4986 71.5625 97 69.4688 97Z"
						fill="#6B7B81"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.2783 93.8174V60.5674H112.778V93.8174C112.778 104.31 104.271 112.817 93.7782 112.817H46.2783C35.7856 112.817 27.2783 104.31 27.2783 93.8174ZM86 85.5C86 94.6127 78.6127 102 69.5 102C60.3873 102 53 94.6127 53 85.5C53 76.3873 60.3873 69 69.5 69C78.6127 69 86 76.3873 86 85.5Z"
						fill="#6B7B81"
					/>
					<path
						d="M46.2784 27.3174C46.2784 24.6954 48.4063 22.5674 51.0283 22.5674C53.6503 22.5674 55.7783 24.6954 55.7783 27.3174H84.2783C84.2783 24.6954 86.4063 22.5674 89.0283 22.5674C91.6503 22.5674 93.7783 24.6954 93.7783 27.3174C104.271 27.3174 112.778 35.8246 112.778 46.3174V51.0674L27.2784 51.3619V46.3174C27.2784 35.8246 35.7856 27.3174 46.2784 27.3174Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const Contractor = ({ labelId = "feedback-modal-contractor" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Contractor" });
	return (
		<>
			<div className="primary-dialog__content--icon">
				<svg width="70" height="40" viewBox="0 0 70 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M50.5921 24.6383C50.5921 32.57 43.8351 39 35.5 39C27.1649 39 20.4079 32.57 20.4079 24.6383C20.4079 24.5243 20.4093 24.4107 20.4121 24.2975C23.4037 24.989 28.393 25.786 35.5 25.786C42.607 25.786 47.5963 24.989 50.5879 24.2975C50.5907 24.4107 50.5921 24.5243 50.5921 24.6383ZM41.3397 32.2378C42.6911 31.3097 41.9019 29.5615 40.2625 29.5615H31.115C29.4756 29.5615 28.6864 31.3097 30.0378 32.2378C31.4653 33.218 33.3842 34.092 35.6888 34.092C37.9933 34.092 39.9122 33.218 41.3397 32.2378Z"
						fill="#6B7B81"
					/>
					<path
						d="M35.5 10.2765C28.3759 10.2765 22.4047 14.9738 20.8199 21.2912H50.1801C48.5953 14.9738 42.6241 10.2765 35.5 10.2765Z"
						fill="#6B7B81"
					/>
					<path
						d="M41.3421 11.2499H44.7498V6.78691C47.5413 8.69628 49.6773 11.4466 50.7658 14.6578H54V21.0476C54 21.0476 48.0536 22.7652 35.5 22.7652C22.9464 22.7652 17 21.0476 17 21.0476V14.6578H20.2342C21.3851 11.2624 23.7071 8.38234 26.7368 6.46678V11.25H30.1446L30.1447 2H41.3416L41.3421 11.2499Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};
export const Accuserve = ({ labelId = "feedback-modal-accuserve" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Accuserve" });
	return (
		<>
			<div className="primary-dialog__content--icon">
				<svg width="70" height="40" viewBox="0 0 70 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M41.413 33.5282H40.9659C40.6659 33.5282 40.4206 33.2825 40.4206 32.9761V21.1575C40.4206 20.8538 40.1752 20.6054 39.8753 20.6054H30.3518C30.0519 20.6054 29.8065 20.8538 29.8065 21.1575V32.9761C29.8065 33.2825 29.5611 33.5282 29.2612 33.5282H28.9067L28.8958 33.5199C28.8958 33.5199 28.8877 33.5199 28.8877 33.5254C28.8877 33.5282 28.8877 33.531 28.8822 33.531H22.5868C22.2869 33.531 22.0415 33.2853 22.0415 32.9788V21.1603C22.0415 20.8566 21.7961 20.6081 21.4962 20.6081H18.2218C17.9518 20.6081 17.7337 20.3873 17.7337 20.114C17.7337 19.9566 17.8073 19.8103 17.9328 19.7164L34.7904 7.10278C34.984 6.95923 35.2457 6.95923 35.4393 7.10278L52.2943 19.7164C52.4197 19.8103 52.4906 19.9566 52.4906 20.114C52.4906 20.3873 52.2725 20.6081 52.0025 20.6081H48.7281C48.4282 20.6081 48.1828 20.8566 48.1828 21.1603V32.9788C48.1828 33.2853 47.9374 33.531 47.6375 33.531H41.413V33.5282Z"
						fill="#6B7B81"
					/>
					<path
						d="M16.2042 22.5433C15.9016 22.5433 15.6589 22.2976 15.6589 21.9911V19.0455C15.6589 18.8688 15.7407 18.7059 15.8798 18.601L19.0507 16.2295C19.3451 16.0087 19.3451 15.5642 19.0507 15.3434L15.4517 12.6213C15.2609 12.475 14.9964 12.475 14.8028 12.6213L2.63466 21.6957C2.49561 21.8006 2.41382 21.9635 2.41382 22.1402V22.9491C2.41382 23.2528 2.6592 23.5012 2.95911 23.5012H4.40413C4.70404 23.5012 4.94942 23.7497 4.94942 24.0534V32.9511C4.94942 33.2548 5.1948 33.5032 5.49471 33.5032H19.6968C19.9967 33.5032 20.2421 33.2548 20.2421 32.9511V23.0982C20.2421 22.7945 19.9967 22.546 19.6968 22.546H16.2042V22.5433Z"
						fill="#6B7B81"
					/>
					<path
						d="M55.4161 12.6192C55.2226 12.4757 54.9581 12.4757 54.7673 12.6192L51.1711 15.3413C50.8793 15.5621 50.8793 16.0066 51.1738 16.2275L54.3474 18.5989C54.4864 18.7038 54.5682 18.8667 54.5682 19.0434V21.9891C54.5682 22.2927 54.3228 22.5412 54.0229 22.5412H50.5303C50.2304 22.5412 49.985 22.7897 49.985 23.0933V32.9684C49.985 33.272 50.2304 33.5205 50.5303 33.5205H64.7324C65.0324 33.5205 65.2777 33.272 65.2777 32.9684V24.0761C65.2777 23.7725 65.5231 23.524 65.823 23.524H67.268C67.568 23.524 67.8133 23.2755 67.8133 22.9719V22.1381C67.8133 21.9614 67.7315 21.7986 67.5925 21.6937L55.4189 12.6165L55.4161 12.6192Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

export const Other = ({ labelId = "feedback-modal-other" }: FeedbackIconProps) => {
	const intl = useIntl();
	const label = intl.formatMessage({ id: labelId, defaultMessage: "Other" });
	return (
		<>
			<div className="primary-dialog__content--icon">
				<svg width="71" height="40" viewBox="0 0 71 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M21.9406 5.83984C14.5439 5.83984 8.54773 11.836 8.54773 19.2327C8.54773 26.6294 14.5439 32.6256 21.9406 32.6256H48.7263C56.123 32.6256 62.1192 26.6294 62.1192 19.2327C62.1192 11.836 56.123 5.83984 48.7263 5.83984H21.9406ZM20.979 23.628C23.4067 23.628 25.3747 21.66 25.3747 19.2324C25.3747 16.8048 23.4067 14.8368 20.979 14.8368C18.5514 14.8368 16.5834 16.8048 16.5834 19.2324C16.5834 21.66 18.5514 23.628 20.979 23.628ZM39.5229 19.2324C39.5229 21.66 37.555 23.628 35.1273 23.628C32.6997 23.628 30.7317 21.66 30.7317 19.2324C30.7317 16.8048 32.6997 14.8368 35.1273 14.8368C37.555 14.8368 39.5229 16.8048 39.5229 19.2324ZM49.2756 23.628C51.7033 23.628 53.6712 21.66 53.6712 19.2324C53.6712 16.8048 51.7033 14.8368 49.2756 14.8368C46.848 14.8368 44.88 16.8048 44.88 19.2324C44.88 21.66 46.848 23.628 49.2756 23.628Z"
						fill="#6B7B81"
					/>
				</svg>
			</div>
			<div className="primary-dialog__content--label">{label}</div>
		</>
	);
};

type FeedbackType = "positive" | "negative" | "neutral";

type FeedbackIconItem = FieldItem<string> & {
	selectedClass?: FeedbackType;
	labelId: string;
};
export const actorIcons: FeedbackIconItem[] = [
	{
		value: "contractor",
		element: <Contractor labelId={"feedback-modal-contractor"} />,
		selectedClass: "neutral",
		labelId: "feedback-modal-contractor",
	},
	{
		value: "accuserve",
		element: <Accuserve labelId={"feedback-modal-accuserve"} />,
		selectedClass: "neutral",
		labelId: "feedback-modal-accuserve",
	},
	{
		value: "other",
		element: <Other labelId={"feedback-modal-other"} />,
		selectedClass: "neutral",
		labelId: "feedback-modal-other",
	},
];

export const faceIcons: FeedbackIconItem[] = [
	{
		value: "good",
		element: <FeedbackGood labelId={"feedback-modal-good"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-good",
	},
	{
		value: "okay",
		element: <FeedbackOkay labelId={"feedback-modal-just-okay"} />,
		selectedClass: "neutral",
		labelId: "feedback-modal-just-okay",
	},
	{
		value: "bad",
		element: <FeedbackBad labelId={"feedback-modal-not-good"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-not-good",
	},
];

export const detailIconsContractorGood: FeedbackIconItem[] = [
	{
		value: "KOTR_POS_CLEAR_EXPECTATIONS",
		element: <FeedbackClearExpectations labelId={"feedback-modal-contractor-positive-expectations"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-expectations",
	},
	{
		value: "KOTR_POS_ON_TIME",
		element: <FeedbackOnTime labelId={"feedback-modal-contractor-positive-on-time"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-on-time",
	},
	{
		value: "KOTR_POS_GOOD_WORKMANSHIP",
		element: <FeedbackGoodWorkmanship labelId={"feedback-modal-contractor-positive-work-quality"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-work-quality",
	},
	{
		value: "KOTR_POS_SAFE",
		element: <FeedbackSafe labelId={"feedback-modal-contractor-positive-workspace-tidy"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-workspace-tidy",
	},
	{
		value: "KOTR_POS_PROFESSIONAL",
		element: <FeedbackProfessional labelId={"feedback-modal-contractor-positive-professional"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-professional",
	},
	{
		value: "KOTR_POS_GOOD_COMM",
		element: <FeedbackGoodCommunication labelId={"feedback-modal-contractor-positive-communication"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-contractor-positive-communication",
	},
];

export const detailIconsContractorBad: FeedbackIconItem[] = [
	{
		value: "KOTR_NEG_UNCLEAR_EXPECTATIONS",
		element: <FeedbackUnclearExpectations labelId={"feedback-modal-contractor-negative-expectations"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-expectations",
	},
	{
		value: "KOTR_NEG_LATE",
		element: <FeedbackLate labelId={"feedback-modal-contractor-negative-on-time"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-on-time",
	},
	{
		value: "KOTR_NEG_BAD_WORKMANSHIP",
		element: <FeedbackBadWorkmanship labelId={"feedback-modal-contractor-negative-work-quality"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-work-quality",
	},
	{
		value: "KOTR_NEG_UNSAFE",
		element: <FeedbackUnsafe labelId={"feedback-modal-contractor-negative-workspace-tidy"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-workspace-tidy",
	},
	{
		value: "KOTR_NEG_UNPROFESSIONAL",
		element: <FeedbackUnprofessional labelId={"feedback-modal-contractor-negative-professional"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-professional",
	},
	{
		value: "KOTR_NEG_BAD_COMM",
		element: <FeedbackBadCommunication labelId={"feedback-modal-contractor-negative-communication"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-contractor-negative-communication",
	},
];

export const detailIconsAccuserveGood: FeedbackIconItem[] = [
	{
		value: "ACC_POS_GOOD_ATTENTION",
		element: <FeedbackGoodAttention labelId={"feedback-modal-accuserve-positive-empathy"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-empathy",
	},
	{
		value: "ACC_POS_TIMELY_COMM",
		element: <FeedbackTimelyCommunication labelId={"feedback-modal-accuserve-positive-communication"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-communication",
	},
	{
		value: "ACC_POS_GOOD_VALUE",
		element: <FeedbackGoodValue labelId={"feedback-modal-accuserve-positive-value"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-value",
	},
	{
		value: "ACC_POS_GOOD_RESOLUTIONS",
		element: <FeedbackGoodResolutions labelId={"feedback-modal-accuserve-positive-solutions"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-solutions",
	},
	{
		value: "ACC_POS_TRUSTWORTHY",
		element: <FeedbackTrustworthy labelId={"feedback-modal-accuserve-positive-trustworthy"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-trustworthy",
	},
	{
		value: "ACC_POS_FLEXIBLE",
		element: <FeedbackFlexible labelId={"feedback-modal-accuserve-positive-schedule"} />,
		selectedClass: "positive",
		labelId: "feedback-modal-accuserve-positive-schedule",
	},
];
export const detailIconsAccuserveBad: FeedbackIconItem[] = [
	{
		value: "ACC_NEG_BAD_ATTENTION",
		element: <FeedbackBadAttention labelId={"feedback-modal-accuserve-negative-empathy"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-empathy",
	},
	{
		value: "ACC_NEG_UNTIMELY_COMM",
		element: <FeedbackUntimely labelId={"feedback-modal-accuserve-negative-communication"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-communication",
	},
	{
		value: "ACC_NEG_BAD_VALUE",
		element: <FeedbackBadValue labelId={"feedback-modal-accuserve-negative-value"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-value",
	},
	{
		value: "ACC_NEG_BAD_RESOLUTIONS",
		element: <FeedbackBadResolutions labelId={"feedback-modal-accuserve-negative-solutions"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-solutions",
	},
	{
		value: "ACC_NEG_NOT_TRUSTWORTHY",
		element: <FeedbackNotTrustworthy labelId={"feedback-modal-accuserve-negative-trustworthy"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-trustworthy",
	},
	{
		value: "ACC_NEG_NOT_FLEXIBLE",
		element: <FeedbackNotFlexible labelId={"feedback-modal-accuserve-negative-schedule"} />,
		selectedClass: "negative",
		labelId: "feedback-modal-accuserve-negative-schedule",
	},
];
