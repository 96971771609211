import { ReactNode } from "react";

type PageIntroSimpleProps = {
	title: String;
	copy: ReactNode;
};

const PageIntroSimple = ({ title, copy }: PageIntroSimpleProps) => {
	let copyElement = copy ? <p>{copy}</p> : null;

	return (
		<div className="page-intro-simple">
			<h1 className="page-intro-simple__title">{title}</h1>
			{copyElement}
		</div>
	);
};

export default PageIntroSimple;
