type PastClaimCardProps = {
	icon: any;
	entryDate: Date;
	entryTitle: string;
	entryDescription: string | undefined | null;
	isStopped: boolean;
};

export const PastClaimCard = ({ icon, entryTitle, entryDescription, isStopped }: PastClaimCardProps) => {
	let description = <></>;
	if (typeof entryDescription === "string" && entryDescription.length > 0) {
		description = <p>{entryDescription}</p>;
	}

	return (
		<div className={`past-claim card d-flex ${isStopped ? "stopped" : ""}`}>
			<div className="past-claim__icon col d-flex">{icon}</div>

			<div className="past-claim__content d-flex">
				<div className="past-claim__info">
					<h2 className="past-claim__title">{entryTitle}</h2>
					{description}
				</div>
			</div>
		</div>
	);
};
