import moment from "moment";
import { PortalInfo } from "model/dto/portal-info";
import { Link } from "react-router-dom";

type OtherClaimsProps = {
	otherPortalsList: Array<PortalInfo> | null;
};

const OtherClaims = ({ otherPortalsList }: OtherClaimsProps) => {
	if (!otherPortalsList) {
		return <></>;
	}

	return (
		<>
			<strong>Active</strong>
			<ul className="unordered-list">
				{otherPortalsList.map((portal) => {
					const prettyDate = portal.lossDate ? ` (${moment(portal.lossDate).format("MMM D, YYYY")})` : "";
					return (
						<li key={portal.publicId}>
							<Link to={`/${portal.publicId}`}>{`${portal.baseClaimNumber}${prettyDate}`}</Link>
						</li>
					);
				})}
			</ul>
		</>
	);
};

export default OtherClaims;
