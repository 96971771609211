import { JournalEntry } from "model/dto/journal-entry";
import { Note } from "model/dto/note";
import { HistoryEntry } from "utils/claim";
import { HistoryIcon, ServiceIcon } from "components/ServiceIcons";
import { isServiceStopped, isStoppedStatus } from "utils/serviceUtil";
import { PastClaimCard } from "components/PastClaimCard";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";

type HistoryCardProps = {
	entry: HistoryEntry;
};

const HistoryCard = ({ entry }: HistoryCardProps) => {
	const intl = useIntl();
	const { getHistoryStrings, getTranslatedService } = useProse(intl);

	const journalContent = () => {
		const journalEntry: JournalEntry = entry.entry as JournalEntry;

		const entryDate = journalEntry.eventTimestamp;
		const { title, description } = getHistoryStrings(entry);

		const isStopped = isStoppedStatus(journalEntry.portalSubStatus);

		let icon: JSX.Element = <HistoryIcon />;

		if (entry.service !== null) {
			icon = isStopped ? (
				<ServiceIcon service={entry.service} stopped={true} />
			) : (
				<ServiceIcon service={entry.service} ignoreStatus={true} />
			);
		}

		return (
			<PastClaimCard
				key={entry.key}
				icon={icon}
				entryDate={entryDate}
				entryTitle={title}
				entryDescription={description}
				isStopped={isStopped}
			/>
		);
	};

	const noteContent = () => {
		const noteEntry: Note = entry.entry as Note;
		const serviceName =
			entry.service && entry.service.serviceType ? getTranslatedService(entry.service.serviceType) : "Claim";
		const icon = entry.service !== null ? <ServiceIcon service={entry.service} /> : <HistoryIcon />;
		const isStopped = entry.service !== null ? isServiceStopped(entry.service) : false;

		return (
			<PastClaimCard
				key={entry.key}
				icon={icon}
				entryDate={entry.date}
				entryTitle={serviceName}
				entryDescription={noteEntry.noteText}
				isStopped={isStopped}
			/>
		);
	};

	return entry.type === "Journal" ? journalContent() : noteContent();
};

export default HistoryCard;
