import { getPortal, getPortalList } from "api/portal";
import { Portal } from "model/dto/portal";
import { PortalList } from "model/dto/portal-list";
import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useParams, useSearchParams } from "react-router-dom";
import { compressError } from "utils/compressError";

import { Locale, useLocale } from "i18n/LocaleContext";

type PortalContextState = {
	portal: Portal | undefined;
	portalList: PortalList | undefined;
	isLoading: boolean;
	linkCode: string | null;
	publicId: string | undefined;
	needsAuth: boolean;
	error: boolean;
};

const PortalContext = React.createContext<PortalContextState>({
	portal: undefined,
	portalList: undefined,
	isLoading: false,
	linkCode: null,
	publicId: undefined,
	needsAuth: false,
	error: false,
});

const previousState = {
	publicId: "",
};

function PortalProvider({ children }) {
	const { publicId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams(undefined);
	const linkCode = searchParams.get("c");

	const [portal, setPortal] = useState<Portal | undefined>(undefined);
	const [portalList, setPortalList] = useState<PortalList | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [needsAuth, setNeedsAuth] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const { setCurrentLocale } = useLocale();

	const loadPortalStuffs = useCallback(
		async (publicId: string) => {
			try {
				setIsLoading(true);

				const portal = await getPortal(publicId);
				const portalList = await getPortalList();

				setPortalList(portalList);
				setPortal(portal);
				// get search params that are not the code
				const params = new URLSearchParams(searchParams);
				params.delete("c");
				setSearchParams(new URLSearchParams(params), { replace: true });

				// set this so that we don't duplicate calls
				previousState.publicId = publicId;
				if (portal && portal.claim && portal.claim.explicitLanguagePreference) {
					setCurrentLocale(portal.claim.explicitLanguagePreference.toLocaleLowerCase() as Locale);
				}
			} catch (err: any) {
				if (err.status === 403) {
					setNeedsAuth(true);
				} else {
					const compressed = compressError(err);
					Sentry.captureException(compressed);
					setError(true);
				}

				// reset this, so that it doesn't
				// prevent future calls
				previousState.publicId = "";
			} finally {
				setIsLoading(false);
			}
		},
		[searchParams, setSearchParams, setCurrentLocale]
	);

	useEffect(() => {
		if (publicId && publicId !== previousState.publicId) {
			loadPortalStuffs(publicId);
		}
	}, [publicId, loadPortalStuffs]);

	return (
		<PortalContext.Provider
			value={{
				portal,
				portalList,
				isLoading,
				linkCode,
				publicId,
				needsAuth,
				error,
			}}
		>
			{children}
		</PortalContext.Provider>
	);
}

export { PortalProvider, PortalContext };
