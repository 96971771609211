import * as t from "io-ts";
import moment from "moment-timezone";

export const EASTERN_TZ = "America/New_York";

/**
 * Provides a codec/type definition for TimeZonedDate
 */
export const ZonedDateTimeFromString = new t.Type<Date, string, unknown>(
	"ZonedDateTimeFromString",
	(u): u is Date => u instanceof Date,
	(u, c) => {
		const result = t.string.validate(u, c);

		if (result.isRight()) {
			return t.success(moment(result.value).tz(EASTERN_TZ).toDate());
		}

		return t.failure(u, c);
	},
	(a) => a.toISOString()
);
