import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { NoteCodec } from "model/dto/note";
import { SubtradeCodec } from "model/dto/subtrade";
import { DateFromISOString } from "io-ts-types/lib/Date/DateFromISOString";
import { AssignedContractorCodec } from "model/dto/assigned-contractor";
import { ServiceTypeCodec } from "model/dto/service-type";
import { StatusTypeCodec } from "model/dto/status-type";
import { SubstatusTypeCodec } from "model/dto/substatus-type";
import { JournalEntryCodec } from "model/dto/journal-entry";
import { AssignedStaffRepCodec } from "model/dto/assigned-staff-rep";
import { ZonedDateTimeFromString } from "model/types/TimeZonedDate";

export const ServiceCodec = t.partial({
	serviceId: t.union([t.null, UUID]),
	serviceType: t.union([t.null, ServiceTypeCodec]),
	notes: t.union([t.null, t.array(NoteCodec)]),
	serviceHistoryEvents: t.union([t.null, t.array(JournalEntryCodec)]),
	portalStatus: t.union([t.null, StatusTypeCodec]),
	portalSubStatus: t.union([t.null, SubstatusTypeCodec]),
	statusLastUpdateDate: t.union([t.null, DateFromISOString]),
	targetJobStartDatetime: t.union([t.null, ZonedDateTimeFromString]),
	targetJobStartDate: t.union([t.null, t.string]),
	jobStartDatetime: t.union([t.null, ZonedDateTimeFromString]),
	jobEstimatedCompletionDate: t.union([t.null, t.string]),
	jobEstimatedCompletionDatetime: t.union([t.null, ZonedDateTimeFromString]),
	jobCompletionDateTime: t.union([t.null, ZonedDateTimeFromString]),
	inspectionScheduledDatetime: t.union([t.null, ZonedDateTimeFromString]),
	inspectionAppointmentDatetime: t.union([t.null, ZonedDateTimeFromString]),
	inspectionPerformedDatetime: t.union([t.null, ZonedDateTimeFromString]),
	assignedContractor: t.union([t.null, AssignedContractorCodec]),
	assignedStaffRep: t.union([t.null, AssignedStaffRepCodec]),
	subtrades: t.union([t.null, t.array(SubtradeCodec)]),
});

export type Service = t.TypeOf<typeof ServiceCodec>;
