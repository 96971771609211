import * as portalApi from "api/portal";
import Loader from "components/Loader";
import { PortalList } from "model/dto/portal-list";
import FatalError from "pages/error/FatalError";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { Navigate } from "react-router-dom";

const LandingPage = () => {
	const [portalList, setPortalList] = useState<PortalList | undefined>(undefined);
	const [error, setError] = useState<boolean>(false);

	useEffect(() => {
		const init = async () => {
			try {
				const portalList = await portalApi.getPortalList();
				setPortalList(portalList);
			} catch (err) {
				Sentry.captureException(err as any);
				setError(true);
			}
		};

		init();
	}, []);

	if (error) {
		return (
			<div className="errorWrap">
				<FatalError />
			</div>
		);
	}

	if (!portalList) {
		return <Loader></Loader>;
	}

	// if only one portal, redirect to it
	if (portalList.portals.length === 1) {
		return <Navigate to={"/" + portalList.portals[0].publicId}></Navigate>;
	} else {
		window.location.assign("https://www.accuserve.com");
	}

	return <></>;
};

export default LandingPage;
