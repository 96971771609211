import ServiceCard from "components/ServiceCard";
import { Service } from "model/dto/service";
import { getSortedServiceList } from "utils/serviceUtil";
import { useIntl } from "i18n/Internationalization";

type ServiceListProps = {
	services: Array<Service>;
};

const ServiceList = ({ services }: ServiceListProps) => {
	const sortedServices = getSortedServiceList(services);
	const intl = useIntl();

	const i18nstrings = {
		"service-list-dates-subject-to-change": intl.formatMessage({
			id: "service-list-dates-subject-to-change",
			defaultMessage:
				"*Anticipated dates are subject to change as the work progresses. Please check back for updates.",
		}),
	};

	return (
		<section className="service-list">
			<div className="service-list__inner">
				{sortedServices.map((service) => (
					<ServiceCard key={service.serviceId} service={service} includeProse={sortedServices.length > 1} />
					// <ServiceCard key={service.serviceId} service={service} includeProse={true} />
				))}
				<div className="disclaimer">
					<p>{i18nstrings["service-list-dates-subject-to-change"]} </p>
				</div>
			</div>
		</section>
	);
};

export default ServiceList;
