import React from "react";
import { useState } from "react";
import { useRef } from "react";
import useId from "hooks/useId";
import ToggleButton from "components/ToggleButton";

type SidebarWidgetProps = {
	content: JSX.Element | String;
	toggleLabel: JSX.Element | String;
	classes?: String;
};

const SidebarWidgetCollapsible = ({ toggleLabel, content, classes = "" }: SidebarWidgetProps) => {
	let [expanded, setExpand] = useState(false);
	let widgetClasses = ["claim-meta__widget", classes].join(" ").trim();
	let drawerClasses = `claim-meta__widget-drawer ${expanded ? "claim-meta__widget-drawer--open" : ""}`.trim();
	let { current: drawerID } = useRef(useId("drawer"));

	// Capture the click even on the widget title to apply state to whole widget
	let handleClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		setExpand(!expanded);
	};

	return (
		<React.Fragment>
			<div className={widgetClasses}>
				<h3 onClick={handleClick}>
					<ToggleButton expanded={expanded} controls={drawerID} toggleParent={setExpand} label={false} />

					<span className="claim-meta__collapsible-label">{toggleLabel}</span>
				</h3>

				<div id={drawerID} className={drawerClasses}>
					{content}
				</div>
			</div>
		</React.Fragment>
	);
};

export default SidebarWidgetCollapsible;
