import { useState } from "react";

import ContactMethods from "components/ContactMethods";
import { useIntl } from "i18n/Internationalization";
import { useProse } from "utils/prose";
import { ContactMethod } from "model/dto/contact-method";
import { Service } from "model/dto/service";

type ServiceCardContactProps = {
	service: Service;
};

const FALLBACK_CONTACT_METHODS: Array<ContactMethod> = [
	{
		methodType: "Phone",
		phoneNumber: "1-800-All-GOOD",
	},
];

const ServiceCardContact = ({ service }: ServiceCardContactProps) => {
	const intl = useIntl();
	const { getTranslatedService } = useProse(intl);

	let assignedStaffRep;
	const serviceName = getTranslatedService(service.serviceType);
	const i18nstrings = {
		"service-card-contact-here-for-you": intl.formatMessage(
			{
				id: "service-card-contact-here-for-you",
				defaultMessage: "We're here for your {serviceName} needs. Questions?",
			},
			{
				serviceName: serviceName,
			}
		),
		"service-card-contact-assigned-staff-rep": intl.formatMessage({
			id: "service-card-contact-assigned-staff-rep",
			defaultMessage: "Accuserve Customer Service",
		}),
	};

	if (typeof service.assignedStaffRep !== "undefined" && service.assignedStaffRep !== null) {
		let contactMethods = <></>;
		if (service.assignedStaffRep.contactMethods && service.assignedStaffRep.contactMethods.length) {
			contactMethods = (
				<ContactMethods
					contactMethods={service.assignedStaffRep.contactMethods}
					includeBreaks={false}
					sortEmailsTop={false}
				/>
			);
		} else {
			contactMethods = (
				<ContactMethods contactMethods={FALLBACK_CONTACT_METHODS} includeBreaks={false} sortEmailsTop={false} />
			);
		}

		assignedStaffRep = (
			<div key={service.assignedStaffRep.fullName} className="service-card__contact-info">
				<p className="service-card__contact-rep">{service.assignedStaffRep.fullName}</p>
				{contactMethods}
			</div>
		);
	} else {
		assignedStaffRep = (
			<div key={`assignedStaff-${service.serviceId}`} className="service-card__contact-info">
				{i18nstrings["service-card-contact-assigned-staff-rep"]}
				<ContactMethods contactMethods={FALLBACK_CONTACT_METHODS} includeBreaks={false} sortEmailsTop={false} />
			</div>
		);
	}

	const [open, setOpen] = useState(false);
	function toggleOpen() {
		setOpen(!open);
	}

	return (
		<div className={`service-card__contact d-flex flex-column justify-content-center ${open ? "open" : ""}`}>
			<button onClick={toggleOpen} className="service-card__contact-btn" aria-label="Contact Information">
				<svg
					width="22"
					height="21"
					viewBox="0 0 22 21"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					aria-hidden="true"
				>
					<path
						d="M5.5 0H1.22222C0.55 0 0 0.500952 0 1.11323C0 11.5664 9.30111 20.0381 20.7778 20.0381C21.45 20.0381 22 19.5371 22 18.9249V15.0397C22 14.4274 21.45 13.9265 20.7778 13.9265C19.2622 13.9265 17.7833 13.7038 16.4144 13.2919C16.2922 13.2474 16.1578 13.2363 16.0356 13.2363C15.7178 13.2363 15.4122 13.3476 15.1678 13.5591L12.4789 16.0082C9.02 14.394 6.18444 11.8225 4.42444 8.67204L7.11333 6.22294C7.45555 5.91124 7.55333 5.47708 7.41889 5.08745C6.96667 3.84063 6.72222 2.50476 6.72222 1.11323C6.72222 0.500952 6.17222 0 5.5 0Z"
						fill="#F99E53"
					/>
				</svg>
				{intl.formatMessage({
					id: "service-card-contact-questions",
					defaultMessage: "Questions?",
				})}
			</button>

			<h3 className="service-card__contact-title">{i18nstrings["service-card-contact-here-for-you"]}</h3>

			{assignedStaffRep}
		</div>
	);
};

export default ServiceCardContact;
