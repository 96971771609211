import { Helmet } from "react-helmet-async";
import ErrorCard from "components/ErrorCard";
import { useIntl } from "i18n/Internationalization";

const Page500 = () => {
	const intl = useIntl();

	const i18nstrings = { 
		"page-500-error-title" : intl.formatMessage({
			id: "page-500-error-title",
			defaultMessage: "500 Error"
		}),
		"page-500-internal-server-error" : intl.formatMessage({
			id: "page-500-internal-server-error",
			defaultMessage: "500 Internal server error."
		}),
		"page-500-did-not-complete-request" : intl.formatMessage({
			id: "page-500-did-not-complete-request",
			defaultMessage: "The server encountered something unexpected that didn't allow it to complete the request."
		})
	};

	return (
		<>
			<Helmet title={i18nstrings['page-500-error-title']} />
			<ErrorCard
				title={i18nstrings['page-500-internal-server-error']}
				copy={i18nstrings['page-500-did-not-complete-request']}
			/>
		</>
	);
};

export default Page500;
