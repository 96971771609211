import React from "react";
import { Col, Container, Row } from "react-bootstrap";

type CenteredProps = {
	children: React.ReactNode;
	vertical?: boolean | undefined | null;
};

const Centered = ({ children, vertical = false }: CenteredProps) => {
	const verticalClass = "centered-container--vertical";
	const containerClasses = `d-flex flex-column h-100 centered-container ${vertical ? verticalClass : ""}`.trim();

	return (
		<Container className={containerClasses}>
			<Row className="h-100">
				<Col lg="5" className="mx-auto d-table h-100">
					<div className="d-table-cell align-middle">{children}</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Centered;
