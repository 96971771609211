import { useIntl } from "i18n/Internationalization";
import moment from "moment";

const PrimaryFooterSimple = () => {
	const intl = useIntl();

	const year = moment().format("YYYY");

	const i18nstrings = {
		"simple-footer-copyright": intl.formatMessage(
			{
				id: "simple-footer-copyright",
				defaultMessage: `© Accuserve Solutions, Inc ${year}. All rights reserved.`,
			},
			{
				year: year,
			}
		),
	};

	return (
		<footer className="primary-footer">
			<div className="primary-footer__inner">
				<div className="primary-footer__copyright">{i18nstrings["simple-footer-copyright"]}</div>
			</div>
		</footer>
	);
};

export default PrimaryFooterSimple;
