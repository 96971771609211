const MAX_LENGTH = 100000;

export function compressError(err: object): object {
	if (err["extra"] && err["extra"]["parseError"]) {
		err["extra"]["parseError"] = [err["extra"]["parseError"][0]];
		if (
			typeof err["extra"]["parseError"][0] == "string" &&
			JSON.stringify(err["extra"]["parseError"][0]).length > MAX_LENGTH
		) {
			console.log("truncating");
			err["extra"]["parseError"][0] = err["extra"]["parseError"][0].substring(0, MAX_LENGTH);
		}
	}
	return err;
}
