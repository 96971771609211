import { Helmet } from "react-helmet-async";
import ErrorCard from "components/ErrorCard";
import { useIntl } from "i18n/Internationalization";

const FatalError = () => {
	// We handle this error page differently than the other error pages because we need to
	// make sure we handle if the intl system is not functioning properly
	let i18nstrings = {};

	try {
		const intl = useIntl();

		i18nstrings = {
			"fatal-error-unexpected":
				intl.formatMessage({
					id: "fatal-error-unexpected",
					defaultMessage: "Unexpected Error",
				}) || "Unexpected Error",
			"fatal-error-something-went-wrong":
				intl.formatMessage({
					id: "fatal-error-something-went-wrong",
					defaultMessage: "Something went wrong.",
				}) || "Something went wrong.",
			"fatal-error-unexpected-error":
				intl.formatMessage({
					id: "fatal-error-unexpected-error",
					defaultMessage: "An unexpected error occurred.",
				}) || "An unexpected error occurred.",
		};
	} catch (error) {
		i18nstrings = {
			"fatal-error-unexpected": "Unexpected Error",
			"fatal-error-something-went-wrong": "Something went wrong.",
			"fatal-error-unexpected-error": "An unexpected error occurred.",
		};
	}

	return (
		<>
			<Helmet title={i18nstrings["fatal-error-unexpected"]} />
			<ErrorCard
				title={i18nstrings["fatal-error-something-went-wrong"]}
				copy={i18nstrings["fatal-error-unexpected-error"]}
			/>
		</>
	);
};

export default FatalError;
