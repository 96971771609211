import Loader from "components/Loader";
import PrimaryFooter from "components/PrimaryFooter";
import PrimaryHeader from "components/PrimaryHeader";
import PrimarySidebar from "components/PrimarySidebar";
import PrimaryContent from "layouts/PrimaryContent";
import ErrorLayout from "layouts/ErrorLayout";
import FatalError from "pages/error/FatalError";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate, Link } from "react-router-dom";
import { PortalContext } from "contexts/PortalContext";
import { prepareOtherPortals } from "utils/portal";
import { useIntl } from "i18n/Internationalization";

const PortalPrivacy = () => {
	const { portal, portalList, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	const intl = useIntl();
	useEffect(() => {
		// ScrollToOptions type is bugged until TS 5.1 where "instant" is properly added
		const scrollToOptions = { top: 0, left: 0, behavior: "instant" } as unknown as ScrollToOptions;
		window.scrollTo(scrollToOptions);
	}, []);

	if (error) {
		return (
			<ErrorLayout>
				<FatalError />
			</ErrorLayout>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || !portalList || isLoading) {
		return <Loader></Loader>;
	}

	const otherPortalsList = prepareOtherPortals(portalList, publicId);

	const i18nstrings = {
		"privacy-page-back-to-dashboard": intl.formatMessage({
			id: "privacy-page-back-to-dashboard",
			defaultMessage: "Back to dashboard",
		}),
	};

	return (
		<>
			<Helmet />
			<PrimaryHeader
				publicId={publicId}
				claim={portal.claim}
				otherPortalsList={otherPortalsList}
				hideHistory={false}
			/>
			<PrimaryContent>
				<div className="g-section">
					<div className="g-layout-sidebar">
						<Link
							className="back-btn"
							to={`/${publicId}`}
							title={i18nstrings["privacy-page-back-to-dashboard"]}
							aria-label={i18nstrings["privacy-page-back-to-dashboard"]}
						>
							{i18nstrings["privacy-page-back-to-dashboard"]}
						</Link>
					</div>
				</div>

				<div className="g-section">
					<div className="g-layout-sidebar">
						<div className="copy-content">
							<div className="copy-content--title">
								<h1>Accuserve Privacy Policy</h1>
							</div>
							<div className="copy-content--card">
								<p className="copy-content--updated">Last Updated: September 2, 2021</p>
								<p>
									Accuserve Solutions, Inc. and its affiliated companies, including Code Blue, LLC,
									Code Blue 360, LLC, FV Holdings, LLC dba MadSky Managed Repair Program, Accuwin,
									LLC, and DK Construction, LLC (collectively, the “Company”,” “we,” “our,” or “us”)
									take your privacy seriously. We want you to know how we collect, use, share, and
									protect your Personal Information. Specific to California residents, we want you to
									know about the rights of California Consumers under California law.
								</p>
								<p>
									<strong>This Privacy Policy addresses the following topics:</strong>
								</p>
								<ul>
									<li>Scope of this Privacy Policy</li>
									<li>Our Collection, Use and Disclosure of Personal Information</li>
									<li>Your California Privacy Rights</li>
									<li>How to Exercise Your Rights</li>
									<li>Additional Rights Under California Law</li>
									<li>Third-Party Links and Services</li>
									<li>Minors Under 16 Years of Age</li>
									<li>Changes to This Privacy Policy</li>
									<li>For More Information</li>
									<li>Data Subject Access Request (DSAR)</li>
								</ul>

								<p>
									The section of this policy entitled, “Our Collection, Use and Disclosure of Personal
									Information”, serves as your notice at collection when we collect personal
									information about you online. For California Consumers, this serves as notice at
									collection as required by California law.
								</p>

								<h2>Scope of this Privacy Policy</h2>
								<p>
									This section of the Privacy Policy describes, for the 12 months preceding the date
									last updated, above, (a) the categories of Personal Information we have collected
									about you, (b) the sources of that Personal Information, (c) the business and
									commercial purposes for use, and (d) the categories of third party recipients of
									your Personal Information. Unless we inform you otherwise, this section also serves
									as our “notice at collection” by informing you of your Personal Information to be
									collected in the future and the purposes for its use.
								</p>

								<p>
									<strong>What Is Personal Information?</strong>
								</p>
								<p>
									“Personal Information” means information that identifies, relates to, describes, is
									capable of being associated with, or could reasonably be linked, directly or
									indirectly, with a particular Consumer or household.
								</p>

								<p>
									<strong>Whose Personal Information Is Covered By This Policy?</strong>
								</p>
								<p>
									This Privacy Policy applies to the Personal Information of our consumers, including
									residents of the State of California in their capacity as consumers (“California
									Consumers”).
								</p>

								<p>
									<strong>What Personal Information Does This Privacy Policy Cover?</strong>
								</p>
								<p>
									This Privacy Policy applies to all Personal Information of Consumers and California
									Consumers that we collect online through Consumers’ visits to our website, and (b)
									use of our services that references this Privacy Policy (collectively, the
									“Services”).
								</p>

								<p>
									<strong>Whose Personal Information Is Not Covered By This Privacy Policy?</strong>
								</p>
								<p>
									or purposes of this Privacy Policy, “Personal Information” does not include the
									personal information of consumers or California residents who are:
								</p>

								<ul>
									<li>
										The Company’s employees, job applicants, directors, officers, or independent
										contractors (collectively, “HR Individuals”) or the emergency contacts of HR
										Individuals or the dependents or spouses who receive the Company’s benefits by
										virtue of their relationship to an HR Individual in their capacities as HR
										Individuals or emergency contacts, dependents, or spouses; or
									</li>
									<li>
										Employees or other agents of a business engaged in a transaction with the
										Company in their capacities as employees or agents of that business.
									</li>
								</ul>

								<h3>Our Collection, Use And Disclosure Of Personal Information</h3>
								<p>
									This section of the Privacy Policy describes, for the 12 months preceding the date
									last updated, above, (a) the categories of Personal Information we have collected
									about you, (b) the sources of that Personal Information, (c) the business and
									commercial purposes for use, and (d) the categories of third party recipients of
									your Personal Information. Unless we inform you otherwise, this section also serves
									as our “notice at collection” by informing you of your Personal Information to be
									collected in the future and the purposes for its use.
								</p>

								<strong>Categories Of Personal Information Collected</strong>
								<p>
									In the last 12 months, the Company collected the following categories of Personal
									Information about Consumers and California Consumers.
								</p>

								<ul>
									<li>
										<strong>Identifiers</strong>, including: real name, alias, postal address, email
										address, home phone number, and cell or other phone number.
									</li>
									<li>
										<strong>Categories described in Cal. Civ. Code § 1798.80(e)</strong> (except
										those already listed in “Identifiers” above), including: insurance policy
										number, and insurance claim number.
									</li>
								</ul>

								<p>
									<strong>
										Sources Of Personal Information and Purposes For Using Personal Information
									</strong>
								</p>
								<p>
									In the last 12 months, the Company collected the categories of Personal Information
									listed above from the following sources and used those categories of Personal
									Information for the following purposes:
								</p>

								<table id="personal-info-table">
									<thead>
										<tr>
											<th scope="column">
												Categories of Personal Information Collected in the Last 12 Months
											</th>
											<th scope="column">Sources of that Personal Information</th>
											<th scope="column">
												Business Purposes and Commercial Purposes for Collecting that Personal
												Information
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td data-label="Categories of Personal Information Collected in the Last 12 Months">
												<p className="table--emphasized">Identifiers</p>
											</td>
											<td data-label="Sources of that Personal Information">
												<p>Consumer (himself or herself)</p>
												<p>Consumer authorized third parties (insurance carriers)</p>
											</td>
											<td data-label="Business Purposes and Commercial Purposes for Collecting that Personal Information">
												<p>
													Providing services, including the scheduling, oversight and services
													related to property repairs and mitigation services
												</p>
												<p>
													Facilitating communications, for example, by collecting and
													organizing contact information, establishing means of
													communications, and communicating with current and prospective
													customers, including regarding questions and feedback
												</p>
												<p>Communications about customer satisfaction surveys</p>
											</td>
										</tr>
										<tr>
											<td data-label="Categories of Personal Information Collected in the Last 12 Months">
												<p className="table--emphasized">
													Categories described in Cal. Civ. Code § 1798.80(e)
												</p>
											</td>
											<td data-label="Sources of that Personal Information">
												<p>Consumer (himself or herself)</p>
												<p>Consumer authorized third parties (insurance carriers)</p>
											</td>
											<td data-label="Business Purposes and Commercial Purposes for Collecting that Personal Information">
												<p>
													Providing services, including the scheduling, oversight and services
													related to property repairs and mitigation services
												</p>
												<p>
													Communications regarding specifics relating to the insurance claim
													for which we are providing services, including estimate, payment,
													and coverage details
												</p>
												<p>Verifying information</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<PrimarySidebar
							publicId={publicId}
							claim={portal.claim}
							isAbsolute={true}
							otherPortalsList={null}
							hideHistory={false}
						/>
					</div>
				</div>
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalPrivacy;
