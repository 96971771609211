export const WATER_MITIGATION = [
	{
		type: "Water Mitigation",
		state: "N/A",
		prose: ["", "", ""],
	},
	{
		type: "Water Mitigation",
		state: "Accuserve Assigned",
		prose: [
			"Your claim has been received.",
			"Accuserve has received your [service] claim and is reaching out to schedule an inspection of your property.",
			"A contractor will be assigned shortly.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Attempting to Contact",
		prose: [
			"Thank you for speaking to us.",
			"We are attempting to contact you in order to schedule an inspection of your property and assess your [service] needs.",
			"A contractor will be assigned to you shortly.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Assigning Contractor",
		prose: [
			"A contractor has been assigned to your claim.",
			"We are locating a [service] contractor who will inspect your property.",
			"Once scheduled, a contractor will inspect your property.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Awaiting Inspection",
		prose: [
			"The contractor has inspected your property.",
			"A [service] contractor has been assigned and the next step is for them to inspect your property.",
			"The contractor will inspect your property at the scheduled appointment time.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Water Mitigation Planning",
		prose: [
			"Accuserve has worked with the contractor to determine best method for drying your property.",
			"Accuserve is working with the [service] contractor on the best method to get everything dry.",
			"Drying will proceed shortly.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Water Mitigation On Hold",
		terminal: true,
		prose: [
			"The [service] is no longer on hold.",
			"[service] is currently on hold. If you have any questions or concerns please contact Accuserve.",
			"",
		],
	},
	{
		type: "Water Mitigation",
		state: "Drying",
		prose: [
			"Good news, drying is complete.",
			"[service] equipment is placed and drying is in progress!",
			"If you proceed with our contractor, they will begin setting up equipment to dry your property.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Submitting to Carrier",
		prose: [
			"Accuserve and your insurance provider have completed the processing for your [service] service.",
			"We are working with your insurance provider to complete the processing of your [service] service.",
			"Once drying has finished, we will work with your insurance provider to complete the processing for your service.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Service Complete",
		prose: [
			"",
			"It has been a pleasure working to complete the [service] at your property. If other services are required within this claim, we will continue that work.",
			"The service will be complete after processing.",
		],
	},
	{
		type: "Water Mitigation",
		state: "Accuserve Not Handling Service",
		terminal: true,
		prose: [
			"The service is no longer stoped.",
			"Accuserve is not currently managing the [service] service at your property. If anything changes and you would like our help, please contact us.",
			"",
		],
	},
];
