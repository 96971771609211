import ServiceCardContractor from "components/ServiceCardContractor";
import { PrimaryDialog } from "components/PrimaryDialog";
import { Service } from "model/dto/service";
type ServiceCardContractorDialogProps = {
	service: Service;
	visible: boolean;
	close: () => void;
};
const ServiceCardContractorDialog = ({ service, visible, close }: ServiceCardContractorDialogProps) => {
	return (
		<PrimaryDialog show={visible} close={close}>
			<ServiceCardContractor service={service} assignedContractorCompany={service.assignedContractor} />
		</PrimaryDialog>
	);
};

export default ServiceCardContractorDialog;
