export const ROOF_RESTORATION = [
	{
		type: "Roofing",
		state: "N/A",
		prose: ["", "", ""],
	},
	{
		type: "Roofing",
		state: "Accuserve Assigned",
		prose: [
			"Your claim has been received.",
			"Accuserve has received your [service] claim and is reaching out to schedule an inspection of your property.",
			"A contractor will be assigned shortly.",
		],
	},
	{
		type: "Roofing",
		state: "Contractor Assigned",
		prose: [
			"The contractor has inspected your property.",
			"A [service] contractor is assigned to your claim and will inspect your property.",
			"After inspection, Accuserve will work with the contractor to estimate any repairs needed.",
		],
	},
	{
		type: "Roofing",
		state: "Estimate In Progress",
		prose: [
			"An estimate has been completed.",
			"We are working with the [service] contractor to create an estimate for any repairs needed.",
			"We will work with the roofing contractor to create an estimate for any repairs needed. Once the estimate is approved by your insurance provider, you will be asked to sign a work authorization in order to begin repairs.",
		],
	},
	{
		type: "Roofing",
		state: "Estimate Approved",
		prose: [
			"Thank you for signing the work authorization!",
			"Good news! The [service] estimate is approved by your insurance provider. Your signature is now needed to authorize the work.",
			"Once you sign a work authorization your repairs will be scheduled.",
		],
	},
	{
		type: "Roofing",
		state: "Repairs Being Planned",
		prose: [
			"Your repairs have been planned and scheduled.",
			"We are working with the [service] contractor to plan and schedule your repairs.",
			"We will reach out to you with the plan after you have authorized the work to begin.",
		],
	},
	{
		type: "Roofing",
		state: "Repairs On Hold",
		terminal: true,
		prose: [
			"Your repairs are no longer on hold.",
			"The [service] repairs to your property are currently on hold.",
			"",
		],
	},
	{
		type: "Roofing",
		state: "Repairs In Progress",
		prose: [
			"Accuserve has been working with the contractor to complete repairs to your property.",
			"We are currently working closely with the [service] contractor to complete repairs to your property.",
			"Accuserve will work with the contractor until repairs to your property are complete.",
		],
	},
	{
		type: "Roofing",
		state: "Roof Complete",
		prose: [
			"Roof repairs have been completed!",
			"We are happy to inform you that repairs to your roof are now complete!",
			"Once the repairs are complete, you will be asked to confirm you are satisfied with the work performed.",
		],
	},
	{
		type: "Roofing",
		state: "Awaiting COS",
		prose: [
			"Thank you for signing that you are satisfied with the completed repairs!",
			"Now that the [service] is complete, we are reaching out to confirm that you are satisfied with the work.",
			"We will reach out to confirm that you are satisfied with the work.",
		],
	},
	{
		type: "Roofing",
		state: "Service Complete",
		prose: [
			"",
			"It has been a pleasure working to complete the [service] at your property. If other services are required within this claim, we will continue that work.",
			"Service will be complete after you confirm you are satisfied.",
		],
	},
	{
		type: "Roofing",
		state: "Accuserve Not Handling Service",
		terminal: true,
		prose: [
			"The service is no longer stoped.",
			"Accuserve is not currently managing the [service] service at your property. If anything changes and you would like our help, please contact us.",
			"",
		],
	},
];
