import "react-app-polyfill/stable";

import App from "App";
import { createRoot } from "react-dom/client";
import reportWebVitals from "utils/reportWebVitals";
import * as Sentry from "@sentry/browser";

import "mock";

const MAX_LENGTH = 100000;

const sentryKey = (window as any).SENTRY_DSN;
if (sentryKey) {
	console.log("activating sentry");
	Sentry.init({
		dsn: sentryKey,
		beforeBreadcrumb(breadcrumb, hint) {
			return {
				...breadcrumb,
				message:
					breadcrumb.message && breadcrumb.message.length > MAX_LENGTH
						? "TRUNCATED: " + breadcrumb.message.substring(0, MAX_LENGTH)
						: breadcrumb.message,
			};
		},
	});
} else {
	console.log("skipping sentry");
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
