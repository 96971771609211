import { coreGet, corePost } from "api/api-wrapper";
import { CoreApiPath } from "api/path";
import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { ActivationResultCodec } from "model/dto/activation-result";
import { AuthStateCodec } from "model/dto/auth-state";
import { CheckValidationCodeResultCodec } from "model/dto/check-validation-code-result";
import { PortalCodec } from "model/dto/portal";
import { FeedbackPromptCodec } from "model/dto/feedback-prompt";
import { FeedbackSubmission } from "model/dto/feedback-submission";
import { PortalListCodec } from "model/dto/portal-list";
import { SendValidationCodeResultCodec } from "model/dto/send-validation-code-result";

export const ping = () => coreGet(t.unknown)(CoreApiPath.AUTH.PING);

export const activate = (publicId: string, linkCode: string | null) =>
	corePost(ActivationResultCodec)(CoreApiPath.AUTH.ACTIVATE, undefined, { publicId, linkCode });

export const getPortalList = () => coreGet(PortalListCodec)(CoreApiPath.AUTH.PORTAL_LIST);

export const sendValidationCode = (publicId: string, contactMethodId: UUID) =>
	corePost(SendValidationCodeResultCodec)(CoreApiPath.AUTH.SEND_VALIDATION_CODE, undefined, {
		publicId,
		contactMethodId,
	});

export const checkValidationCode = (publicId: string, confirmCode: string) =>
	corePost(CheckValidationCodeResultCodec)(CoreApiPath.AUTH.CHECK_VALIDATION_CODE, undefined, {
		publicId,
		confirmCode,
	});

export const getAuthState = (publicId: string, since: Date) =>
	coreGet(AuthStateCodec)(CoreApiPath.AUTH.GET_AUTH_STATE, { publicId, since: since.toISOString() });

export const getPortal = (publicId: string) => coreGet(PortalCodec)(`${CoreApiPath.PORTAL}/${publicId}`);

export const getFeedbackPrompt = async (publicId: string) => {
	return coreGet(FeedbackPromptCodec)(`${CoreApiPath.PORTAL}/${publicId}${CoreApiPath.FEEDBACK}`);
};

export const submitFeedback = async (publicId: string, feedback: FeedbackSubmission) => {
	return corePost(t.void)(`${CoreApiPath.PORTAL}/${publicId}${CoreApiPath.FEEDBACK}`, undefined, feedback);
};

export const assertPromptShown = async (publicId: string) => {
	return corePost(t.void)(`${CoreApiPath.PORTAL}/${publicId}${CoreApiPath.FEEDBACK}${CoreApiPath.PROMPT_SHOWN}`);
};

export const explicitLanguageChange = async (publicId: string, language: string) => {
	return corePost(t.void)(`${CoreApiPath.PORTAL}/${publicId}${CoreApiPath.EXPLICIT_LANGUAGE_CHANGE}`, undefined, {
		language: language.toLocaleUpperCase(),
	});
};

export const logout = () => corePost(t.unknown)(CoreApiPath.AUTH.LOGOUT);
