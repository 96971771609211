import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";

export const ContactMethodCodec = t.partial({
	contactId: UUID,
	contactMethodId: UUID,
	methodType: t.union([t.literal("Email"), t.literal("Phone")]),
	phoneNumber: t.string,
	emailAddress: t.string,
});

export type ContactMethod = t.TypeOf<typeof ContactMethodCodec>;
