import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext } from "react";
import messages_en from "i18n/lang/en.json";
import messages_es from "i18n/lang/es.json";

export enum Locale {
	ENGLISH = "en",
	SPANISH = "es",
}

type LocaleContextProps = {
	currentLocale: Locale;
	setCurrentLocale: Dispatch<SetStateAction<Locale>>;
	messages: { [key in Locale]: { [key: string]: string } };
};

export const LocaleContext = createContext<LocaleContextProps | null>(null);

export const LocaleProvider: React.FC<{
	children: ReactNode;
	currentLocale: Locale;
	setCurrentLocale: Dispatch<SetStateAction<Locale>>;
}> = ({ children, currentLocale, setCurrentLocale }) => {
	const messages = {
		[Locale.ENGLISH]: messages_en,
		[Locale.SPANISH]: messages_es,
	};
	return (
		<LocaleContext.Provider
			value={{
				currentLocale,
				setCurrentLocale,
				messages,
			}}
		>
			{children}
		</LocaleContext.Provider>
	);
};

export const useLocale = () => {
	const context = useContext(LocaleContext);

	if (!context) {
		throw new Error("locale context not available");
	}

	return context;
};
