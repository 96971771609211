import React from "react";
import { Claim } from "model/dto/claim";
import { Service } from "model/dto/service";
import { JournalEntry } from "model/dto/journal-entry";
import { Note } from "model/dto/note";
import { randomishString } from "utils/util";
import moment from "moment";

export type HistoryEntryType = "Journal" | "Note";
export type HistoryEntry = {
	key: string;
	type: HistoryEntryType;
	date: Date;
	dateCondensed: string;
	month: string;
	day: string;
	service: Service | null;
	entry: JournalEntry | Note;
};
export type HistoryGroup = {
	month: string;
	day: string;
	entries: Array<HistoryEntry>;
};

export const JOURNAL_TYPE: HistoryEntryType = "Journal";
export const NOTE_TYPE: HistoryEntryType = "Note";

/**
 * Given a claim, this function will extract and return history entries
 * for the combined set of services within the claim.
 *
 * @param claim the claim from which we want to extract history entries
 * @returns an array of HistoryEntry objects
 */
export function getHistoryEntries(claim: Claim): Array<HistoryGroup> {
	if (typeof claim === "undefined") {
		return [];
	}

	const claimEvents = (claim.claimHistoryEvents || []).map((entry) => ({
		key: randomishString(),
		type: JOURNAL_TYPE,
		date: entry.eventTimestamp as Date,
		dateCondensed: moment(entry.eventTimestamp).format("MMM D"),
		month: moment(entry.eventTimestamp).format("MMM"),
		day: moment(entry.eventTimestamp).format("D"),
		service: null,
		entry: entry,
	}));
	const serviceEvents = (claim.services || []).reduce((arr: Array<HistoryEntry>, service: Service) => {
		return [
			...arr,
			...(service.serviceHistoryEvents || []).map((entry) => ({
				key: randomishString(),
				type: JOURNAL_TYPE,
				date: entry.eventTimestamp as Date,
				dateCondensed: moment(entry.eventTimestamp).format("MMM D"),
				month: moment(entry.eventTimestamp).format("MMM"),
				day: moment(entry.eventTimestamp).format("D"),
				service: service,
				entry: entry,
			})),
			...(service.notes || []).map((entry) => ({
				key: randomishString(),
				type: NOTE_TYPE,
				date: entry.noteDateTime as Date,
				dateCondensed: moment(entry.noteDateTime).format("MMM D"),
				month: moment(entry.noteDateTime).format("MMM"),
				day: moment(entry.noteDateTime).format("D"),
				service: service,
				entry: entry,
			})),
		];
	}, []);

	const groups = [...claimEvents, ...serviceEvents]
		.sort((a: HistoryEntry, b: HistoryEntry) => {
			return b.date.getTime() - a.date.getTime();
		})
		.reduce((groups, entry) => {
			if (!groups[entry.dateCondensed]) {
				groups[entry.dateCondensed] = {
					month: entry.month,
					day: entry.day,
					entries: [],
				};
			}

			groups[entry.dateCondensed].entries.push(entry);

			return groups;
		}, {});

	return Object.values(groups);
}

/**
 * Returns a history icon for the claim level events.
 * @deprecated
 * @returns an icon for the claim level history events
 */
export function getHistoryIcon() {
	return React.createElement("img", { src: "/img/icon-history-claim.svg", alt: "Claim history icon" }, null);
}

/**
 * Retrieve the appropriate policy holder name from the claim.
 *
 * @param claim the claim to extract the greeting name from
 * @returns a string representing the name that should be used for
 * the policy holder (greeting or anywhere else we may refer to the
 * policy holder)
 */
export function extractName(claim: Claim) {
	if (claim.policyholderFirstName) {
		return `${claim.policyholderFirstName.trim()},`;
	}
	return claim.policyholderFullName || "";
}
