import * as t from "io-ts";
import { UUID } from "io-ts-types/lib/UUID";
import { FeedbackQuestionKeyCodec } from "model/dto/feedback-question-key";

export const FeedbackSubjectCodec = t.union([t.literal("CONTRACTOR"), t.literal("ACCUSERVE"), t.literal("OTHER")]);

export const FeedbackResponseCodec = t.type({
	questionKey: FeedbackQuestionKeyCodec,
	questionType: t.union([t.literal("ENUM"), t.literal("TEXT")]),
	displayedText: t.string,
	responseEnum: t.union([t.string, t.undefined]),
	responseText: t.union([t.string, t.undefined]),
	subject: FeedbackSubjectCodec,
	contractorId: t.union([UUID, t.undefined]),
});

export type FeedbackSubject = t.TypeOf<typeof FeedbackSubjectCodec>;
export type FeedbackResponse = t.TypeOf<typeof FeedbackResponseCodec>;

export const CONTRACTOR_SUBJECT: FeedbackSubject = "CONTRACTOR";
export const ACCUSERVE_SUBJECT: FeedbackSubject = "ACCUSERVE";
export const OTHER_SUBJECT: FeedbackSubject = "OTHER";
