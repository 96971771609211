import moment from "moment";
import { Link } from "react-router-dom";
import { useIntl } from "i18n/Internationalization";

type PrimaryFooterProps = {
	publicId: String | undefined;
};

const PrimaryFooter = ({ publicId }: PrimaryFooterProps) => {
	const intl = useIntl();
	const year = moment().format("YYYY");
	const i18nstrings = {
		"footer-copyright": intl.formatMessage(
			{
				id: "footer-copyright",
				defaultMessage: `© Accuserve Solutions, Inc ${year}. All rights reserved.`,
			},
			{
				year: year,
			}
		),
		"footer-terms-and-conditions-link": intl.formatMessage({
			id: "footer-terms-and-conditions-link",
			defaultMessage: "Terms & Conditions",
		}),
		"footer-privacy-policy-link": intl.formatMessage({
			id: "footer-privacy-policy-link",
			defaultMessage: "Privacy Policy",
		}),
	};

	let links = publicId ? (
		<>
			<Link className="primary-footer__link" to={`/${publicId}/privacy-policy`}>
				{i18nstrings["footer-privacy-policy-link"]}
			</Link>
			<Link className="primary-footer__link" to={`/${publicId}/terms-conditions`}>
				{i18nstrings["footer-terms-and-conditions-link"]}
			</Link>
		</>
	) : null;

	return (
		<footer className="primary-footer">
			<div className="primary-footer__inner">
				<div className="primary-footer__copyright">
					{i18nstrings["footer-copyright"]}
					{links}
				</div>
			</div>
		</footer>
	);
};

export default PrimaryFooter;
