import { JournalEntry } from "model/dto/journal-entry";
import { Claim } from "model/dto/claim";
import HistoryCard from "components/HistoryCard";
import PrimarySidebar from "components/PrimarySidebar";
import { getHistoryEntries, HistoryGroup } from "utils/claim";
import { isStoppedStatus } from "utils/serviceUtil";
import { Link } from "react-router-dom";
import { useIntl } from "i18n/Internationalization";

type HistoryCardListProps = {
	publicId: String | undefined;
	claim: Claim;
};

const HistoryCardList = ({ claim, publicId }: HistoryCardListProps) => {
	const cards = getHistoryEntries(claim).map((group: HistoryGroup, idx: number) => {
		// Scape entries for "stopped" services
		const hasStopped = group.entries
			.map((entry) => {
				const journalEntry: JournalEntry = entry.entry as JournalEntry;
				return isStoppedStatus(journalEntry.portalSubStatus);
			})
			.includes(true);

		// label whole date group as stopped even if just one service is stopped
		const groupClassName = `history-group ${hasStopped ? "stopped" : ""}`.trim();

		const entries = group.entries.map((entry) => {
			return (
				<div key={entry.key} className="history-group__entry">
					<div className="history-group__date">
						<div className="history-group__date-inner">
							<span className="month">{group.month}</span>
							<span className="day">{group.day}</span>
						</div>
					</div>

					<HistoryCard entry={entry} />
				</div>
			);
		});

		return (
			<div key={`historyGroup-${idx}`} className={groupClassName}>
				{entries}
			</div>
		);
	});

	const intl = useIntl();

	const i18nstrings = {
		"history-list-back-button": intl.formatMessage({
			id: "history-list-back-button",
			defaultMessage: "Back to dashboard",
		}),
		"history-list-h1": intl.formatMessage({
			id: "history-list-h1",
			defaultMessage: "Your Claim History",
		}),
	};

	return (
		<div className="history-list">
			<div className="g-layout-sidebar mt-5">
				<Link className="back-btn" to={`/${publicId}`} title="Back to dashboard" aria-label="Back to dashboard">
					{i18nstrings["history-list-back-button"]}
				</Link>
			</div>

			<div className="history-list__content-wrap g-layout-sidebar">
				<h1 className="primary-content__title">{i18nstrings["history-list-h1"]}</h1>
				<div className="history-list__content">{cards}</div>
				<div className="history-list__sidebar">
					<PrimarySidebar
						publicId={publicId}
						claim={claim}
						isAbsolute={false}
						otherPortalsList={null}
						hideHistory={true}
					/>
				</div>
			</div>
		</div>
	);
};

export default HistoryCardList;
