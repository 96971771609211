import Loader from "components/Loader";
import PageIntro from "components/PageIntro";
import PrimaryFooter from "components/PrimaryFooter";
import PrimaryHeader from "components/PrimaryHeader";
import PrimaryHeaderSimple from "components/PrimaryHeaderSimple";
import ServiceList from "components/ServiceList";
import PrimaryContent from "layouts/PrimaryContent";
import FatalError from "pages/error/FatalError";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { PortalContext } from "contexts/PortalContext";
import { prepareOtherPortals } from "utils/portal";

const PortalDashboard = () => {
	const { portal, portalList, isLoading, linkCode, publicId, needsAuth, error } = useContext(PortalContext);

	if (error) {
		return (
			<>
				<PrimaryHeaderSimple />
				<main className="main" id="main-content">
					<FatalError />
				</main>
				<PrimaryFooter publicId={publicId} />
			</>
		);
	}

	if (needsAuth) {
		return <Navigate to={"/" + publicId + "/activate" + (linkCode ? "?c=" + linkCode : "")}></Navigate>;
	}

	if (!portal || !portalList || isLoading) {
		return <Loader></Loader>;
	}

	const otherPortalsList = prepareOtherPortals(portalList, publicId);

	return (
		<>
			<Helmet />
			<PrimaryHeader
				publicId={publicId}
				claim={portal.claim}
				otherPortalsList={otherPortalsList}
				hideHistory={false}
			/>
			<PrimaryContent>
				<PageIntro publicId={publicId} claim={portal.claim} otherPortalsList={otherPortalsList} />
				<ServiceList services={portal.claim.services || []} />
			</PrimaryContent>
			<PrimaryFooter publicId={publicId} />
		</>
	);
};

export default PortalDashboard;
