import Centered from "layouts/Centered";
import FatalError from "pages/error/FatalError";
import React, { ReactNode } from "react";
import * as Sentry from "@sentry/browser";

export class FatalErrorBoundary extends React.Component<{ children?: ReactNode }, { hasError: boolean }> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.captureException(error);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Centered vertical={true}>
					<FatalError />
				</Centered>
			);
		}

		return this.props.children;
	}
}
